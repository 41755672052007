import React, { Component, useState } from 'react';
import RDropDown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { firebaseDb, firebaseAuth } from './firebase.js';
import 'font-awesome/css/font-awesome.min.css';
import { withRouter, useHistory, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css'


import ScrollAccountMenu from './components/ScrollAccountMenu';
import DesktopScrollAccountMenu from './components/DesktopScrollAccountMenu';
import DesktopHistoryDateRangePicker from './components/DesktopHistoryDateRangePicker';

import TourButton from './components/TourButton';
import { budgetsteps } from './toursteps/budgetsteps';
import Navigation from './Navigation';
import FreeTrialAlert from './components/FreeTrialAlert';

const dbURL = 'https://vermillionapp.fly.dev/';
// const dbURL = 'https://shielded-bastion-58122.herokuapp.com/';

export default function History(props) {

	let navigate = useNavigate();
	function TransactionCard(trans) {
		return (
			<>
				<div className="transCard" key={'cont-' + trans.id} id={trans.id}
					onClick={() => {
						navigate(
							'/EditTransaction',
							{
								state: {
									// any values/objects/etc you want to also send to pushed route/path
									newAmount: trans.amount,
									newCatId: trans.categoryid,
									newPayee: trans.merchant,
									newCat: trans.category,
									newAcc: currAccount,
									newDate: trans.occurred_at,
									transId: trans.id,
									newXfrAcc: trans.xfraccid,
									newXfrIdAcc: trans.xfrid,
									newSplitId: trans.splitid,
									newSplitList: trans.split_list
								}
							}
						)
					}}>
					<div className="row mt-4" key={'row-' + trans.id}>
						<div className="col-3 text-center" key={'date-col-' + trans.id}>
							<p className="mt-0 mb-0 transDate" key={'date-' + trans.id}>{reformatDate(trans.occurred_at)}</p>
						</div>
						<div className="col-6" key={'src-col1-' + trans.id}>
							<div className="row" key={'src-row-' + trans.id}>
								<p className="fw-bold mt-0 mb-0 transSrc" key={'src-' + trans.id}>{trans.merchant}</p>
							</div>
							<div className="row" key={'cat-row-' + trans.id}>
								{(trans.splitid != null && trans.categoryid > 0) ? (
									<p className="fw-light mt-0 mb-0 transCat" key={'cat-' + trans.id}>Mixed</p>
								) : (
									<p className="fw-light mt-0 mb-0 transCat" key={'cat-' + trans.id}>{trans.category}</p>
								)}

							</div>
						</div>
						<div className="col-3" key={'dol-col-' + trans.id}>
							<div className="row" key={'dol-' + trans.id}>
								{(trans.splitid != null && trans.categoryid > 0) ? (
									<div className="expenseAmt" key={'dol-' + trans.id}>{toCurrency(-1 * trans.split_total)}</div>
								) : (
									trans.categoryid != 0 && trans.categoryid != -1 && trans.categoryid != -2 && trans.categoryid != -4 && trans.categoryid != -6 && trans.categoryid != -7 ? (
										<div className="expenseAmt" key={'dol-' + trans.id}>{toCurrency(trans.amount)}</div>
									) : (
										<div className="incomeAmt" key={'dol-' + trans.id}>{toCurrency(trans.amount)}</div>
									)
								)}

							</div>
							<div className="row" key={'bal-row-' + trans.id}>
								<div className="runningBalance fst-italic" key={'bal-' + trans.id}>{toCurrency(trans.running_balance)}</div>
							</div>


						</div>
					</div>
				</div>
			</>
		)
	}

	function DesktopTransactionCard(trans) {
		return (
			<>
				<div className="transCard desktopTransCard" key={'cont-' + trans.id} id={trans.id}
					onClick={() => {
						navigate(
							'/EditTransaction',
							{
								state: {
									// any values/objects/etc you want to also send to pushed route/path
									newAmount: trans.amount,
									newCatId: trans.categoryid,
									newPayee: trans.merchant,
									newCat: trans.category,
									newAcc: currAccount,
									newDate: trans.occurred_at,
									transId: trans.id,
									newXfrAcc: trans.xfraccid,
									newXfrIdAcc: trans.xfrid,
									newSplitId: trans.splitid,
									newSplitList: trans.split_list
								}
							}
						)
					}}>
					<div className="row mt-4" key={'row-' + trans.id}>
						<div className="col-2 text-center" key={'date-col-' + trans.id}>
							<p className="mt-0 mb-0 transDate" key={'date-' + trans.id}>{reformatDate(trans.occurred_at)}</p>
						</div>
						<div className="col-3" key={'src-col2-' + trans.id}>
							<p className="fw-bold mt-0 mb-0 transSrc" key={'src-' + trans.id}>{trans.merchant}</p>
						</div>
						<div className="col-3" key={'src-col3-' + trans.id}>
							{(trans.splitid != null && trans.categoryid > 0) ? (
								<p className="fw-light mt-0 mb-0 transCat" key={'cat-' + trans.id}>Mixed</p>
							) : (
								<p className="fw-light mt-0 mb-0 transCat" key={'cat-' + trans.id}>{trans.category}</p>
							)}

						</div>
						<div className="col-2" key={'dol-col-' + trans.id}>
							{(trans.splitid != null && trans.categoryid > 0) ? (
								<div className="expenseAmt" key={'dol-' + trans.id}>{toCurrency(-1 * trans.split_total)}</div>
							) : (
								trans.categoryid != 0 && trans.categoryid != -1 && trans.categoryid != -2 && trans.categoryid != -4 && trans.categoryid != -6 && trans.categoryid != -7 ? (
									<div className="expenseAmt" key={'dol-' + trans.id}>{toCurrency(trans.amount)}</div>
								) : (
									<div className="incomeAmt" key={'dol-' + trans.id}>{toCurrency(trans.amount)}</div>
								)
							)}
						</div>

						<div className="col-2 pe-5" key={'bal-row-' + trans.id}>
							<div className="runningBalance fst-italic" key={'bal-' + trans.id}>{toCurrency(trans.running_balance)}</div>
						</div>


					</div>
				</div>
			</>
		)
	}

	const [transactions, setTransactions] = useState([]);
	const [reducedTransactions, setReducedTransactions] = useState([]);

	const [userID, setUserID] = useState('');
	const [email, setEmail] = useState('');
	var newDate = new Date();
	const [startDate, setStartDate] = useState(newDate);
	const [endDate, setEndDate] = useState(newDate);
	const [accounts, setAccounts] = useState([]);
	const [currAccount, setCurrAccount] = useState('');
	const [currAccountName, setCurrAccountName] = useState('');
	const [idToken, setIdToken] = useState('');
	let [days_left, setDaysLeft] = useState(600);

	// console.log("base curr account",currAccount);

	const signOut = () => {
		firebaseAuth.signOut().then(() => {
			// Sign-out successful.
			props.history.push('/Login');
		}).catch((error) => {
			// An error happened.
			console.log("Cannot log out.");
		});
	};

	const reformatDate = (oldDate) => {
		var date = new Date(oldDate + 'T00:00:00');
		return (date.toLocaleString("en-us", {
			month: "short",
			day: 'numeric',
			year: 'numeric'
		}).toUpperCase().replace(",", ""));
	}

	const reformatDateForFilter = (oldDate) => {
		var date = new Date(oldDate).toISOString().split('T')[0];
		return (date);
	}


	const toCurrency = (x) => {
		// *** Convert to format $0.00
		return (x / 100).toLocaleString('US', {
			style: "currency",
			currency: "USD"
		});
	}

	const api_get_accounts = (authToken) => {
		return firebaseAuth.currentUser.getIdToken()
			.then((idToken) => {
				// Send token to your backend via HTTPS
				const data = { token: idToken, color2: 'red' };
				const requestOptions = {
					method: 'GET'
				};
				// Where we're fetching data from
				const baseURL = dbURL + 'getaccounts?';
				const reqURL = baseURL + 'token=' + data.token;
				console.log(reqURL);
				return fetch(reqURL, requestOptions)
					.then((response) => response.json())
					.then((data) => {
						const newData = data.results.rows;
						return newData;
					})
					.catch((error) => {
						console.error(error);
						throw error;
					});
			})
			.catch((error) => {
				console.log("No user logged in!");
				throw error;
			});
	};



	const api_get_transactions = (accountid, startDate, endDate) => {
		return firebaseAuth.currentUser.getIdToken()
			.then((idToken) => {
				const data = {
					token: idToken, accountid: accountid,
					startdate: reformatDateForFilter(startDate),
					enddate: reformatDateForFilter(endDate)
				};
				const requestOptions = {
					method: 'GET'
				};
				// Where we're fetching data from
				const baseURL = dbURL + 'gethistory?';
				const reqURL = baseURL
					+ 'token=' + data.token
					+ '&accountid=' + data.accountid
					+ '&startdate=' + data.startdate
					+ '&enddate=' + data.enddate
					;
				// console.log(reqURL);
				return fetch(reqURL, requestOptions)
					// We get the API response and receive data in JSON format
					.then((response) => {
						// console.log("getting response ", response)
						return (response.json());
					})
					.then((data) => {
						const newData = data.results.rows
						return (newData);
					})
					.catch((error) => console.error(error));
			})
			.catch((error) => {
				console.log("No user logged in!");
				throw error;
			});


	}

	async function api_getIdToken(user) {
		if (user) {
			const token = await user.getIdToken()

		} else {
			console.log("No user is logged in")
		}
	}

	useEffect(() => {
		firebaseAuth.onAuthStateChanged(function (user) {
			if (user) {
				// User is signed in.
				setUserID(firebaseAuth.currentUser.uid);
				setEmail(encodeURIComponent(firebaseAuth.currentUser.email));
				// const idToken2 = await 
				user.getIdToken()
					.then(idToken => {
						setIdToken(idToken);
						api_get_accounts(idToken).then((data) => {
							const newData = data;
							setAccounts(newData);
							console.log("accounts: ", newData);
							setCurrAccount(newData[0]['accountid']);
							setCurrAccountName(newData[0]['name']);
							// console.log("setting account to...", newData[0]['accountid']);
						})

					});
				console.log('logged in!' + firebaseAuth.currentUser.uid);

				var date = new Date();
				var date2 = new Date();
				date2.setDate(date2.getDate() - 30);
				var firstDay = new Date(date2.getFullYear(), date2.getMonth(), 1);
				var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
				setStartDate(firstDay);
				setEndDate(lastDay);
				// console.log("DATES", firstDay,lastDay);
				getAccList();
			} else {
				// No user is signed in.
				console.log('no one signed in');
				props.history.push('/login');
			}
		});
	}, []);


	useEffect(() => {
		if (currAccount != '') {
			api_get_transactions(currAccount, startDate, endDate).then((data) => {
				const newData = data;
				// console.log("HISTORY ITEMS: ",newData);
				setDaysLeft(newData[0]['days_left']);
				// console.log("Setting days left to ",newData[0]['days_left']);
				// console.log("transactions",newData);
				// Find duplicate rows due to splitID
				// Ideally we would take the one with the HIGHEST transactionid

				// 12/26/2022: Just got rid of dupes in the running balance, 
				// so we shouldn't need to reduce here. Dupes just won't be kept
				// on the backend, will need to be rolled up.
				let newReducedData = newData.reduce((acc, d) => {
					if (d.running_balance == null) {
						// console.log("Not keeping",d);
						// const found = newData.find(a =>
						// (a.splitid === d.splitid
						// 	&& a.splitid != null
						// 	&& a.transactionid < d.transactionid));

						// if (found) {
						// 	// Check if a member already exists in our new list
						// 	const alreadyFound = acc.find(a =>
						// 		(a.splitid === d.splitid
						// 			&& a.splitid != null
						// 			&& a.transactionid < d.transactionid));

						// 	console.log("ALREADY FOUND", alreadyFound);

						// 	let splitList = [];
						// 	console.log("Found a duplicate");
						// 	console.log("Keeping",d);
						// 	// console.log("Removing",a);
					}
					else {
						acc.push(d);
					}
					return acc;
				}, []);

				// console.log("reduced transactions",newReducedData);


				setReducedTransactions(newReducedData)
				setTransactions(newData);
			});
		}
	}, [currAccount, startDate, endDate]);


	const getAccList = () => {
		if (userID !== '') {
			var newList = [];
			const accList = firebaseDb.ref('/acc/').child(userID);
			accList.once('value', snapshot => {
				snapshot.forEach((data) => {
					var key = data.val().Id;
					var name = data.val().name;
					var val = {
						id: key,
						name: name
					}
					var entry = { key: val };
					newList.push(val);
				});
				setAccounts(newList);
			});
		}
	}

	return (

		<>
			{/* <button onClick={() => signOut()} className="onBButton back-btn">Sign Out</button> */}

			<FreeTrialAlert days_left={days_left} email={email} />
			{/* <div style={days_left === '-1' || days_left < 500
				? { marginTop: '80px' }
				: {}}> */}
				<div>
				<Navigation key="nav-bar-key" />
				{/* Desktop */}
				<div className='d-none d-lg-block greyBG'>
					<div className='contentSpace'>
						<div className='row ms-5'>
							<div className='col-11 mt-5 text-dark fw-bold fs-2'>
								<DesktopScrollAccountMenu
									accountList={accounts}
									selectedAccount={currAccount}
									updateAccount={setCurrAccount}
									startDate={startDate}
									endDate={endDate}
									setStartDate={setStartDate}
									setEndDate={setEndDate} />
							</div>
							<div className='col'>
								<TourButton steps={budgetsteps} />
							</div>
						</div>
						<div className='row'>
							<div key="answer-section" className='mb-5 col-9'>
								<ul key="answerList" className='answerList transList'>

									{reducedTransactions.map((trans, index) => {
										return (
											<React.Fragment key={`keytrans-${index}`}>
												<DesktopTransactionCard id={trans.transactionid}
													merchant={trans.merchant}
													categoryid={trans.categoryid}
													category={trans.category}
													occurred_at={trans.occurred_at}
													amount={trans.amount}
													running_balance={trans.running_balance}
													xfraccid={trans.xfraccount}
													xfrid={trans.xfrid}
													splitid={trans.splitid}
													split_total={trans.split_total}
													split_list={JSON.stringify(trans.split_list).replaceAll(`"`, ``)} />

											</React.Fragment>
										);
									})}
								</ul>
							</div>
							<div className='col pt-2'>
								<DesktopHistoryDateRangePicker
									startDate={startDate}
									endDate={endDate}
									setStartDate={setStartDate}
									setEndDate={setEndDate} />
							</div>
						</div>
					</div>
				</div>
				{/* Mobile */}
				<div className='greyBG d-block d-lg-none '>
					<div className='greyBG'>
						<ScrollAccountMenu
							accountList={accounts}
							selectedAccount={currAccount}
							updateAccount={setCurrAccount}
							startDate={startDate}
							endDate={endDate}
							setStartDate={setStartDate}
							setEndDate={setEndDate} />
						<div key="answer-section" className='answer-section'>
							<ul key="answerList" className='answerList transList'>

								{reducedTransactions.map((trans, index) => {
									return (
										<React.Fragment key={`keytrans-${index}`}>
											<TransactionCard id={trans.transactionid}
												merchant={trans.merchant}
												categoryid={trans.categoryid}
												category={trans.category}
												occurred_at={trans.occurred_at}
												amount={trans.amount}
												running_balance={trans.running_balance}
												xfraccid={trans.xfraccount}
												xfrid={trans.xfrid}
												splitid={trans.splitid}
												split_total={trans.split_total}
												split_list={JSON.stringify(trans.split_list).replaceAll(`"`, ``)} />

										</React.Fragment>
									);
								})}
							</ul>
						</div>
					</div>
				</div>

			</div>
		</>
	);
}

