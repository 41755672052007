import React, { Component, useState } from 'react';
import { Button, Form, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
// import parse, { attributesToProps } from 'html-react-parser';
import 'bootstrap/dist/css/bootstrap.min.css';
// import monthlyIncomeImg from './monthlyincome.PNG';
// import envelopesImg from './rent.png';
import { firebaseDb, firebaseAuth } from './firebase.js';
import 'font-awesome/css/font-awesome.min.css';
// import { BrowserRouter, Route, useHistory, withRouter, Redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import sqarelogo from './img/squarelogo.png';

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";

// library.add(faCoffee);


function ForgotPassword({ email }) {
    const [message, setMessage] = useState('');

    // Function to handle the "Forgot Password" button click
    function handleForgotPassword() {
        if (window.confirm(`Send a password reset link to ${email}?`)) {
            console.log(email);
            // Use Firebase Auth to send a password reset email
            firebaseAuth.sendPasswordResetEmail(email)
                .then(() => {
                    // Password reset email sent successfully
                    setMessage('Password reset link sent. Check your inbox.');
                })
                .catch((error) => {
                    // Handle errors, such as invalid email or network issues
                    setMessage(`Error: ${error.message}`);
                });
        }


    }

    return (
        <div>
            <div
                className='text-center fst-italic text-muted w-50 m-auto'
                onClick={handleForgotPassword}>
                Forgot Password
            </div>
            <p
                className='w-75 m-auto'>
                {message}
            </p>
        </div>
    );
}

function Login(props) {
    let navigate = useNavigate();

    const [transactions, setTransactions] = useState([]);

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [userID, setUserID] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');




    const signIn = () => {
        firebaseAuth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in
                console.log('logged in!');
                navigate('/History');
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode);
                console.log(errorMessage);
            });
    };


    // const dummyAPICall = (goal) => {
    //     const db = firebaseDb.ref("/items");
    //     db.push(goal);
    // };


    firebaseAuth.onAuthStateChanged(function (user) {
        if (user) {
            // User is signed in.
            console.log("signed in!");
            console.log(firebaseAuth.currentUser.uid);
            setUserID(firebaseAuth.currentUser.uid);
            navigate('/History');
            // undo this hide
        } else {
            // No user is signed in.
            console.log('no one signed in');
        }
    });


    return (

        <>
            {/* Desktop */}
            < div className='d-none d-lg-block greyBG' >
                <div className='d-flex justify-content-center mt-5'>
                    <div className='app d-flex flex-column p-5'>
                        <div className='w-100 mt-5 align-content-center'
                            style={{ textAlign: 'center' }}>
                            <img
                                src={sqarelogo}
                                className='navLogo'
                                alt="..." />
                        </div>
                        <div className="d-grid gap-2 mt-auto mb-1 align-content-center">
                            <div className='row mb-3'>
                                <input
                                    className="desktopTransInput p-2"
                                    style={{ textAlign: 'center' }}
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onInput={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='row mb-5'>
                                <input
                                    className="desktopTransInput p-2"
                                    style={{ textAlign: 'center' }}
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onInput={e => setPassword(e.target.value)}
                                />
                            </div>
                            <div className='row'>
                                <div
                                    className='button button-darkRed p-2 w-75'
                                    style={{ backgroundColor: '#f65f4b', borderRadius: '50px', textAlign: 'center', fontSize: '20px' }}
                                    onClick={() => signIn()}>Sign In
                                </div>
                            </div>

                        </div>
                        <br/>
                <ForgotPassword email={email} />
                    </div>
                </div>
            </div >
            {/* Mobile */}
            < div className='d-block d-lg-none ' >
                <div className='app d-flex flex-column '>
                    <div className="d-grid gap-2 mt-auto">

                        <div className='w-100 mt-3 align-content-center mb-5'
                            style={{ textAlign: 'center' }}>
                            <img
                                src={sqarelogo}
                                className='navLogo'
                                alt="..." />
                        </div>
                        <div className="d-grid gap-2 mt-auto mb-1 align-content-center">
                            <div className='row mb-3'>
                                <input
                                    className="desktopTransInput p-2 w-75 m-auto"
                                    style={{ textAlign: 'center' }}
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onInput={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='row mb-5'>
                                <input
                                    className="desktopTransInput p-2 w-75 m-auto"
                                    style={{ textAlign: 'center' }}
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onInput={e => setPassword(e.target.value)}
                                />
                            </div>
                            <div className='row'>
                                <div
                                    className='button button-darkRed p-2 w-50'
                                    style={{ backgroundColor: '#f65f4b', borderRadius: '50px', textAlign: 'center', fontSize: '20px' }}
                                    onClick={() => signIn()}>Sign In
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <ForgotPassword email={email} />
            </div >
        </>
    );
}



export default Login;

