import React from 'react';
import { useNavigate as withRouter } from "react-router-dom";
// import { text } from 'stream/consumers';
import { toCurrency, toNumber, textEllipsis, roundDownOneDecimal } from '../util/Util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export default function BudgetSummary(props) {
    // console.log("PROPS FOR BUDGET SUMMARY", props);
    // { onClick, itemId, name, budgeted, spent, bal, catid }
    return (
        <div id={"budgetsummary"} className='budgetsummary bg-white'>
            <div className="container ">
                <div className="row align-items-center">
                    <div className="col-5 text-center ">
                        <div id="saveRateBubble">
                            <div id='saveRatePerc'>
                                {/* {(((isNaN(props.saveRate) || props.saveRate === undefined || props.saveRate === null) ? 0 : props.saveRate) * 100).toFixed(1)}% */}
                                {roundDownOneDecimal(((isNaN(props.saveRate) || props.saveRate === undefined || props.saveRate === null) ? 0 : props.saveRate) * 100)}%
                            </div>
                            <div id='saveRateLabel'>save rate</div>
                        </div>
                    </div>
                    <div className="col text-center ">
                        <div className='budgetBal-warn p-3 pt-1 pb-1'>
                            {isNaN(props.saveRate)
                                ? toCurrency(0) // Set the goal to $0 when saveRate is NaN
                                : props.saveRate > (props.saveGoal / 100.0)
                                    ? toCurrency((props.saveRate - (props.saveGoal / 100.0)) * (1.0*props.income+1.0*props.pretax))
                                    : toCurrency(((props.saveGoal / 100.0) - props.saveRate) * (1.0*props.income+1.0*props.pretax))}
                            {isNaN(props.saveRate)
                                ? ' over goal' // Custom message when saveRate is NaN
                                : props.saveRate > (props.saveGoal / 100.0)
                                    ? ' over goal'
                                    : ' to reach goal'} of {(props.saveGoal).toFixed(1)}%
                            {/* {props.saveRate > saveGoal ? (
                                toCurrency((props.saveRate - saveGoal) * props.income / 100) + ' over goal'
                            ) : (
                                toCurrency((saveGoal - props.saveRate) * props.income / 100) + ' to reach goal'
                            )} */}
                        </div>
                    </div>
                </div>
            </div >
            <div className="container budgetSumAmt pb-3">
                <div className="row align-items-center mb-2">
                    <div className="col-5 clearfix">
                        <FontAwesomeIcon icon={faCircle}
                            className="ms-1 colorDot colorDotIncome" />
                        <span className='summaryLabel float-left'>Income </span>
                        <span className='summaryValue float-right'>{toCurrency(props.income)}</span>
                    </div>
                    <div className="col clearfix">
                        <FontAwesomeIcon icon={faCircle}
                            className="ms-1 colorDot colorDotPretax" />
                        <span className='summaryLabel float-left'>Pre-Tax Saved </span>
                        <span className='summaryValue float-right'>{toCurrency(props.pretax)}</span>
                    </div>
                </div>
                <div className="row align-items-center mb-3">
                    <div className="col-5 clearfix">
                        <FontAwesomeIcon icon={faCircle}
                            className="ms-1 colorDot colorDotExpense" />
                        <span className='summaryLabel float-left'>Expense </span>
                        <span className='summaryValue float-right'>{toCurrency(props.income - props.saved)}</span>
                    </div>
                    <div className="col clearfix">
                        <FontAwesomeIcon icon={faCircle}
                            className="ms-1 colorDot colorDotPostTax" />
                        <span className='summaryLabel float-left'>Post-Tax Saved </span>
                        <span className='summaryValue float-right'>{toCurrency(props.saved)}</span>
                    </div>
                </div>
            </div >
        </div>

    );
}
