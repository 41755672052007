import React, { useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
// import parse, { attributesToProps } from 'html-react-parser';
import 'bootstrap/dist/css/bootstrap.min.css';
import monthlyIncomeImg from './img/monthlyincome.PNG';
import envelopesImg from './img/rent.png';
import freetrial from './img/freetrial.png';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter, Route, useHistory, withRouter, Redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import useWindowSize from 'react-use/lib/useWindowSize';
import { firebaseDb, firebaseAuth } from './firebase.js';
// import useWindowSize from "use-window-size-v2";
import Confetti from 'react-confetti';
import { SmallLoadingSpinner } from "./LoadingSpinner";
// library.add(faCoffee);


const dbURL = 'https://vermillionapp.fly.dev/';
// const dbURL = 'https://shielded-bastion-58122.herokuapp.com/';


function SignupDisclaimer() {
    return <div className="disclaimer">By clicking "Let's go!" below you agree to Vermillion’s <a href="https://vermillion.app/terms-of-service/">Terms and Conditions</a> and <a href="https://vermillion.app/privacy-policy/">Privacy Policy</a>. You may also receive email offers from us on Vermillion products and services. You may unsubscribe at any time.</div>;
}

function Onboarding(props) {
    let navigate = useNavigate();
    // const { width, height } = useWindowSize();
    const [userSubmitted, setUserSubmitted] = useState(false);
    const [questions, setAnswers] = useState([
        {
            pageName: "Info",
            titleText: "Vermillion will help you re-align your spending to your values through awareness and habit changes.",
            preButtonText: "To get started, tell us more about yourself.",
            buttonText: "Let's go!",
            isQuestion: false,
            answerSelected: -1,
            answerOptions: [
                { answerText: "Let's go!", isSelected: false },
            ],
        },
        {
            pageName: "Experience",
            questionText: 'What experience do you have with budgeting?',
            isQuestion: true,
            answerSelected: -1,
            answerOptions: [
                { answerText: 'None, this is my first time.', isSelected: false },
                { answerText: 'A little, I’ve tried budgeting before.', isSelected: false },
                { answerText: 'A lot, I’ve budgeted for a while now.', isSelected: false },
            ],
        },
        {
            pageName: "Ability",
            questionText: 'How would you describe your ability to save?',
            isQuestion: true,
            answerSelected: -1,
            answerOptions: [
                { answerText: "Easy! There's always enough money to stash away.", isSelected: false },
                { answerText: 'Moderate. I put in the effort and manage to put some away.', isSelected: false },
                { answerText: "Difficult. No matter how hard I try, it's never enough.", isSelected: false },
            ],
        },
        {
            pageName: "Income",
            questionText: 'What type of income do you earn?',
            isQuestion: true,
            answerSelected: -1,
            answerOptions: [
                { answerText: 'Salary', isSelected: false },
                { answerText: 'Hourly', isSelected: false },
                { answerText: 'Contract/Freelance', isSelected: false },
                { answerText: 'Gig Economy/Per Task', isSelected: false },
                { answerText: 'Other/Mix', isSelected: false },
            ],
        },
        {
            pageName: "Info",
            questionText: 'Most budgets are built around a static income which assumes you earn the same money every month.',
            moreText: "But the majority of people are either hourly, contractors, or gig economy workers who earn a variable income.",
            isQuestion: false,
            preButtonText: "Vermillion will help you plan for those ups and downs.",
            buttonText: "Got it!",
            img: monthlyIncomeImg,
            answerSelected: -1,
            answerOptions: [
                { answerText: "Let's go!", isSelected: false },
            ],
        },
        {
            pageName: "Spending",
            questionText: 'How would you describe your monthly spending?',
            isQuestion: true,
            answerSelected: -1,
            answerOptions: [
                { answerText: "It's the same every month.", isSelected: false },
                { answerText: "It's a little different every month.", isSelected: false },
                { answerText: "It varies a lot month-to-month.", isSelected: false },
            ],
        },
        {
            pageName: "Info",
            questionText: 'Most budgets are build with static budget categories that stay the same month-to-month.',
            moreText: "But spending rarely works that way in real life. ",
            isQuestion: false,
            preButtonText: "Vermillion uses digital envelopes to ensure that every need is met.",
            buttonText: "Got it!",
            img: envelopesImg,
            answerSelected: -1,
            answerOptions: [
                { answerText: "Let's go!", isSelected: false },
            ],
        },
        {
            pageName: "Concern",
            questionText: 'What is your biggest financial concern?',
            isQuestion: true,
            answerSelected: -1,
            answerOptions: [
                { answerText: 'Life: bills, food, shelter. ', isSelected: false },
                { answerText: "Saving for something large (house, wedding, retirement, etc.)", isSelected: false },
                { answerText: 'Building better habits.', isSelected: false },
                { answerText: 'Other', isSelected: false },
            ],
        },
        {
            pageName: "Goal",
            questionText: 'About how much are you currently saving every month?',
            isQuestion: true,
            buttonText: "Let's make it happen!",
            answerSelected: -1,
            disclaimerText: `Ambitious journeys start small -- achievable goals create momentum that can keep you going.`,
            answerOptions: [
                { answerText: '1', isSelected: false },
            ],
        },
        {
            pageName: "Balance",
            questionText: 'How much money do you have to budget with?',
            moreText: "Not \"how much are you paid every month\", but literally \"how much do you have *at this moment in time*\". It's important to prioritize the dollars you have, and not overbudget with money you don't. You can always budget more when you get more money to budget with. ",
            isQuestion: true,
            buttonText: "Got it!",
            answerSelected: -1,
            disclaimerText: `To keep it simple, we recommend the balance of your most used checking account. (You can edit this later.)`,
            answerOptions: [
                { answerText: '1', isSelected: false },
            ],
        },
        {
            pageName: "Info",
            pageId: "Info-freetrial",
            questionText: "Vermillion is a saving tool that pays for itself. By filling out this questionnaire, you’ve earned 30 days for free – on us! After that, it’s only $5.95 a month.",
            moreText: "",
            isQuestion: false,
            preButtonText: "Our average user is able to save about $325 in their first few months just with better habits and awareness. Update your payment information under Settings at any time to keep on saving.",
            buttonText: "Sounds good!",
            img: freetrial,
            answerSelected: -1,
            answerOptions: [
                { answerText: "Let's go!", isSelected: false },
            ],
        },
        {
            pageName: "Email",
            titleText: "Almost there...",
            preButtonText: "",
            disclaimerText: `By clicking "Let's go!" below you agree to Vermillion’s Terms and Conditions and Privacy Policy. You may also receive email offers from us on Vermillion products and services. You may unsubscribe at any time.`,
            buttonText: "Let's go!",
            collectEmail: true,
            isQuestion: false,
            answerSelected: -1,
            answerOptions: [
                { answerText: "Let's go!", isSelected: false },
            ],
        },
    ]);

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [score, setScore] = useState(0);
    const [userEmail, setEmail] = useState('');
    const [userFirstName, setFirstName] = useState('');
    const [userLastName, setLastName] = useState('');
    const [userPassword, setPassword] = useState('');
    const [userGoal, setGoal] = useState(null);
    const [userBalance, setBalance] = useState(null);
    const [emailWarning, setEmailWarning] = useState(false);
    const [goalWarning, setGoalWarning] = useState(false);
    const [balanceWarning, setBalanceWarning] = useState(false);
    const progressVal = Math.round(100 * currentQuestion / questions.length) + 5;
    const [selections, setSelections] = useState([-1, -1, -1, -1]);


    const updateAnswers = (currentQuestion, answerIndex) => {

        const newAnswers = questions.map(question => {

            if (questions.indexOf(question) !== currentQuestion || !question.isQuestion) {
                return question;
            }
            else {
                const updatedQuestion = question.answerOptions.map(answerOption => {
                    if (question.answerOptions.indexOf(answerOption) !== answerIndex) {
                        const updatedAnswerOption = answerOption;
                        updatedAnswerOption.isSelected = false;
                        return updatedAnswerOption;
                    }
                    else {
                        const updatedAnswerOption = answerOption;
                        updatedAnswerOption.isSelected = true;
                        return updatedAnswerOption;
                    }
                })
                return question;
            }
        });
        setAnswers(newAnswers);
        handleAnswerButtonClick(currentQuestion, answerIndex);

    }

    const handleAnswerButtonClick = (currentQuestion, answerIndex) => {
        const nextQuestion = currentQuestion + 1;
        console.log("going from question " + currentQuestion + " to question " + nextQuestion);
        if (nextQuestion < questions.length) {
            setCurrentQuestion(nextQuestion);
        } else {
            setShowScore(true);
        }
    };

    const api_create_user = () => {
        console.log("API creating user...");

        const selectedAnswers = questions.map((question) => {
            const selectedOptionIndex = question.answerOptions.findIndex((option) => option.isSelected);
            return selectedOptionIndex >= 0 ? selectedOptionIndex : -1;
        });
        console.log("Answers: ", selectedAnswers);

        const data = {
            email: encodeURIComponent(userEmail),
            password: encodeURIComponent(userPassword),
            firstname: encodeURIComponent(userFirstName),
            lastname: encodeURIComponent(userLastName),
            surveyanswers: encodeURIComponent(selectedAnswers),
            initialgoal: encodeURIComponent(userGoal),
            initialbalance: encodeURIComponent(userBalance),
        };
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };

        console.log('Adding before api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'adduser?';
        const reqURL = baseURL
            + 'email=' + data.email
            + '&password=' + data.password
            + '&first_name=' + data.firstname
            + '&last_name=' + data.lastname
            + '&survey_answers=' + data.surveyanswers
            + '&initial_goal=' + data.initialgoal
            + '&initial_balance=' + data.initialbalance;
        console.log(reqURL);
        // return (1);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response)
            .then((data) => {
                console.log("Actually logging in now");
                firebaseAuth
                    .signInWithEmailAndPassword(userEmail, userPassword)
                    .then(res => {
                        console.log("Redirecting to budget...");
                        navigate('/Budget');
                    })
                    .catch(error => console.error(error));

                // Redirect to budget page after 5 seconds
                // setTimeout(() => {
                //     console.log("Redirecting to budget...");
                //     navigate('/Budget');
                // }, 5000);
            })
            .catch((error) => console.error(error));
    }

    const validateEmail = (currentQuestion, answerIndex) => {
        const emailRegExp = new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$', 'g');
        // var randomstring = Math.random().toString(36).slice(-8);
        if (emailRegExp.test(userEmail)) {
            console.log("Everything seems in order, calling API...");
            // Disable the submit button
            const submitButton = document.getElementById('submitButtonFinal');
            if (submitButton) {
                submitButton.disabled = true;
                // Set isLoading to true to show loading animation
                setUserSubmitted(true);
            }
            api_create_user();
        } else {
            // display bad email
            setEmailWarning(true);
        }
    };

    const validateGoal = (currentQuestion, answerIndex) => {
        // var randomstring = Math.random().toString(36).slice(-8);
        const input = (document.getElementById('exampleInputGoal1').value).replace(/[^0-9.]/g, ''); // Allow digits and a decimal point
        setGoal(input)
        console.log("Goal:", input);
        if (input != null && input != '') {
            handleAnswerButtonClick(currentQuestion, answerIndex);
        } else {
            // display bad email
            console.log('Bad goal');
            setGoalWarning(true);
        }
    };
    const validateBalance = (currentQuestion, answerIndex) => {
        // var randomstring = Math.random().toString(36).slice(-8);
        const input = (document.getElementById('exampleInputBalance1').value).replace(/[^0-9.]/g, ''); // Allow digits and a decimal point
        setBalance(input);
        console.log("Balance:", input);
        if (input != null && input != '') {
            handleAnswerButtonClick(currentQuestion, answerIndex);
        } else {
            // display bad email
            console.log('Bad balance');
            setBalanceWarning(true);
        }
    };

    const doNothing = () => {
        console.log("");
    };

    const backButtonClick = () => {
        const prevQuestion = currentQuestion - 1;
        setCurrentQuestion(prevQuestion);
    };

    function BackButton() {
        return (
            <div id="backDiv" className='backDiv'>
                {currentQuestion == 0 ? <div></div> :
                    (
                        <>
                            <button onClick={() => backButtonClick()} className="onBButton back-btn">Back</button>
                        </>
                    )}
            </div>
        )
    }

    function BigQuestionText() {
        return (
            <>
                <div key="question-section" className='question-section'>
                    <div key="question-count" className='question-count'>

                        <span>{questions[currentQuestion].questionText}</span></div>
                </div>
            </>
        )
    }

    function EmailInput() {
        return (
            <>
                <div className="mb-3">
                    {/* Need: email, firstname, lastname, password */}

                    <input type="text" placeholder="First Name" className="form-control mt-4 mb-4"
                        id="exampleInputFirstName" aria-describedby="firstNameHelp" defaultValue={userFirstName}
                        onBlur={e => setFirstName(e.target.value)} />
                    <input type="text" placeholder="Last Name" className="form-control"
                        id="exampleInputLastName" aria-describedby="firstNameHelp" defaultValue={userLastName}
                        onBlur={e => setLastName(e.target.value)} />


                    <label htmlFor="exampleInputEmail1" className="form-label"></label>
                    <input type="email" placeholder="Email" className="form-control mb-4"
                        id="exampleInputEmail1" aria-describedby="emailHelp" defaultValue={userEmail}
                        onBlur={e => setEmail(e.target.value)} />
                    {emailWarning ? (
                        <div id="emailHelp" className="form-text text-danger" ><FontAwesomeIcon icon={faExclamationCircle} color="red" /> Please provide a valid email.</div>
                    ) : (
                        <div></div>
                    )}


                    <input type="password" placeholder="Password" className="form-control"
                        id="exampleInputPassword" aria-describedby="passwordHelp" defaultValue={userPassword}
                        onBlur={e => setPassword(e.target.value)} />
                </div>
            </>
        )
    }

    function QuestionPage() {
        return (
            <>
                <BigQuestionText />
                <div key="answer-section" className='answer-section'>
                    <ul key="answerList" className='answerList'>

                        {questions[currentQuestion].answerOptions.map((answerOption, index) => {
                            return <li key={"li-" + currentQuestion + "" + index}>
                                <input type="radio" id={"" + currentQuestion + "" + index}
                                    checked={answerOption.isSelected}
                                    onClick={() => updateAnswers(currentQuestion, index)}
                                    onChange={() => doNothing()}
                                    //onClick={()=> handleAnswerButtonClick(currentQuestion,index)}
                                    name={"" + currentQuestion + "" + index} />
                                <label htmlFor={"" + currentQuestion + "" + index}
                                    key={"lab-" + currentQuestion + "" + index}
                                >
                                    {answerOption.answerText}
                                </label>
                            </li>;
                        })}
                    </ul>
                </div>
            </>
        )
    }

    function InfoPage() {
        return (
            <>
                <div className='question-section'>
                    <div className='display-6'>
                        <span>{questions[currentQuestion].titleText}</span>
                    </div>
                    <div className='question-text'>{questions[currentQuestion].questionText}</div>
                    <div className='question-text'>{questions[currentQuestion].moreText}</div>
                </div>
                <div className="col-md-12">
                    <img className="thumbnail img-fluid" src={questions[currentQuestion].img} />
                </div>
                <div className="d-grid gap-2 mt-auto">
                    <div className='question-text'>{questions[currentQuestion].preButtonText}</div>
                    <button className='onBButton btn-active' onClick={() => handleAnswerButtonClick(currentQuestion, -1)}>
                        {questions[currentQuestion].buttonText}</button>
                </div>
            </>
        )
    }

    function EmailPage() {
        return (
            <>
                <div className='question-section'>
                    <div className='display-6'>
                        <span>{questions[currentQuestion].titleText}</span>
                    </div>
                    <EmailInput />
                    <SignupDisclaimer />
                    <div className='question-text'>{questions[currentQuestion].questionText}</div>
                    <div className='question-text'>{questions[currentQuestion].moreText}</div>
                </div>
                <div className="col-md-12">
                    <img className="thumbnail img-fluid" src={questions[currentQuestion].img} />
                </div>
                <div className="d-grid gap-2 mt-auto">
                    <div className='question-text'>
                        {questions[currentQuestion].preButtonText}
                    </div>
                    <button id='submitButtonFinal' className='onBButton btn-active' onClick={() => validateEmail(currentQuestion, -1)}>
                        {userSubmitted ? (
                            <SmallLoadingSpinner />
                        ) : (
                            questions[currentQuestion].buttonText
                        )}
                    </button>
                </div>
            </>
        )
    }

    function GoalPage() {
        return (
            <>
                <BigQuestionText />

                <div className='question-text'>{questions[currentQuestion].moreText}</div>

                <div className="input-group mb-3 goalInput">
                    <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                    </div>

                    <input
                        type="number"
                        placeholder="50"
                        className="form-control"
                        id="exampleInputGoal1"
                        defaultValue={userGoal}
                    // onInput={e => setGoal(e.target.value)}
                    />
                    {/* <input type="text"
                        placeholder="50"
                        className="form-control"
                        id="exampleInputGoal1"
                        defaultValue={userGoal}
                    // onBlur={e => {
                    //     const input = e.target.value.replace(/[^0-9.]/g, ''); // Allow digits and a decimal point
                    //     // setGoal(input)
                    // }} 
                    /> */}
                    <div className='w-100' style={{ marginBottom: '40px' }}>
                        {goalWarning ? (
                            <div id="emailHelp" className="form-text text-danger" ><FontAwesomeIcon icon={faExclamationCircle} color="red" /> Please provide a valid amount.</div>
                        ) : (
                            <div></div>
                        )}
                    </div>

                </div>


                <div className="disclaimer">
                    {questions[currentQuestion].disclaimerText}
                </div>
                <div className="d-grid gap-2 mt-auto">
                    <div className='question-text'>{questions[currentQuestion].preButtonText}</div>
                    <button className='onBButton btn-active'
                        onClick={() => validateGoal(currentQuestion, -1)}>
                        {questions[currentQuestion].buttonText}
                    </button>
                </div>
            </>
        )
    }

    function BalancePage() {
        return (
            <>
                <BigQuestionText />

                <div className='question-text' style={{ fontSize: "14px" }}>
                    <p>
                        Not <i>"how much are you paid every month"</i>, but literally <i>"how much do you have <b>at this moment in time</b>"</i>.
                    </p>
                    <p>
                        It's important to prioritize the dollars you have, and not overbudget with money you don't.
                        You'll add more to your budget when you get paid again.
                    </p>
                </div>


                <div className="input-group mb-3 goalInput">
                    <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                    </div>
                    <input
                        type="number"
                        placeholder="416.22"
                        className="form-control"
                        id="exampleInputBalance1"
                        defaultValue={userBalance}
                    // onBlur={e => {
                    //     const input = e.target.value.replace(/[^0-9.]/g, ''); // Allow digits and a decimal point
                    //     setBalance(input)
                    // }} 
                    />
                    <div className='w-100' style={{ marginBottom: '40px' }}>
                        {balanceWarning ? (
                            <div id="emailHelp" className="form-text text-danger" ><FontAwesomeIcon icon={faExclamationCircle} color="red" /> Please provide a valid amount.</div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>

                <div className="disclaimer">
                    {questions[currentQuestion].disclaimerText}
                </div>
                <div className="d-grid gap-2 mt-auto">
                    <div className='question-text'>{questions[currentQuestion].preButtonText}</div>
                    <button className='onBButton btn-active'
                        onClick={() => validateBalance(currentQuestion, -1)}>
                        {questions[currentQuestion].buttonText}
                    </button>
                </div>
            </>
        )
    }

    function SwitchPage() {
        // console.log(questions[currentQuestion].pageName);
        switch (questions[currentQuestion].pageName) {
            case "Goal":
                return (<GoalPage />)
            case "Balance":
                return (<BalancePage />)
            case "Email":
                return (<EmailPage />)
            case "Info":
                return (<InfoPage />)
            default:
                return (<QuestionPage />)
        }
    }

    return (
        <div className='d-flex justify-content-center'>
            {questions[currentQuestion].pageId == 'Info-freetrial' ?
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    numberOfPieces={1500}
                    recycle={false}
                />
                :
                <></>
            }
            <div className='app d-flex flex-column '>
                <ProgressBar now={progressVal} />
                <BackButton />
                <SwitchPage />
            </div>
        </div>
    );
}

export default Onboarding;
