import React, { useEffect, useState } from 'react'
import '../index.css'

import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


const today = new Date();

function App(props) {
    const [days, setDays] = useState(props.days_left);
    // const [days, setDays] = useState("-1");
    const [message, setMessage] = useState("");

    const free_trial_phrases = [
        "The clock is ticking! Savor the next {days} days of our premium features for free. \nUpgrade to keep the party going!",
        "Don't let these free days slip away! Take advantage of our awesome features for the next {days} days. \nUpgrade when you're ready for the full experience!",
        "Your free ride is almost over! Enjoy the next {days} days of our top-notch tools and features. \nUpgrade now to keep the good times rolling!",
        "Only {days} days left of the free ride! Make the most of our amazing tools and features while you can. \nUpgrade when you're ready to keep the party going!",
        "Time is money! But for the next {days} days, our features are on us. \nEnjoy the ride and upgrade when you're ready for even more awesomeness!"
    ]

    const subscription_ended_phrases = [
        "Sorry, your trial or subscription has ended.\nUpgrade to continue saving!",
        "Your subscription has expired.\nUpgrade now to keep saving!",
        "Time to renew your subscription!\nUpgrade to keep the savings coming.",
        "Your free trial has ended.\nUpgrade now to keep saving big!",
        "Your subscription has lapsed.\nUpgrade to start saving again!"
    ];


    useEffect(() => {
        setDays(props.days_left);
        if (props.days_left < 0) {
            // No trial or subscrption
            const randomIndex = Math.floor(Math.random() * subscription_ended_phrases.length);
            const message = subscription_ended_phrases[randomIndex].replace("{days}", ~~props.days_left+1);
            setMessage(message);
        } else {
            // Free Trail or active
            const randomIndex = Math.floor(Math.random() * free_trial_phrases.length);
            const message = free_trial_phrases[randomIndex].replace("{days}", ~~props.days_left+1);
            setMessage(message);
        }
    }, [props.days_left]);


    return (
        <>
            {days >= 500 ? (
                // Active subscription
                // no message
                <></>
            ) : (
                <a target="_blank" href={"https://billing.stripe.com/p/login/fZe4k87s753y0Fy288?prefilled_email=" + props.email}>
                <div className='w-100 d-flex justify-content-center' 
                style={days === "-1"
                    ? {backgroundColor:'#e15956',color:'white',position: 'sticky', top: 0, left: 0, zIndex: 30}
                    : {backgroundColor:'#c479c7',color:'white',position: 'sticky', top: 0, left: 0, zIndex: 30}}
                >
                    <div className='p-3 w-100 text-center'>
                        {message.split('\n').map((line, index) => (
                            index === message.split('\n').length - 1 ?
                                <span key={index}>{line} <FontAwesomeIcon icon={faArrowRight} className='ms-2'/></span> :
                                <div key={index} className='py-0'>{line}</div>
                        ))}
                    </div>
                </div>
                </a>
            )}
        </>

    );
}

export default App;