import React from 'react';
import { Routes as Switch, BrowserRouter as Router, Route } from 'react-router-dom';
//import { BrowserRouter, useNavigate as withRouter } from 'react-router-dom';

import Navigation from './Navigation';
import Onboarding from './Onboarding';
import OnboardingOld from './OnboardingOld';
import ReturnUser from './ReturnUser';
import History from './History';
import Budget from './Budget';
import Accounts from './Accounts';
import Analytics from './Analytics';
import AddTransaction from './AddTransaction';
import EditTransaction from './EditTransaction';
import Category from './Category';
import Login from './Login';
import NewAccount from './SignUp';
import SignUp from './waitlist/SignUp';
import Settings from './Settings';
// import Test from './Test';

const Main = () => {
  return (
<div className='fullHeight'>
      <Router>
      {window.location.pathname == '/' 
        || window.location.pathname == '/EditTransaction'
        || window.location.pathname == '/waitlist'
        || window.location.pathname == '/waitlist/'
        || window.location.pathname == '/AddTransaction'
        || window.location.pathname == '/Login/'
        || window.location.pathname == '/NewAccount'
        || window.location.pathname == '/Test'
        || window.location.pathname == '/Onboarding'
        || window.location.pathname == '/Onboarding-new'
        || window.location.pathname == '/ReturnUser'
        || window.location.pathname == '/login'
        || window.location.pathname == '/Login'
         ? (
          <div></div>
        ) : (
          <div></div>
          // <Navigation key="nav-bar-key" />
        )}
        <Switch>
          <Route path="/" element={<Budget />} />
          <Route path='/AddTransaction' element={<AddTransaction/>} />
          <Route path='/EditTransaction' element={<EditTransaction/>} />
          <Route path='/Analytics' element={<Analytics/>} />          
          <Route path='/Budget' element={<Budget/>} />          
          <Route path='/History' element={<History/>} />   
          <Route path='/Accounts' element={<Accounts/>} />
          <Route path='/Category' element={<Category/>} />
          <Route path='/Login' element={<Login/>} />
          <Route path='/NewAccount' element={<NewAccount/>} />
          <Route path='/Settings' element={<Settings/>} />
          <Route path='/Onboarding' element={<Onboarding/>} />
          <Route path='/OnboardingOld' element={<OnboardingOld/>} />
          <Route path='/ReturnUser' element={<ReturnUser/>} />
          {/* <Route path='/Test' element={<Test/>} /> */}
        </Switch>
      </Router>





      {/* <BrowserRouter>
        {window.location.pathname == '/' 
        || window.location.pathname == '/EditTransaction'
        || window.location.pathname == '/waitlist'
        || window.location.pathname == '/waitlist/'
        || window.location.pathname == '/AddTransaction'
         ? (
          <div></div>
        ) : (
          <div></div>
          // <Navigation key="nav-bar-key" />
        )}
        <Switch> 
          <Route exact path='/' component={SignUp}></Route>
          <Route exact path='/budget' component={Budget}></Route>
          <Route exact path='/history' component={History}></Route>
          <Route exact path='/Accounts' component={Accounts}></Route>
          <Route exact path='/AddTransaction' component={AddTransaction}></Route>
          <Route exact path='/EditTransaction' component={EditTransaction}></Route>
          <Route exact path='/Category' component={Category}></Route>
          <Route exact path='/login' component={Login}></Route>
          <Route exact path='/waitlist' component={SignUp}></Route>
          <Route exact path='/waitlist/' component={SignUp}></Route>
        </Switch>
      </BrowserRouter> */}
    </div>
  );
}

export default Main;