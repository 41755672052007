import React, { Component, useState } from 'react';
import ScrollMonthMenu from './ScrollMonthMenu';
import DesktopScrollMonthMenu from './components/DesktopScrollMonthMenu';
import LoadingSpinner from "./LoadingSpinner";
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import TourButton from './components/TourButton';
import { budgetsteps } from './toursteps/budgetsteps';
// Note: Only Solid and Brands are avail for free. "Regular" etc are Pro.

// import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used


// import { SwipeToRevealActions } from './react-swipe-to-reveal-actions';
// import SwipeToRevealActions from './SwipeToRevealActions';
// import { SwipeListView } from 'react-native-swipe-list-view';
// import { SwipeToRevealOptions } from 'react-swipe-to-reveal-options2';

import { firebaseAuth } from './firebase.js';
import { withRouter, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './index.css'
import Lottie from 'lottie-react';
import greenCheck from './lotties/greencheck.json';
import { Button, Modal } from 'react-bootstrap';

import BudgetSummary from './components/BudgetSummary';
import BudgetCategory from './components/budgetCategory';
import DesktopBudgetCategory from './components/DesktopBudgetCategory';
import FreeTrialAlert from './components/FreeTrialAlert';
import Navigation from './Navigation';
import ConfirmScreen from './components/ConfirmScreen.js';

import { reformatDate, roundDownOneDecimal } from "./util/Util";

const defaultOptions = {
	loop: false,
	autoplay: true,
	animationData: greenCheck,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice"
	}
};

// console.log('BUDGET', budgetsteps);

const dbURL = 'https://vermillionapp.fly.dev/';
// const dbURL = 'https://shielded-bastion-58122.herokuapp.com/';


const today = new Date();



// library.add(faCoffee);
export default function Budget(props) {
	let navigate = useNavigate();

	const toCurrency = (x) => {
		// *** Convert to format $0.00
		return (x / 100).toLocaleString('US', {
			style: "currency",
			currency: "USD"
		});
	}

	const toNumber = (x) => {
		// console.log("ATTEMPTING UPDATE", x);
		const newX = 100 * Number(String(x).replace(/[^0-9.-]/g, '')
			.replace(/(\..*)\./g, '$1')
			.replace(/(?!^)-/g, '')
			.replace(/^0+(\d)/gm, '$1'));
		// console.log('Turning into Number...' + newX);
		return (newX);

	}
	const [userID, setUserID] = useState('');
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [idToken, setIdToken] = useState('');
	const [months, setMonths] = useState([]);
	const [budget, setBudget] = useState([]);
	const [oldBudget, setOldBudget] = useState([]);
	const [avail, setAvail] = useState(0);
	const [confirmed, setConfirmed] = useState(false);
	var newDate = new Date();
	const [currMonth, setCurrMonth] = useState(newDate);
	// const [currMonthName, setCurrMonthName] = useState('');
	let currMonthName= '';
	const [showNeedAccount, setShowNeedAccount] = useState(false);

	let [income, setIncome] = useState(0);
	let [saved, setSaved] = useState(0);
	let [pretax, setPretax] = useState(0);
	let [saveRate, setSaveRate] = useState(0);
	let [saveRateGoal, setSaveRateGoal] = useState(0);
	let [leftToBudget, setLeft] = useState(0);
	let [days_left, setDaysLeft] = useState(600);
	const leftToBudget_display = toCurrency(Math.abs(leftToBudget));
	console.log(income, saved, pretax, saveRate, leftToBudget, days_left)

	const api_get_months = () => {
		// setIsLoading(true);

		return firebaseAuth.currentUser.getIdToken()
			.then((idToken) => {
				const data = {
					token: idToken
				};
				const requestOptions = {
					method: 'GET'
				};
				// Where we're fetching data from
				const baseURL = dbURL + 'getMonths?';
				const reqURL = baseURL
					+ 'token=' + data.token;
				// console.log(reqURL);
				return fetch(reqURL, requestOptions)
					// We get the API response and receive data in JSON format
					.then((response) => {
						// console.log(response);
						return (response.json());
					})
					.then((data) => {
						const newData = data.results.rows;
						// console.log("Months", newData);
						// setIsLoading(false);
						return (newData);
					})
					.catch((error) => console.error(error));
			})
			.catch((error) => {
				console.log("No user logged in!");
				throw error;
			});
	}


	const api_get_budget = (selectedMonth) => {
		// setIsLoading(true);

		return firebaseAuth.currentUser.getIdToken()
			.then((idToken) => {
				const data = {
					token: idToken,
					month: selectedMonth
				};
				const requestOptions = {
					method: 'GET'
				};
				// Where we're fetching data from
				const baseURL = dbURL + 'getBudget?';
				const reqURL = baseURL
					+ 'token=' + data.token
					+ '&month=' + data.month;
				// console.log(reqURL);
				return fetch(reqURL, requestOptions)
					// We get the API response and receive data in JSON format
					.then((response) => response.json())
					.then((data) => {
						const newData = data.results.rows
						// setIsLoading(false);
						return (newData);
					})
					.catch((error) => console.error(error));
			})
			.catch((error) => {
				console.log("No user logged in!");
				throw error;
			});

	}

	const api_update_budget = (budgetid, newAmount) => {
		// setIsLoading(true);
		// console.log("About to make the API reuqest...POST");

		return firebaseAuth.currentUser.getIdToken()
			.then((idToken) => {
				const data = {
					token: idToken, budgetid: budgetid, amount: Math.round(newAmount)
				};
				const requestOptions = {
					method: 'POST',
					// headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({})
				};

				// console.log('Adding before api post...');
				// Where we're fetching data from
				const baseURL = dbURL + 'updatebudget?';
				const reqURL = baseURL
					+ 'token=' + data.token
					+ '&budgetid=' + data.budgetid
					+ '&amount=' + data.amount;
				// + '&categoryid=' + data.categoryid
				// + '&merchant=' + encodeURIComponent(data.merchant)
				// + '&notes=' + data.notes
				// + '&amount=' + data.amount
				// + '&occurred=' + data.occurred_at;
				// console.log("POST", reqURL);
				return fetch(reqURL, requestOptions)
					// We get the API response and receive data in JSON format
					.then((response) => {
						// console.log("Update budget response", JSON.parse(JSON.stringify(response)));
						// setIsLoading(false);
						return (JSON.parse(JSON.stringify(response)));
					})
					// .then((data) => console.log("Adding after api post.." + data))
					.catch((error) => console.error(error));
			})
			.catch((error) => {
				console.log("No user logged in!");
				throw error;
			});

	}

	const api_add_category = (newName, newSection) => {
		return firebaseAuth.currentUser.getIdToken()
			.then((idToken) => {
				const data = {
					token: idToken,
					name: encodeURIComponent(newName),
					section: encodeURIComponent(newSection)
				};
				const requestOptions = {
					method: 'POST',
					// headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({})
				};

				// console.log('Adding before api post...');
				// Where we're fetching data from
				const baseURL = dbURL + 'addcategory?';
				const reqURL = baseURL
					+ 'token=' + data.token
					+ '&name=' + encodeURIComponent(data.name)
					+ '&section=' + data.section;
				// console.log(reqURL);
				// return(1);
				return fetch(reqURL, requestOptions)
					// We get the API response and receive data in JSON format
					.then((response) => response)
					// .then((data) => console.log("Adding after api post.." + data))
					.catch((error) => console.error(error));
			})
			.catch((error) => {
				console.log("No user logged in!");
				throw error;
			});

	}

	useEffect(() => {
		setIsLoading(true);
		firebaseAuth.onAuthStateChanged(function (user) {
			if (user) {
				// User is signed in.
				setUserID(firebaseAuth.currentUser.uid);
				setEmail(encodeURIComponent(firebaseAuth.currentUser.email));
				// const idToken2 = await 
				user.getIdToken()
					.then(idToken => {
						setIdToken(idToken);
						api_get_months()
							.then((data) => {
								const newData = data;
								setMonths(newData);
								// console.log("months",newData)
								var numMonths = newData.length;
								if (numMonths > 1) {
									var newDate = new Date(newData[numMonths - 1]['month']);
									// setCurrMonthName(newData[numMonths - 2]['name']);
									currMonthName = newData[numMonths - 2]['name'];
									setCurrMonth(newData[numMonths - 2]['month']);
									// console.log("setting account to...", newData[numMonths - 2]['name']);
								} else if (numMonths == 1) {
									var newDate = new Date(newData[numMonths - 1]['month']);
									// setCurrMonthName(newData[numMonths - 1]['name']);
									currMonthName = newData[numMonths - 1]['name'];
									setCurrMonth(newData[numMonths - 1]['month']);
									// console.log("setting account to...", newData[numMonths - 1]['name']);
								}
								// setIsLoading(false);
							})
					});
				// console.log('logged in!' + firebaseAuth.currentUser.uid);
			} else {
				// No user is signed in.
				console.log('no one signed in');
				navigate('/login');
			}
		});
	}, []);

	useEffect(() => {
		if (idToken != '') {
			if (currMonth != undefined) {
				api_get_budget(currMonth).then((data) => {
					const newData = data;
					// console.log("Budget items...", newData);
					setBudget(newData);
					setOldBudget(JSON.parse(JSON.stringify(newData)));
					setLeft(newData[0]['m_income'] - newData[0]['m_budgeted']);
					setIncome(newData[0]['m_income']);
					setSaved(newData[0]['m_saved']);
					setPretax(newData[0]['m_pretax']);
					setSaveRateGoal(newData[0]['save_rate_goal']);
					setDaysLeft(newData[0]['days_left']);
					// console.log("Setting days left to ", newData[0]['days_left']);
					setSaveRate((1.0 * newData[0]['m_saved'] + 1.0 * newData[0]['m_pretax']) / (1.0 * newData[0]['m_income'] + 1.0 * newData[0]['m_pretax']));
					// console.log("SAVE RATE", (1.0 * newData[0]['m_saved'] + 1.0 * newData[0]['m_pretax']) / (1.0 * newData[0]['m_income'] + 1.0 * newData[0]['m_pretax']));

					setIsLoading(false);
				});
			} else {
				console.log("User or month undefined");
			}
		}
	}, [currMonth]);

	useEffect(() => {
		// This is where we allow the modal to be shown
		if (!isLoading && months.length > 0 && months[0]['has_account'] !== undefined) {
			setShowNeedAccount(months[0]['has_account'] <= 0);
		}
	  }, [isLoading, months]);


	const getBudgetChanges = (x) => {
		// Compare new budget to old to get the changes
		// m_budgeted, budgeted, ..........balance???
		// issue: it's updating the oldBudget..
		// if changes exist, show a "Revert changes?"
		// don't let them move to the next month without saving? 
		// Changes in budget category
		// console.log("Changes: Old budget", oldBudget);
		// console.log("Changes: New budget", budget);
		let changes = [];
		// let oldBudgeted = 0;
		// let budgeted = 0;
		for (let i = 0; i < oldBudget.length; i++) {
			if (oldBudget[i]['budgeted'] != budget[i]['budgeted']) {
				changes.push([oldBudget[i]['budgetid'], oldBudget[i]['budgeted'], budget[i]['budgeted']]);
				api_update_budget(oldBudget[i]['budgetid'], budget[i]['budgeted']);
				// update the "old budget" to now include this!
				const newOldBudget = oldBudget;
				newOldBudget[i]['budgeted'] = budget[i]['budgeted'];
				setOldBudget(newOldBudget);
			}
			// oldBudgeted += oldBudget[i]['budgeted'];
			// budgeted += budget[i]['budgeted'];
		}
		console.log('updates', changes);
		//   console.log('updates',oldBudgeted,budgeted);

		// Change in m_budgeted
		// console.log();
	}

	const updateBudgetAmt = (newId, newVal, oldVal, itemId) => {
		console.log("Updating the budget......supposedly", newId, newVal, oldVal, itemId);
		const newValNum = toNumber(newVal);
		const newBudget = budget;
		newBudget.filter(d => d.budgetid == itemId)[0]['budgeted'] = newValNum;
		const newLeft = leftToBudget + oldVal - newValNum;
		const oldLeft = leftToBudget;
		const newBal = newBudget.filter(d => d.budgetid == itemId)[0]['balance'] - oldVal + newValNum;
		newBudget.filter(d => d.budgetid == itemId)[0]['balance'] = newBal;
		setBudget(newBudget);
		setLeft(newLeft);

		// Get sectionid of changed
		let updatedCatType = newBudget.filter(d => d.budgetid == itemId)[0]['sectionid'];
		console.log("updated this type of cat", updatedCatType);
		if (updatedCatType === '3') {
			// console.log("updated this cat", saved, oldVal, toNumber(newVal));
			const oldSaved = saved;
			const newSaved = saved - oldVal + toNumber(newVal);

			setSaved(newSaved)
			// console.log("Updating save rate",newSaved);
			setSaveRate((1.0 * newSaved + 1.0 * newBudget[0]['m_pretax']) / (1.0 * newBudget[0]['m_income'] + 1.0 * newBudget[0]['m_pretax']));
		}

		getBudgetChanges();
		// this.setState();
	}

	function hotBalance(bal, bud, id) {
		const newBal = (bud - bal) / 100.0;
		const newId = id.replace('budget-input-', '');
		console.log("HOT BALANCE", bal, bud, id, newId, newBal);
		// document.getElementById({ id }).defaultValue = toCurrency(newBal);
		console.log(budget.filter(d => d.budgetid == newId)[0]['budgeted']);
		const oldVal = budget.filter(d => d.budgetid == newId)[0]['budgeted'];
		// console.log(oldVal);
		updateBudgetAmt(newId, newBal, oldVal, newId);
	}

	const addNewCat = () => {
		const newName = document.getElementById('category-name').value;
		const newSection = document.getElementById('category-section').value;
		console.log(newName, newSection);
		if (newName != '' && newName != null && newName != undefined) {
			if (newSection != '' && newSection != null && newSection != undefined) {
				api_add_category(newName, newSection);
				setTimeout(() => {
					console.log("Toggling the other value...");
					setConfirmed(!confirmed);
				}, 550);
				return (1);
			} else {
				alert("Invalid category section.");

			}

		} else {
			alert("Invalid category name.");
		}

	}

	function HiddenCategoriesButton() {
		const [show, setShow] = useState(false);

		const handleClose = () => setShow(false);
		const handleShow = () => setShow(true);

		return (
			<>
				<div className='button button-lightGreen'
					onClick={handleShow}>
					Hidden Categories
				</div>


				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Hidden Categories</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<> {budget != undefined && budget != [] ? (
							budget.map((budget_item, index) => (
								<div key={`keyhidden-${index}`}>
									{budget_item.hidden_at != null ? (
										<div id={budget_item} key={`keybudget-${index}`}>
											<BudgetCategory
												itemId={budget_item.budgetid} // NOTE: itemId is required for track items
												name={budget_item.name}
												budgeted={budget_item.budgeted}
												spent={budget_item.spent}
												bal={budget_item.balance}
												catid={budget_item.categoryid}
												budget={budget}
												hotBalance={hotBalance}
												updateBudgetAmt={updateBudgetAmt}
											// onClick={handleClick(id)}
											/>
										</div>
									) : (
										<span></span>
									)}
								</div>
							))) : (
							<div></div>
						)}

						</>
					</Modal.Body>
					<Modal.Footer>
						{/* <div className='button button-darkGreen' onClick={() => addNewCat()}>
							Save
						</div> */}
						<div className="button button-darkGreen" onClick={handleClose}>
							Done
						</div>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	function AddCategoryButton() {
		const [show, setShow] = useState(false);

		const handleClose = () => setShow(false);
		const handleShow = () => setShow(true);

		return (
			<>
				<div className='button button-darkGreen'
					onClick={handleShow}>
					+ Add Category
				</div>


				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Add New Category</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-3">
							<input type="text" className="form-control"
								id="category-name"
								placeholder='Category Name'
							// onBlur={e => {
							// updateAmt(e.target.value);}}
							/>
						</div>
						<div className="mb-3">
							<select name="category-section" className="form-control" id="category-section">
								<option value=''>--select a section--</option>
								<option value="1">Everyday Expenses</option>
								<option value="2">Monthly Bills</option>
								<option value="3">Savings</option>
								<option value="4">Debt</option>
							</select>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className='button button-darkGreen' onClick={() => addNewCat()}>
							Save
						</div>
						<div className="button button-lightGreen" onClick={handleClose}>
							Cancel
						</div>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	function NeedToAddAccountModal() {
		const [show, setShow] = useState(true);

		// const handleClose = () => setShow(false);
		// const handleShow = () => setShow(true);

		return (
			<>
				<Modal show={show} >
					<Modal.Header>
						<Modal.Title>Add Money to Budget</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="col">
							<div className="">Your budget won't work without any money to fund it! Head over to the Accounts page to add a new account so that you can begin budgeting.</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className='button button-darkGreen' onClick={() => navigate('/Accounts')}>
							Go to Accounts →
						</div>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	class BudgetAlert extends Component {
		render() {
			return (
				<>
					<div className='budgetAlert'>
						{leftToBudget >= 0 ? (
							leftToBudget == 0 ? (
								<div></div>
							) : (
								<div className="alert alert-success" role="alert">
									<h4 className="alert-heading text-center">Unbudgeted: {this.props.leftToBudget_display}</h4>
									{/* <p>You have {leftToBudget_display} available to budget.</p> */}
								</div>)

						) : (
							<div className="alert alert-danger" role="alert">
								<h4 className="alert-heading text-center">Overbudget: {this.props.leftToBudget_display}</h4>
								{/* <p>You've budgeted {leftToBudget_display} more than you have available.</p> */}
							</div>
						)}
					</div>
				</>
			)
		};
	}


	class DesktopBudgetAlert extends Component {
		render() {
			return (
				<>
					<div className='budgetAlert'>
						{leftToBudget >= 0 ? (
							leftToBudget == 0 ? (
								<div className="desktopAlert alert-grey" role="alert">
									<h4 className="alert-heading-regular text-center">Every dollar budgeted!</h4>
								</div>
							) : (
								<div className="desktopAlert alert-success" role="alert">
									<h4 className="alert-heading text-center">Unbudgeted: {this.props.leftToBudget_display}</h4>
								</div>)

						) : (
							<div className="desktopAlert alert-danger" role="alert">
								<h4 className="alert-heading text-center">Overbudget: {this.props.leftToBudget_display}</h4>
							</div>
						)}
					</div>
				</>
			)
		};
	}

	function BudgetList() {
		return (
			<div id="budgetList">
				{budget != undefined && budget != [] ? (
					budget.map((budget_item, index) => (
						<div id={"bc-" + index} key={"bc-" + index}>
							{budget_item.hidden_at == null ? (
								<div id={"budget_item-" + index} key={`keybudget-${index}`}>
									<BudgetCategory
										itemId={budget_item.budgetid} // NOTE: itemId is required for track items
										name={budget_item.name}
										budgeted={budget_item.budgeted}
										spent={budget_item.spent}
										bal={budget_item.balance}
										catid={budget_item.categoryid}
										budget={budget}
										hotBalance={hotBalance}
										updateBudgetAmt={updateBudgetAmt}
										is_disabled={days_left === '-1'}
									// onClick={handleClick(id)}
									/>
								</div>
							) : (
								<span></span>
							)}
						</div>
					))) : (
					<div></div>
				)}

			</div>
		);
	}

	function DesktopBudgetList() {
		return (
			<div id="budgetList">
				{budget != undefined && budget != [] ? (
					budget.map((budget_item, index) => (
						<div id={"bc-" + index} key={"bc-" + index}>
							{budget_item.hidden_at == null ? (
								<div id={"budget_item-" + index} key={`keybudget-${index}`}>
									<DesktopBudgetCategory
										itemId={budget_item.budgetid} // NOTE: itemId is required for track items
										name={budget_item.name}
										budgeted={budget_item.budgeted}
										spent={budget_item.spent}
										bal={budget_item.balance}
										catid={budget_item.categoryid}
										budget={budget}
										hotBalance={hotBalance}
										updateBudgetAmt={updateBudgetAmt}
										is_disabled={days_left === '-1' ? true : false}
									// onClick={handleClick(id)}
									/>
								</div>
							) : (
								<span></span>
							)}
						</div>
					))) : (
					<div></div>
				)}

			</div>
		);
	}


	return (
		<>
			<FreeTrialAlert days_left={days_left} email={email} />
			{showNeedAccount && <NeedToAddAccountModal />}

			{/* <div style={days_left === '-1' || days_left < 500
				? { marginTop: '80px' }
				: {}}> */}
				<div>
				<Navigation key="nav-bar-key" />
				{confirmed ? (
					<ConfirmScreen
						buttonList={[['Back to Budget', '']]}
					/>
				) : (
					<>
						{isLoading ? (
							<LoadingSpinner />
						) : (
							<div></div>
						)}
						<>
							{/* Desktop */}
							<div className='d-none d-lg-block greyBG fullBG'>
								<div className='contentSpace'>
									<div className='row ms-5'>
										<div className='col-8'>
											<div className='row'>
												<div className='col mt-5 text-dark fw-bold fs-2'>
													Welcome back!
													<div className='mt-2 fs-6'>
														{today.toLocaleString('en-EN', { weekday: 'long' })}, {(today.toLocaleString('en-EN', { month: 'long', day: 'numeric', year: 'numeric' })).replace(',', '')}
													</div>

												</div>
												<div className='col mt-5'>
													<div>
														<DesktopBudgetAlert
															leftToBudget_display={leftToBudget_display} />
													</div>
												</div>
											</div>
											<div className='mt-5'>
												<DesktopScrollMonthMenu
													selectedMonth={currMonth}
													monthsList={months}
													updateMonth={setCurrMonth} />
											</div>
											<div className='mt-5'>
												<DesktopBudgetList />
											</div>

										</div>
										<div className='col-3' id="desktopMonthSummaryDiv">
											<div
												id="desktopIncomeSummary"
												className='mt-5 desktopPill sidePurple'>
												Income
												<div className='fs-4 fw-bold'>{toCurrency(income)}</div>
											</div>
											<div
												id="desktopExpenseSummary"
												className='mt-5 desktopPill sideRed'>
												Expense
												<div className='fs-4 fw-bold'>{toCurrency(income - saved)}</div>
											</div>

											<div id="desktopSavingsSummary">
												<div className='mt-5 saveRateCircle align-items-center text-center '>
													<div className='row'>

														<div className=' col-12 desktopSaveRateDisplay'>
															{/* {(((isNaN(saveRate) || saveRate === undefined || saveRate === null) ? 0 : saveRate) * 100).toFixed(3)}% */}
															{roundDownOneDecimal(((isNaN(saveRate) || saveRate === undefined || saveRate === null) ? 0 : saveRate) * 100)}%
														</div>
													</div>
													<div className='row'>
														<div className=' col-12 fs-4 fw-bold text-center'>Save Rate</div>

													</div>
												</div>
												<div className='bg-white desktopSaveContainer container'>
													<div className="col clearfix">
														<FontAwesomeIcon icon={faCircle}
															className="ms-1 colorDot colorDotPretax" />
														<span className='summaryLabel float-left'>Pre-Tax </span>
														<span className='summaryValue float-right fw-bold'>{toCurrency(pretax)}</span>
													</div>
													<div className="col clearfix">
														<FontAwesomeIcon icon={faCircle}
															className="ms-1 colorDot colorDotPosttax" />
														<span className='summaryLabel float-left'>Post-Tax </span>
														<span className='summaryValue float-right fw-bold'>{toCurrency(saved)}</span>
													</div>
													<hr />
													<div className="col clearfix">
														<FontAwesomeIcon icon={faCircle}
															className="ms-1 colorDot colorDotClear" />
														<span className='summaryLabel float-left'>Total </span>
														<span className='summaryValue float-right fw-bold'>{toCurrency((toNumber(pretax) + toNumber(saved)) / 100.0)}</span>
													</div>

													<div className='desktopToReachGoal'>
														<div className='fs-4 fw-bold'>
															{isNaN(saveRate)
																? toCurrency(0) // Set the goal to $0 when saveRate is NaN
																: saveRate > (saveRateGoal / 100.0)
																	? toCurrency((saveRate - (saveRateGoal / 100.0)) * (1.0 * income + 1.0 * pretax))
																	: toCurrency(((saveRateGoal / 100.0) - saveRate) * (1.0 * income + 1.0 * pretax))}
														</div>
														<div className='fs-6 fw-bold'>
															{isNaN(saveRate)
																? 'over goal' // Custom message when saveRate is NaN
																: saveRate > (saveRateGoal / 100.0)
																	? 'over goal'
																	: 'to reach goal'} of {(saveRateGoal).toFixed(1)}%
														</div>
													</div>
												</div>
											</div>
											<div className='mt-5'>
												<div className='d-flex justify-content-center text-center'>
													<HiddenCategoriesButton />
												</div>
												<div className='d-flex justify-content-center text-center pt-3'>
													<AddCategoryButton />
												</div>
											</div>
										</div>
									</div>
								</div>
								<TourButton steps={budgetsteps} />
							</div>
							{/* Mobile */}
							<div className='greyBG d-block d-lg-none '>
								<ScrollMonthMenu
									selectedMonth={currMonth}
									monthsList={months}
									updateMonth={setCurrMonth} />
								<div className="container-sm mb-5 pb-5">

									<BudgetSummary
										income={income}
										saved={saved}
										pretax={pretax}
										saveGoal={saveRateGoal}
										saveRate={1 * (saveRate).toFixed(13)}
									/>
									<BudgetAlert leftToBudget_display={leftToBudget_display} />
									<BudgetList></BudgetList>
									<div className='row'>
										<div className='col  d-flex justify-content-center text-center'>
											<HiddenCategoriesButton />
										</div>
										<div className='col  d-flex justify-content-center text-center'>
											<AddCategoryButton />
										</div>
									</div>

									<div id='bottomSpacing'></div>
								</div>
							</div>

						</>
					</>
				)}
			</div>
		</>
	);
}

