import React, { useEffect, useState } from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import './index.css'


const today = new Date();

function App(props) {
    // const [months,setMonths] = useState(props.monthsList);
    const [selected, setSelected] = useState([]);
    const [position, setPosition] = useState([]);

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const scrollToMonth = () => {
        // Get active month
        // $("select.x")
        var myDivElement = document.getElementById("activeMonth");
        var options = { inline: "center", block: "end" }
        if (myDivElement !== null && myDivElement !== undefined) {
            myDivElement.scrollIntoView(options);
        }
        return (1);
    }

    scrollToMonth();
    useEffect(() => {
        // console.log("setting budget month with: ", props.monthsList);
        if (props.monthsList != [] && props.monthsList != undefined) {
            const listLength = (props.monthsList).length;
            let latestMonth = '';
            let latestPosition = 0;
            if (listLength > 1) {
                latestMonth = props.monthsList[listLength - 2]['month'];
                latestPosition = listLength - 2;
            } else if (listLength == 1) {
                latestMonth = props.monthsList[0]['month'];
                latestPosition = listLength - 1;
            } else {
                latestMonth = '1/1/1000';
            }
            setSelected([latestMonth]);
            setPosition(latestPosition);
        }
    }, [props.monthsList]);

    useEffect(() => {
        

        document.addEventListener('DOMContentLoaded', function () {
            console.log("Content is loaded now");
        }, false);
    }, []);

    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);
                var blah = [];
                props.updateMonth(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? blah = [id]
                        // if item is currently selected, do nothing
                        : currentSelected = [id]
                    // if it's not currently selected, add it!
                );
            };

    return (
        <>
            <div className='bg-white navMonthDate ps-4 pt-4'>
                <div id="currentDay">
                    {today.toLocaleString('en-EN', { weekday: 'long' })}
                </div>
                <div id="currentDate">
                    {(today.toLocaleString('en-EN', { month: 'long', day: 'numeric', year: 'numeric' })).replace(',','')}
                </div>
            </div>
            <div className='bg-white scrollNavMonths css-box-shadow'>
                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                    {(props.monthsList).map(({ month, name, year }) => (
                        <div id={month} key={`keymonth-${month + '-' + year}`}>
                            <Card
                                itemId={month} // NOTE: itemId is required for track items
                                monthName={name}
                                year={year}
                                key={month}
                                onClick={handleClick(month)}
                                selected={isItemSelected(month)}
                            />
                        </div>
                    ))}
                </ScrollMenu>
            </div>
        </>

    );
}

function LeftArrow() { return (''); }
function RightArrow() { return (''); }

function Card({ onClick, selected, monthName, year, itemId }) {
    const visibility = React.useContext(VisibilityContext);

    return (

        <>
            < div
                onClick={() => onClick(visibility)
                }
                style={{
                    width: '70px',
                }}
                tabIndex={0}
            >
                {selected ? (
                    <div id="activeMonth" className="active monthSingleContainer">
                        <div className="text-center monthSingleName">{monthName}</div>
                        <div className="text-center monthSingleYear">{year}</div>
                    </div>
                ) : (
                    <div className="monthSingleContainer">
                        <div className="text-center monthSingleName">{monthName}</div>
                        <div className="text-center monthSingleYear">{year}</div>
                    </div>
                )}

            </div >

        </>

    );
}

export default App;