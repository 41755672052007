// import firebase from "firebase";
// import "firebase/database";

// let config = {
//     apiKey: "AIzaSyAWU-f4McbKojqiEMcxm3qHCNwJswgyuM8",
//     authDomain: "vermillion-stage.firebaseapp.com",
//     databaseURL: "https://vermillion-stage.firebaseio.com",
//     projectId: "vermillion-stage",
//     storageBucket: "vermillion-stage.appspot.com",
//     messagingSenderId: "741959654739",
//     appId: "1:741959654739:web:e91b74cf0fbcb7285ed5a6"
// };

// firebase.initializeApp(config);
// const firebaseDb = firebase.database();
// const firebaseAuth = firebase.auth();

// export {firebaseDb, firebaseAuth};
// require('dotenv').config()
import { getDatabase } from "firebase/database";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";


let config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID
};

// apiKey: "AIzaSyAWU-f4McbKojqiEMcxm3qHCNwJswgyuM8",
// authDomain: "vermillion-stage.firebaseapp.com",
// databaseURL: "https://vermillion-stage.firebaseio.com",
// projectId: "vermillion-stage",
// storageBucket: "vermillion-stage.appspot.com",
// messagingSenderId: "741959654739",
// appId: "1:741959654739:web:e91b74cf0fbcb7285ed5a6"

// apiKey: process.env.FIREBASE_API_KEY,
// authDomain: process.env.FIREBASE_AUTH_DOMAIN,
// databaseURL: process.env.FIREBASE_DB_URL,
// projectId: process.env.FIREBASE_PROJECTID,
// storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
// messagingSenderId: process.env.FIREBASE_MESSAGING_SENDERID,
// appId: process.env.FIREBASE_APPID

const app = firebase.initializeApp(config);
const firebaseDb = getDatabase(app);
const firebaseAuth = app.auth();
const firebaseGoogleAuth = new firebase.auth.GoogleAuthProvider();

export {firebaseDb, firebaseAuth, firebaseGoogleAuth};