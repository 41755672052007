import React from "react";
// import { Data } from "../util/Data.js";
// import {Charts} from "./PieChart";
// import "./styles.css";
import { useLocation } from "react-router-dom";
import { toNumber, arrayAvg, toCurrency } from '../util/Util'
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';


ChartJS.register(
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Filler
);



// var plugin = {
//     beforeDraw: function (chart, easing) {
//         var chartArea = chart.chartArea;
//         var ctx = chart.ctx;

//         // Replace these IDs if you have given your axes IDs in the config
//         var xScale = chart.scales["x-axis-1"];
//         var yScale = chart.scales["y-axis-1"];

//         var midX = xScale.getPixelForValue(0);
//         var midY = yScale.getPixelForValue(0);

//         // Top left quadrant
//         ctx.fillStyle = '#335C67';
//         ctx.fillRect(
//             chartArea.left,
//             chartArea.top,
//             midX - chartArea.left,
//             midY - chartArea.top
//         );

//         // Top right quadrant
//         ctx.fillStyle = '#AAC9BA';
//         ctx.fillRect(
//             midX,
//             chartArea.top,
//             chartArea.right - midX,
//             midY - chartArea.top
//         );

//         // Bottom right quadrant
//         ctx.fillStyle = '#335C67';
//         ctx.fillRect(
//             midX,
//             midY,
//             chartArea.right - midX,
//             chartArea.bottom - midY
//         );

//         // Bottom left quadrant
//         ctx.fillStyle = '#AAC9BA';
//         ctx.fillRect(
//             chartArea.left,
//             midY,
//             midX - chartArea.left,
//             chartArea.bottom - midY
//         );
//     },
// };




// ChartJS.plugins.register(plugin);

const footer = (tooltipItems) => {
    let sum = 0;

    tooltipItems.forEach(function (tooltipItem) {
        sum += tooltipItem.parsed.y;
    });
    return 'Average of ' + toCurrency(sum * 100) + ' spent each time';
};
const footer2 = (tooltipItems) => {
    let sum = 0;

    tooltipItems.forEach(function (tooltipItem) {
        sum += tooltipItem.parsed.x;
    });
    if (sum === 1) {
        return '' + sum + ' trip in the last 6 months';
    } else {
        return '' + sum + ' trips in the last 6 months';
    }
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            beginAtZero: true,
            suggestedMin: 0,
            ticks: {
                callback: function (value, index, values) {
                    if (parseInt(value) >= 1000) {
                        return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else {
                        return '$' + value;
                    }
                }
            }
        },
        x: {
            beginAtZero: true,
            suggestedMin: 0
        }
    },
    plugins: {
        legend: {
            display: false
        },
        quadrants: {
            topLeft: '#FFDE9F',
            topRight: '#FFDDD6',
            bottomRight: '#FFDE9F',
            bottomLeft: '#C9F2E8',
        },
        tooltip: {
            callbacks: {
                footer: footer,
                beforeFooter: footer2,
                label: function (context) {
                    return '';
                }
            }
        }
    }
};

// label: function(context) {
//     let label = context.dataset.label || '';

//     if (label) {
//         label += ': ';
//     }
//     if (context.parsed.y !== null) {
//         label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
//     }
//     return label;
// }

function QuadLegend() {
    return (
        <>
            <div className="row text-center">
                <div className="col-1"></div>
                <div className="legendLabelContainer col text-center">
                    <span className="legendSquare legendRed">
                    <FontAwesomeIcon icon={faSquare}/></span>
                    <span className="legendLabel">High Spend</span>
                </div>
                <div className="legendLabelContainer col text-center">
                    <span className="legendSquare legendGreen">
                    <FontAwesomeIcon icon={faSquare}/></span>
                    <span className="legendLabel">Low Spend</span>
                </div>
                <div className="col-1"></div>
            </div>

            <div className="row align-items-center">
                <div className="legendLabelContainer col text-center">
                    <span className="legendSquare legendYellow">
                    <FontAwesomeIcon icon={faSquare}/></span>
                    <span className="legendLabel">Moderate Spend</span>
                </div>
            </div>

        </>
    )
}

function CategorySpendScatter(props) {
    const location = useLocation();
    let merchantData = {};
    let merchantDataX = {};
    let merchantDataY = {};
    let middleX = 5;
    let middleY = 5000;
    let thisYearData = [];
    let labels = [];


    // console.log("cat spend3", props.data);
    // const average = array => {array.reduce((a,b) => a+b)/array.length};

    if (props.data !== undefined && props.data !== []) {
        // data: [{x1,y1},{x2,y2}] x= #trans, y=avg
        // merchantDataX = props.data.map((m) => m.merchant_count);
        // merchantDataY = props.data.map((m) => m.merchant_avg);
        if (props.data[0] !== undefined && props.data[0] !== []) {
            // console.log("cat spend4", props.data[0]['midx']);
            // console.log("cat spend4", props.data[0]['midy']);

            var filtered = props.data.filter((m) => toNumber(m.merchant_avg) >= 0)
            merchantData = filtered.map((m) => { return ([m.merchant_count, m.merchant_avg / 100.0]) });
            // console.log("merchant", merchantData);
            // middleX = arrayAvg(merchantDataX);
            labels = props.data.map(m => m.merchant);
            // console.log("merchant", labels);
            // console.log("mom spend4", thisYearData);


            const quadrants = {
                id: 'quadrants',
                beforeDraw(chart, args, options) {
                    const { ctx, chartArea: { left, top, right, bottom }, scales: { x, y } } = chart;
                    const midX = x.getPixelForValue(props.data[0]['midx']);
                    const midY = y.getPixelForValue(props.data[0]['midy'] / 100.0);
                    ctx.save();
                    ctx.fillStyle = options.topLeft;
                    ctx.fillRect(left, top, midX - left, midY - top);
                    ctx.fillStyle = options.topRight;
                    ctx.fillRect(midX, top, right - midX, midY - top);
                    ctx.fillStyle = options.bottomRight;
                    ctx.fillRect(midX, midY, right - midX, bottom - midY);
                    ctx.fillStyle = options.bottomLeft;
                    ctx.fillRect(left, midY, midX - left, bottom - midY);
                    ctx.restore();
                }
            };


            const data = {
                labels,
                datasets: [
                    {
                        label: 'Last Year',
                        data: merchantData,
                        backgroundColor: 'rgba(58, 129, 149, 1)',
                        borderRadius: 5,
                        pointStyle: 'circle'
                    }
                ],
            };


            return (
                <>
                    {labels.length > 1 ? (
                        <div className="momSpendContainer m-3">
                            <div className="h5 text-center fw-bold mb-4">Top Spending By Merchant</div>
                            <QuadLegend />
                            <div className="momSpendWrapper">
                                <Scatter data={data} options={options} plugins={[quadrants]} />
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </>
            );


        }

    }


}

export default CategorySpendScatter;