import React, { Component, useState } from 'react';
import { Form, Button, Spinner, Container, Col, Row, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { v4 as uuidv4 } from 'uuid';
import { generate as referralCode } from 'referral-codes'
import { faCheckSquare, faCoffee, faCopy } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import womancircle from '../img/womancircle.png';
import { BrowserRouter, Route, useHistory, useNavigate as withRouter, Redirect } from "react-router-dom";
// import ConfettiExplosion from 'react-confetti-explosion';
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";



// library.add(faCoffee);


const dbURL = 'https://vermillionapp.fly.dev/';
// const dbURL = 'https://shielded-bastion-58122.herokuapp.com/';

class newButton extends Component {
	render() {
		return (
			<div>HEY THERE!</div>
		)
	}
}


export default function SignUp(props) {

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
	const [userGoal, setGoal] = useState(null);
	const [emailWarning, setEmailWarning] = useState(false);
	// const progressVal = Math.round(100*currentQuestion/questions.length)+5;
	const [selections, setSelections] = useState([-1, -1, -1, -1]);
	const [clicked, setClicked] = useState(false);
	const [waitNumber, setWaitNumber] = useState(null);
	const [newCode, setNewCode] = useState('');
	const [email, setEmail] = useState('');
	const [isExploding, setIsExploding] = useState(false);


	const bigExplodeProps = {
		force: 0.6,
		duration: 5000,
		particleCount: 200,
		floorHeight: 1600,
		floorWidth: 1600
	};

	const littleExplodeProps = {
		force: 0.4,
		duration: 3000,
		particleCount: 60,
		floorHeight: 1000,
		floorWidth: 1000
	};

	const tinyExplodeProps = {
		force: 0.4,
		duration: 2000,
		particleCount: 30,
		floorHeight: 500,
		floorWidth: 300
	};

	// function confettiButton() {
	// 	const [isExploding, setIsExploding] = React.useState(false);
	// 	console.log(1);
	// 	return (
	// 		<div>
	// 			<button
	// 				type="btn btn-success"
	// 				onClick={() => setIsExploding(!isExploding)}
	// 			>
	// 				{isExploding && (
	// 					<div>
	// 						<ConfettiExplosion {...bigExplodeProps} />
	// 					</div>
	// 				)}
	// 				Explode!
	// 			</button>
	// 		</div>
	// 	);
	// }

	function get_code() {
		// Get referrer code
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const refCode = urlParams.get('ref')
		return (refCode)
	}

	function api_submit_email() {
		setEmail(document.getElementById('email_input').value);
		const email = document.getElementById('email_input').value;
		const refCode = get_code();
		const newCode = referralCode({
			length: 8,
			count: 1,
		})[0];
		const data = {
			refcode: refCode, newcode: newCode,
			email: email
		};
		const requestOptions = {
			method: 'POST',
			// headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({})
		};
		console.log('Adding before transfer api post...');
		// Where we're fetching data from
		const baseURL = dbURL + 'waitlist?';
		const reqURL = baseURL
			+ 'email=' + encodeURIComponent(data.email)
			+ '&refcode=' + encodeURIComponent(data.refcode)
			+ '&newcode=' + encodeURIComponent(data.newcode);
		console.log(reqURL);
		return fetch(reqURL, requestOptions)
			// We get the API response and receive data in JSON format
			.then((response) => response.json())
			.then((data) => {
				const newData = data.results.rows
				console.log("the new data is", data.results[0]);
				setWaitNumber(data.results[0]['waitlist_number']);
				setNewCode(data.results[0]['refcode']);
				setIsExploding(true);
				// setAccOptions(newData);
			})
			.catch((error) => console.error(error));
	}

	function api_mailinglist_subscribe() {
		console.log("Attempting Mailerlite subscribe...");
		setEmail(document.getElementById('email_input').value);
		const email = document.getElementById('email_input').value;
		const data = {
			email: email
		};
		const requestOptions = {
			method: 'POST',
			// headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({})
		};
		console.log('Adding before transfer api post...');
		// Where we're fetching data from
		const baseURL = dbURL + 'mailingListSubscribe?';
		const reqURL = baseURL
			+ 'email=' + encodeURIComponent(data.email);
		console.log(reqURL);
		return fetch(reqURL, requestOptions)
			// We get the API response and receive data in JSON format
			.then((response) => response.json())
			.catch((error) => console.error(error));

	}

	function attempt_submit_email() {
		// alert(window.location.pathname);
		api_mailinglist_subscribe();
		api_submit_email();
	}

	function copyRefCode() {
		var copyText = document.getElementById("referral_link");
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		navigator.clipboard.writeText(copyText.value);
	}

	// function confetti() {
	// 	return (
	// 		<>
	// 			{isExploding && <ConfettiExplosion />}
	// 		</>
	// 	);
	// }

	return (
		<div>

			<Container className='mt-5'>
				<Row>
					<div className='col-sm-7'>
						<div id="waitlist_title_containter">
							<h1 className='display-1 mt-5' id="waitlist_title">
								<strong>
									Are you ready to take control?
								</strong>
							</h1>
							
							<h4 className='text-muted mt-4'>
								Join our waitlist for early access!
							</h4>


							<div className="mt-5">


								{(clicked) ? (

									(waitNumber == null) ? (
										<div className="mb-3" id="waitlist_input">
											<input
												type="email"
												placeholder="Your Email"
												id="email_input"
												disabled
											/>
											<div className='btn btn-warning'
												disabled
												id="btn_submit">
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												<span className="visually-hidden">Loading...</span>
											</div>
										</div>
									) : (
										<div>
											<div className="mb-3" id="waitlist_input">
												<input
													type="email"
													placeholder="Your Email"
													id="email_input"
													value={email}
													disabled
												/>
												<div className='btn btn-warning'
													id="btn_confirmed">
													✓
												</div>
											</div>
											<div>
												Congrats! You're currently number <span className='waitlist_number'>{waitNumber}</span> in line to receive early access to Vermillion.
												<br />
												<div className='mt-3 mb-3'>
													A limited number of early users will be granted free access to special features. For every friend that signs up using your <span className='waitlist_number'>unique referral link</span>, you'll skip ahead in the waitlist queue and increase your chances of gaining access to special features!
												</div>
												<div className="mb-3" id="referral_input">
													<input
														id="referral_link"
														value={"https://vermillion.app/waitlist?ref=" + newCode}
														disabled />

													<div className='btn btn-info'
														id="btn_confirmed">
														<FontAwesomeIcon icon={faCopy} color="white"
															onClick={() => {
																copyRefCode();
															}} />

													</div>
												</div>

											</div>
											<div id="refSocials" className='mb-3'>
												<a className="socialIcon mb-5" href="https://www.facebook.com" target="_blank">
													<img src="https://cdn3.iconfinder.com/data/icons/social-media-chamfered-corner/154/facebook-512.png"
														className='img_social ms-4' />
												</a>

												<a className="socialIcon mb-5" href="https://www.twitter.com" target="_blank">
													<img src="https://cdn3.iconfinder.com/data/icons/social-media-chamfered-corner/154/twitter-512.png"
														className='img_social ms-4' />
												</a>

												<a className="socialIcon mb-5" href="https://www.instagram.com" target="_blank">
													<img src="https://cdn3.iconfinder.com/data/icons/social-media-chamfered-corner/154/instagram-256.png"
														className='img_social ms-4' />
												</a>

												<a className="socialIcon mb-5" href="https://www.snapchat.com" target="_blank">
													<img src="https://cdn3.iconfinder.com/data/icons/social-media-chamfered-corner/154/snapchat-512.png"
														className='img_social ms-4' />
												</a>

												<a className="socialIcon" href="https://www.tiktok.com" target="_blank">
													<img src="https://cdn-icons-png.flaticon.com/512/3116/3116490.png"
														className='img_social ms-4'
														id="" />
												</a>
											</div>
										</div>
									)
								) : (
									<div className="mb-3" id="waitlist_input">
										<input
											type="email"
											placeholder="Your Email"
											id="email_input"
										/>
										<div className='btn btn-warning'
											onClick={() => {
												setClicked(true);
												attempt_submit_email();
											}}
											id="btn_submit">
											Submit
										</div>
									</div>
								)}
							</div>
						</div>


					</div>
					<div className='col-sm'>
						<img
							src={womancircle}
							className='img-fluid'
							alt="..."></img>
					</div>
				</Row>

			</Container>




		</div>
	);
}

// export default withRouter(SignUp);

// export default function SignUp() {
// 	return (
// 		<div>
// 			sign up
// 		</div>
// 	)
// };