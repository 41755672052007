import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import Toggle from 'react-toggle';
// import useWindowSize from "use-window-size-v2";
// import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti';
import sqarelogo from './img/squarelogo.png';
import { enableLogging } from 'firebase/database';
import GreenLoading from './components/GreenLoading';
import ErrorScreen from './components/ErrorScreen';

function ReturnUser(props) {




    let navigate = useNavigate();
    const [resetEnabled, setResetEnabled] = useState(false);
    const [email, setEmail] = useState('MYEMAIL@EMAIL.COM');
    const [code, setCode] = useState('MYEMAIL@EMAIL.COM');
    const [resetting, setResetting] = useState(false);
    const [isErrorResetting, setIsErrorResetting] = useState(false);
    // const [email, setEmail] = useState(null);
    // const [code, setCode] = useState(null);

    // const [searchParams, setSearchParams] = useSearchParams();
    // searchParams.get("email");
    // console.log(searchParams[0]);

    const dbURL = 'https://vermillionapp.fly.dev/';

    const location = useLocation();

    useEffect(() => {
        // Access UTM parameters from location.search
        const queryParams = new URLSearchParams(location.search);

        setCode(queryParams.get('code'));
        setEmail(queryParams.get('email'));
    }, [location.search]);


    const api_reset_budget = (authToken) => {
        const data = { code: code, email: email };
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };

        // console.log('Adding before api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'returnUser?';
        const reqURL = baseURL
            + 'code=' + data.code
            + '&email=' + data.email;
        console.log(reqURL);
        // return (1);
        return fetch(reqURL, requestOptions)
            .then((response) => response.json())
            .catch((error) => {
                console.error(error);
                throw error; // Rethrow the error to be caught in the outer promise chain
            });
    }

    const attemptReset = () => {
        // const newName = document.getElementById('newAccDescriptionInput').value;
        // console.log(newName);
        setResetting(true);
        api_reset_budget().then((data) => {
            // Check if the returned data contains the expected value
            if (data && data.code === 1) {
                setTimeout(() => {
                    // console.log("Closing modal...");
                    // setCurrEditAccountId('');
                    console.log("done waiting");
                    navigate('/Budget');
                    // console.log("Closing modal...");
                }, 1000);
            } else {
                setIsErrorResetting(true);
            }


        });

    }

    function GoalPage(props) {
        if (!email || !code || isErrorResetting) {
            return <ErrorScreen />;
        }

        if (resetting) {
            return <GreenLoading buttonList={[['Back to Budget', '']]} />
        }

        return (<>
            {/* Desktop */}
            < div className='d-none d-lg-block greyBG' >
                <div className='d-flex justify-content-center mt-5'>
                    <div className='app d-flex flex-column p-5'>
                        <div className='w-100 mt-5 align-content-center'
                            style={{ textAlign: 'center' }}>
                            <img
                                src={sqarelogo}
                                className='navLogo'
                                alt="..." />
                        </div>
                        <div className="d-grid gap-2 mt-auto mb-1 align-content-center">
                            <div className='row mt-5 mb-3'>

                                <div className='text-center mb-3 h1' style={{ color: "#3d3a3a" }}>
                                    Ready to clear off the cobwebs?
                                </div>
                                <div className='text-center mb-3 h5' style={{ color: "#3d3a3a" }}>
                                    Click the button below to reset your budget and start fresh!
                                </div>
                            </div>
                            <div className="container">
                                <div className="row mb-1">
                                    <div className="col-1" id="confirmToggleDiv">
                                        <Toggle
                                            id='confirmToggle'
                                            defaultChecked={resetEnabled}
                                            icons={false}
                                            onChange={() => {
                                                setResetEnabled(!resetEnabled);
                                                // setRerender(!rerender);
                                            }}
                                        />
                                    </div>
                                    <div className="col">
                                        <div className="confirmToggleLabel">I understand that:</div>
                                    </div>
                                </div>
                            </div>
                            <ul>
                                <li>The free trial associated with {email} will restart.</li>
                                <li>Any accounts or transactions I previously logged will be deleted.</li>
                                <li><b>Deletion is permanent and can't be undone.</b></li>

                            </ul>
                            <div className='row'>
                                <div
                                    // className='button button-darkRed p-2 w-75'
                                    className={`button button-darkRed p-2 w-75 ${!resetEnabled ? 'disabled' : ''}`}
                                    style={{ backgroundColor: '#f65f4b', borderRadius: '50px', textAlign: 'center', fontSize: '20px' }}
                                    onClick={() => {
                                        if (resetEnabled) {
                                            // attemptCloseAcc();
                                            console.log("Reset budget!");
                                            attemptReset();
                                        } else {
                                            console.log("Can't close without confirmation.");
                                        }
                                    }}
                                    disabled={!resetEnabled}
                                >
                                    Reset Budget
                                </div>
                            </div>

                        </div>
                        <br />
                        {/* <ForgotPassword email={email} /> */}
                    </div>
                </div>
            </div >
            {/* Mobile */}
            < div className='d-block d-lg-none ' >
                <div className='app d-flex flex-column'>
                    <div className="d-grid gap-2 mt-auto">

                        <div className='w-100 mt-3 align-content-center mb-5'
                            style={{ textAlign: 'center' }}>
                            <img
                                src={sqarelogo}
                                className='navLogo'
                                alt="..." />
                        </div>
                        <div className='row mt-5 mb-3'>

                            <div className='text-center mb-3 h1' style={{ color: "#3d3a3a" }}>
                                Ready to clear off the cobwebs?
                            </div>
                            <div className='text-center h5' style={{ color: "#3d3a3a" }}>
                                Click the button below to reset your budget and start fresh!
                            </div>
                        </div>
                        <div className="d-grid gap-2 mt-auto mb-1 align-content-center ps-3 pe-3">
                            <div className="container" style={{ width: "fit-content" }}>
                                <div className="row mb-3">
                                    <div className="col-1" id="confirmToggleDiv">
                                        <Toggle
                                            id='confirmToggle'
                                            defaultChecked={resetEnabled}
                                            icons={false}
                                            onChange={() => {
                                                setResetEnabled(!resetEnabled);
                                                // setRerender(!rerender);
                                            }}
                                        />
                                    </div>
                                    <div className="col">
                                        <div className="confirmToggleLabel">I understand that:</div>
                                    </div>
                                </div>
                            </div>
                            <ul>
                                <li>The free trial associated with {email} will restart.</li>
                                <li>Any accounts or transactions I previously logged will be deleted.</li>
                                <li><b>Deletion is permanent and can't be undone.</b></li>

                            </ul>
                            <div className='row'>
                                <div
                                    // className='button button-darkRed p-2 w-75'
                                    className={`button button-darkRed p-2 w-50 ${!resetEnabled ? 'disabled' : ''}`}
                                    style={{ backgroundColor: '#f65f4b', borderRadius: '50px', textAlign: 'center', fontSize: '20px' }}
                                    onClick={() => {
                                        if (resetEnabled) {
                                            // attemptCloseAcc();
                                            console.log("Reset budget!");
                                            attemptReset();
                                        } else {
                                            console.log("Can't close without confirmation.");
                                        }
                                    }}
                                    disabled={!resetEnabled}
                                >
                                    Reset Budget
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                {/* <ForgotPassword email={email} /> */}
            </div >


        </>
        )
    }

    return (
        <>
            <GoalPage />
        </>
    );
}

export default ReturnUser;