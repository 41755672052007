import React from 'react';
import { Button } from 'react-bootstrap';
import Lottie from 'lottie-react';
import greenCheck from '../lotties/greencheck.json';
import { useNavigate } from "react-router-dom";



function ConfirmScreen({ buttonList, history }) {
    let navigate = useNavigate();

    function ConfirmScreenButton(text, link, index) {
        return (
            <>
                <Button
                    variant={index === 1 ? ("success") : ("outline-success")}
                    size="lg"
                    onClick={() => {
                        if (link === '') {
                            window.location.reload(false);
                        } else {
                            navigate(link)
                        }
                    }}>
                    {text}
                </Button>
            </>
        )
    };
    // [[buttonText1, buttonLink1], [buttonText2, buttonLink2]]
    return (
        <div>
            <div className='d-none d-lg-block '>
                <div className='contentSpace row greyBG justify-content-center m-0'>
                    <div className='col-5 mt-5'>
                        <div>
                            <Lottie
                                loop={false}
                                autoplay={true}
                                animationData={greenCheck}
                                height={400}
                                width={400}
                            />
                        </div>
                        <div id="confirmedButtons" className=''>
                            <div className="d-grid gap-4">
                                {buttonList.map((button, index) => {
                                        return (
                                            <Button
                                                variant={index === 0 ? ("success") : ("outline-success")}
                                                size="lg"
                                                onClick={() => {
                                                    if (button[1] === '') {
                                                        window.location.reload(false);
                                                    } else {
                                                        navigate(button[1])
                                                    }
                                                }}>
                                                {button[0]}
                                            </Button>

                                        );
                                    })
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className='d-block d-lg-none'>
                <div className=''>
                    <div>
                        <Lottie
                            loop={false}
                            autoplay={true}
                            animationData={greenCheck}
                            height={400}
                            width={400}
                        />
                    </div>
                    <div id="confirmedButtons" className=''>
                        <div className="d-grid gap-4">
                            {buttonList.map((button, index) => {
                                        return (
                                            <Button
                                                variant={index === 0 ? ("success") : ("outline-success")}
                                                size="lg"
                                                onClick={() => {
                                                    if (button[1] === '') {
                                                        window.location.reload(false);
                                                    } else {
                                                        navigate(button[1])
                                                    }
                                                }}>
                                                {button[0]}
                                            </Button>

                                        );
                                    })
                                }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmScreen