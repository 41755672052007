import React, { useEffect, useState } from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import '../index.css'
import { toCurrency } from '../util/Util';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';


function DesktopHistoryDateRangePicker(props) {
	const [dummyDate, setDummyDate] = useState(props.startDate);

    var firstDay = props.startDate;
    var lastDay = props.endDate;
    

    function showCalendars() {
        console.log("showing the calendars!!!");
        var list = [];
        list = document.getElementsByClassName("daterangepicker ltr show-ranges show-calendar opensright");
        list = document.getElementsByClassName("daterangepicker");
        // .daterangepicker.show-calendar .drp-calendar
        // console.log(list[0].style);
        if (list.length > 0) {
            list[0].style.display = "block";
            list[0].style.top = "265px";
            list[0].style.left = "auto";
            list[0].style.right = "40px";
        }

        var list2 = document.getElementsByClassName("daterangepicker show-calendar drp-calendar");
        if (list2.length > 0) {
            list2[0].style.display = "block";
            // list2[0].style.top = "265px";
            // list2[0].style.left = "auto";
            // list2[0].style.right = "0px";
        }

        var list3 = document.getElementsByClassName("drp-selected");
        if (list3.length > 0) {
            list3[0].style.display = "none";
            // list2[0].style.top = "265px";
            // list2[0].style.left = "auto";
            // list2[0].style.right = "0px";
        }


        var floatCal = document.getElementsByClassName("drp-calendar");
        console.log(floatCal);
        if (floatCal.length > 0) {
            floatCal[0].style.float = "none";
            // list2[0].style.top = "265px";
            // list2[0].style.left = "auto";
            // list2[0].style.right = "0px";
        }


        // .daterangepicker .ranges, .daterangepicker .drp-calendar
        // float: none

    }

    const today = new Date();

    function handleEvent(event, picker) {
        // var picker = $('#HistoryDateRangePicker').data('daterangepicker');
        console.log("date changeiiiiing", picker);
        var newStartDate = new Date(picker.split(" ")[0]);
        var newEndDate = new Date(picker.split(" ")[2]);
        props.setStartDate(newStartDate);
        props.setEndDate(newEndDate);
        console.log("Setting start date to...", newStartDate);
        console.log("Setting end date to...", newEndDate);
        // showCalendars();
    }


    var date = new Date();
    var date2 = new Date();
    date2.setDate(date2.getDate()-30);
    var firstDay2 = new Date(date2.getFullYear(), date2.getMonth(), 1);
    var lastDay2 = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // console.log("PROP DATES",firstDay,lastDay);
    return (
        <DateRangePicker
            id="HistoryDateRangePicker"
            className="DesktopHistoryDateRangePickerContainer"
            onApply={(e) => { handleEvent(e, e.target.value) }}
            // onCancel={(e) => {showCalendars()}}
            // onHide={(e) => {showCalendars()}}
            onShow={(e) => {showCalendars()}}
            // onHideCalendar={(e) => {showCalendars()}}
            initialSettings={{
                startDate: firstDay2,
                endDate: lastDay2,
                alwaysShowCalendars: true,
                opensright: true,
                ranges: {
                    Today: [moment().toDate(), moment().toDate()],
                    Yesterday: [
                        moment().subtract(1, 'days').toDate(),
                        moment().subtract(1, 'days').toDate(),
                    ],
                    'Last 7 Days': [
                        moment().subtract(6, 'days').toDate(),
                        moment().toDate(),
                    ],
                    'Last 30 Days': [
                        moment().subtract(29, 'days').toDate(),
                        moment().toDate(),
                    ],
                    'This Month': [
                        moment().startOf('month').toDate(),
                        moment().endOf('month').toDate(),
                    ],
                    'Last Month': [
                        moment().subtract(1, 'month').startOf('month').toDate(),
                        moment().subtract(1, 'month').endOf('month').toDate(),
                    ],
                },
            }}
        //   onCallback={handleCallback}
        >
            <input type="text" className="form-control DesktopHistoryDateRangePicker" />

        </DateRangePicker>
    );
}


export default DesktopHistoryDateRangePicker;