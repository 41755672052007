import React, { useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
// import parse, { attributesToProps } from 'html-react-parser';
import 'bootstrap/dist/css/bootstrap.min.css';
import monthlyIncomeImg from './img/monthlyincome.PNG';
import envelopesImg from './img/rent.png';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter, Route, useHistory, withRouter, Redirect } from "react-router-dom";
// import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
// library.add(faCoffee);


const dbURL = 'https://vermillionapp.fly.dev/';
// const dbURL = 'https://shielded-bastion-58122.herokuapp.com/';


function SignupDisclaimer() {
    return <div className="disclaimer">By clicking "Get Started" below you agree to Vermillion’s <a href="https://vermillion.app/terms-of-service/">Terms and Conditions</a> and <a href="https://vermillion.app/privacy-policy/">Privacy Policy</a>. You may also receive email offers from us on Vermillion products and services. You may unsubscribe at any time.</div>;
}

function OnboardingOld(props) {
    // const { width, height } = useWindowSize();
    const [questions, setAnswers] = useState([
        {
            pageName: "Intro",
            titleText: "Vermillion will help you re-align your spending to your values through awareness and habit changes.",
            preButtonText: "To get started, tell us more about yourself.",
            buttonText: "Let's go!",
            isQuestion: false,
            answerSelected: -1,
            answerOptions: [
                { answerText: "Let's go!", isSelected: false },
            ],
        },
        {
            pageName: "Experience",
            questionText: 'What experience do you have with budgeting?',
            isQuestion: true,
            answerSelected: -1,
            answerOptions: [
                { answerText: 'None, this is my first time.', isSelected: false },
                { answerText: 'A little, I’ve tried budgeting before.', isSelected: false },
                { answerText: 'A lot, I’ve budgeted for a while now.', isSelected: false },
            ],
        },
        {
            pageName: "Ability",
            questionText: 'How would you describe your ability to save?',
            isQuestion: true,
            answerSelected: -1,
            answerOptions: [
                { answerText: "Easy! There's always enough money to stash away.", isSelected: false },
                { answerText: 'Moderate. I put in the effort and manage to put some away.', isSelected: false },
                { answerText: "Difficult. No matter how hard I try, it's never enough.", isSelected: false },
            ],
        },
        {
            pageName: "Income",
            questionText: 'What type of income do you earn?',
            isQuestion: true,
            answerSelected: -1,
            answerOptions: [
                { answerText: 'Salary', isSelected: false },
                { answerText: 'Hourly', isSelected: false },
                { answerText: 'Contract/Freelance', isSelected: false },
                { answerText: 'Gig Economy/Per Task', isSelected: false },
                { answerText: 'Other/Mix', isSelected: false },
            ],
        },
        {
            pageName: "Info-income",
            questionText: 'Most budgets are built around a static income which assumes you earn the same money every month.',
            moreText: "But the majority of people are either hourly, contractors, or gig economy workers who earn a variable income.",
            isQuestion: false,
            preButtonText: "Vermillion will help you plan for those ups and downs.",
            buttonText: "Got it!",
            img: monthlyIncomeImg,
            answerSelected: -1,
            answerOptions: [
                { answerText: "Let's go!", isSelected: false },
            ],
        },
        {
            pageName: "Spending",
            questionText: 'How would you describe your monthly spending?',
            isQuestion: true,
            answerSelected: -1,
            answerOptions: [
                { answerText: "It's the same every month.", isSelected: false },
                { answerText: "It's a little different every month.", isSelected: false },
                { answerText: "It varies a lot month-to-month.", isSelected: false },
            ],
        },
        {
            pageName: "Info-spending",
            questionText: 'Most budgets are build with static budget categories that stay the same month-to-month.',
            moreText: "But spending rarely works that way in real life. ",
            isQuestion: false,
            preButtonText: "Vermillion uses digital envelopes to ensure that every need is met.",
            buttonText: "Got it!",
            img: envelopesImg,
            answerSelected: -1,
            answerOptions: [
                { answerText: "Let's go!", isSelected: false },
            ],
        },
        {
            pageName: "Concern",
            questionText: 'What is your biggest financial concern?',
            isQuestion: true,
            answerSelected: -1,
            answerOptions: [
                { answerText: 'Life: bills, food, shelter. ', isSelected: false },
                { answerText: "Saving for something large (house, wedding, retirement, etc.)", isSelected: false },
                { answerText: 'Building better habits.', isSelected: false },
                { answerText: 'Other', isSelected: false },
            ],
        },
        {
            pageName: "Goal",
            questionText: 'About how much are you currently saving every month?',
            isQuestion: true,
            buttonText: "Let's make it happen!",
            answerSelected: -1,
            disclaimerText: `Ambitious journeys start small -- achievable goals create momentum that can keep you going.`,
            answerOptions: [
                { answerText: '1', isSelected: false },
            ],
        },
        {
            pageName: "Info-freetrial",
            questionText: "Vermillion normally costs $5.95/month, but you've earned 30 days free!",
            moreText: "",
            isQuestion: false,
            preButtonText: "No credit card required.",
            buttonText: "Sounds good!",
            img: envelopesImg,
            answerSelected: -1,
            answerOptions: [
                { answerText: "Let's go!", isSelected: false },
            ],
        },
        {
            pageName: "Email",
            titleText: "Let us know which email to use.",
            preButtonText: "",
            disclaimerText: `By clicking "Get Started" below you agree to Vermillion’s Terms and Conditions and Privacy Policy. You may also receive email offers from us on Vermillion products and services. You may unsubscribe at any time.`,
            buttonText: "Let's go!",
            collectEmail: true,
            isQuestion: false,
            answerSelected: -1,
            answerOptions: [
                { answerText: "Let's go!", isSelected: false },
            ],
        },
    ]);

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [score, setScore] = useState(0);
    const [userEmail, setEmail] = useState('');
    const [userGoal, setGoal] = useState(null);
    const [emailWarning, setEmailWarning] = useState(false);
    const progressVal = Math.round(100 * currentQuestion / questions.length) + 5;
    const [selections, setSelections] = useState([-1, -1, -1, -1]);

    const call_new_api = () => {
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };
        // Where we're fetching data from
        // return fetch(dbURL + "postplain",requestOptions)
        // // We get the API response and receive data in JSON format
        //   .then((response) => response.json())
        //   .then((data) => console.log(data))
        //   .catch ((error) => console.error(error));
    }

    const updateAnswers = (currentQuestion, answerIndex) => {

        const newAnswers = questions.map(question => {

            if (questions.indexOf(question) !== currentQuestion || !question.isQuestion) {
                return question;
            }
            else {
                const updatedQuestion = question.answerOptions.map(answerOption => {
                    if (question.answerOptions.indexOf(answerOption) !== answerIndex) {
                        const updatedAnswerOption = answerOption;
                        updatedAnswerOption.isSelected = false;
                        return updatedAnswerOption;
                    }
                    else {
                        const updatedAnswerOption = answerOption;
                        updatedAnswerOption.isSelected = true;
                        return updatedAnswerOption;
                    }
                })
                return question;
            }
        });
        setAnswers(newAnswers);
        handleAnswerButtonClick(currentQuestion, answerIndex);

    }

    const handleAnswerButtonClick = (currentQuestion, answerIndex) => {
        const nextQuestion = currentQuestion + 1;
        console.log("going from question " + currentQuestion + " to question " + nextQuestion);
        if (nextQuestion < questions.length) {
            setCurrentQuestion(nextQuestion);
        } else {
            setShowScore(true);
        }
    };

    const validateEmail = (currentQuestion, answerIndex) => {
        const emailRegExp = new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$', 'g');
        // var randomstring = Math.random().toString(36).slice(-8);
        if (emailRegExp.test(userEmail)) {
            console.log("make the user");
            // firebaseAuth.createUserWithEmailAndPassword(userEmail, 'Password').then(u => {
            // 	handleAnswerButtonClick(currentQuestion,answerIndex);
            // }).catch(error => {
            // 	switch (error.code) {
            // 		case 'auth/email-already-in-use':
            // 		console.log(`Email address already in use.`);
            // 		break;
            // 		case 'auth/invalid-email':
            // 		console.log(`Email address is invalid.`);
            // 		break;
            // 		case 'auth/operation-not-allowed':
            // 		console.log(`Error during sign up.`);
            // 		break;
            // 		// case 'auth/weak-password':
            // 		// console.log('Password is not strong enough. Add additional characters including special characters and numbers.');
            // 		// break;
            // 		default:
            // 		console.log(error.message);
            // 		setEmailWarning(true);
            // 		break;
            // 	}
            // });
            //handleAnswerButtonClick(currentQuestion,answerIndex);
        } else {
            // display bad email
            setEmailWarning(true);
        }
    };

    const validateGoal = (currentQuestion, answerIndex) => {
        // var randomstring = Math.random().toString(36).slice(-8);
        if (userGoal != null) {
            handleAnswerButtonClick(currentQuestion, answerIndex);
        } else {
            // display bad email
            console.log('Bad goal');
        }
    };

    const doNothing = () => {
        console.log("");
    };

    const backButtonClick = () => {
        const prevQuestion = currentQuestion - 1;
        setCurrentQuestion(prevQuestion);
    };


    return (
        <div className='d-flex justify-content-center'>
            <Confetti
                width={300}
                height={1000}
                numberOfPieces={1500}
                recycle={false}
            />
            <div className='app d-flex flex-column '>
                <ProgressBar now={progressVal} />

                {showScore ? (
                    <div className='score-section'>You scored {score} out of {questions.length}</div>
                ) : (

                    <>
                        <div id="backDiv" className='backDiv'>
                            {currentQuestion == 0 ? <div></div> :
                                (<><button onClick={() => backButtonClick()} className="onBButton back-btn">Back</button>
                                </>)}
                        </div>
                        {questions[currentQuestion].isQuestion ? (
                            <>
                                {/* question page */}

                                <div key="question-section" className='question-section'>
                                    <div key="question-count" className='question-count'>

                                        <span>{questions[currentQuestion].questionText}</span></div>
                                </div>

                                {questions[currentQuestion].pageName == 'Goal' ? (
                                    <>

                                        <div className="input-group mb-3 goalInput">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input type="number" placeholder="50" className="form-control" id="exampleInputGoal1"
                                                value={userGoal} onInput={e => setGoal(e.target.value)} />
                                        </div>

                                        <div className="disclaimer">
                                            {questions[currentQuestion].disclaimerText}
                                        </div>
                                        <div className="d-grid gap-2 mt-auto">
                                            <div className='question-text'>{questions[currentQuestion].preButtonText}</div>
                                            <button className='onBButton btn-active' onClick={() => validateGoal(currentQuestion, -1)}>
                                                {questions[currentQuestion].buttonText}</button>
                                        </div>
                                    </>

                                ) : (
                                    <div key="answer-section" className='answer-section'>
                                        <ul key="answerList" className='answerList'>

                                            {questions[currentQuestion].answerOptions.map((answerOption, index) => {
                                                return <li key={"li-" + currentQuestion + "" + index}>
                                                    <input type="radio" id={"" + currentQuestion + "" + index}
                                                        checked={answerOption.isSelected}
                                                        onClick={() => updateAnswers(currentQuestion, index)}
                                                        onChange={() => doNothing()}
                                                        //onClick={()=> handleAnswerButtonClick(currentQuestion,index)}
                                                        name={"" + currentQuestion + "" + index} />
                                                    <label htmlFor={"" + currentQuestion + "" + index}
                                                        key={"lab-" + currentQuestion + "" + index}
                                                    >
                                                        {answerOption.answerText}
                                                    </label>
                                                </li>;
                                            })}
                                        </ul>
                                    </div>
                                )}

                            </>

                        ) : (
                            <>
                                {/* informational page */}
                                <div className='question-section'>
                                    <div className='display-6'>
                                        <span>{questions[currentQuestion].titleText}</span>
                                    </div>

                                    {questions[currentQuestion].pageName == 'Email' ? (
                                        <>
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                <input type="email" placeholder="youremail@example.com" className="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp" deaultvalue={userEmail}
                                                    onInput={e => setEmail(e.target.value)} />
                                                {emailWarning ? (
                                                    <div id="emailHelp" className="form-text text-danger" ><FontAwesomeIcon icon={faExclamationCircle} color="red" /> Please provide a valid email.</div>
                                                ) : (
                                                    <div></div>
                                                )}

                                            </div>
                                            <SignupDisclaimer />
                                        </>

                                    ) : (<div></div>)}


                                    <div className='question-text'>{questions[currentQuestion].questionText}</div>
                                    <div className='question-text'>{questions[currentQuestion].moreText}</div>
                                </div>
                                <div className="col-md-12">
                                    <img className="thumbnail img-fluid" src={questions[currentQuestion].img} />
                                </div>
                                {/* Need to make this conditional for the email */}
                                {questions[currentQuestion].pageName == 'Email' ? (
                                    <div className="d-grid gap-2 mt-auto">
                                        <div className='question-text'>{questions[currentQuestion].preButtonText}</div>
                                        <button className='onBButton btn-active' onClick={() => validateEmail(currentQuestion, -1)}>
                                            {questions[currentQuestion].buttonText}</button>
                                    </div>
                                ) : (
                                    <div className="d-grid gap-2 mt-auto">
                                        <div className='question-text'>{questions[currentQuestion].preButtonText}</div>
                                        <button className='onBButton btn-active' onClick={() => handleAnswerButtonClick(currentQuestion, -1)}>
                                            {questions[currentQuestion].buttonText}</button>
                                    </div>
                                )}


                            </>
                        )}



                    </>
                )}
            </div>
        </div>
    );
}

export default OnboardingOld;