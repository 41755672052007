import React, { Component, useState } from 'react';
import { ToggleButton, Button, ToggleButtonGroup, Modal } from 'react-bootstrap';
import Toggle from 'react-toggle';

import 'bootstrap/dist/css/bootstrap.min.css';
import { v4 as uuidv4 } from 'uuid';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { withRouter, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { firebaseAuth } from './firebase.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './index.css';
import Lottie from 'lottie-react';
import greenCheck from './lotties/greencheck.json';
import "react-toggle/style.css"; // for ES6 modules

import Navigation from './Navigation';
import FreeTrialAlert from './components/FreeTrialAlert';
import ConfirmScreen from './components/ConfirmScreen.js';

const dbURL = 'https://vermillionapp.fly.dev/';
// const dbURL = 'https://shielded-bastion-58122.herokuapp.com/';

export default function EditTransaction(props) {
    //   const history = useHistory();
    let navigate = useNavigate();
    const location = useLocation();

    const [userID, setUserID] = useState('');
    const [email, setEmail] = useState('');
    const [newAmount, setNewAmount] = useState(location.state.newAmount);
    const [newAmtToggle, setNewAmtToggle] = useState(false);
    // 0 = income, 1 = expense, 2 = transfer
    const [newType, setNewType] = useState(1);
    const [newXfr, setNewXfr] = useState(0);
    const [newPayee, setNewPayee] = useState(location.state.newPayee);
    const [newCat, setNewCat] = useState(location.state.newCatId);
    const [newAcc, setNewAcc] = useState(0);
    const [newXfrAcc, setNewXfrAcc] = useState(0);
    const [confirmed, setConfirmed] = useState(false);
    const [accOptions, setAccOptions] = useState([]);
    const [token, setToken] = useState('');
    const [transId, setTransId] = useState(0);
    const [xfrId, setXfrId] = useState(0);
    const [splitId, setSplitId] = useState(null);
    const [splitList, setSplitList] = useState([]);
    const [pretax, setPretax] = useState(false);
    const [pretaxList, setPretaxList] = useState([]);
    const [removalList, setRemovalList] = useState([]);
    let [days_left, setDaysLeft] = useState(600);


    console.log("===NORM===", newAcc, newCat, transId, newType);
    // console.log("===XFR===", newAcc, newXfrAcc, transId, xfrId);
    // console.log("===SPLIT===", newAcc, splitId, splitList);
    // console.log("===PRETAX===", newAcc, pretax, pretaxList);
    // console.log("=======Removal List", removalList);

    // var curr = new Date();
    var curr = new Date(location.state.newDate);
    // curr.setDate(curr.getDate() - 1);
    var date = curr.toISOString().substr(0, 10);
    const [newDate, setNewDate] = useState(date);

    const toCurrency = (x) => {
        // *** Convert to format $0.00
        return (x / 100).toLocaleString('US', {
            style: "currency",
            currency: "USD"
        });
    }

    const toCurrencyNumber = (x) => {
        // *** Convert to format 0.00
        const newX = Number(x.replace(/[^0-9.-]/g, '')
            .replace(/(\..*)\./g, '$1')
            .replace(/(?!^)-/g, '')
            .replace(/^0+(\d)/gm, '$1'));
        // console.log('Turning into Number...' + newX);
        return (newX);
    }


    var styleElem = document.head.appendChild(document.createElement("style"));

    styleElem.innerHTML = "#ticketDiv:before {top: 175px;}";
    styleElem.innerHTML = "#ticketDiv:after {top: 175px;}";

    const toNumber = (x) => {
        const newX = 100 * Number(x.replace(/[^0-9.-]/g, '')
            .replace(/(\..*)\./g, '$1')
            .replace(/(?!^)-/g, '')
            .replace(/^0+(\d)/gm, '$1'));
        // console.log('Turning into Number...' + newX);
        return (newX);

    }


    const toggleType = (x) => {
        // When the type is toggled, the category must also be toggled
        setTimeout(() => {
            console.log("Toggling the type...", newType, x, newCat);
            if (x != newType) {
                console.log("Toggling the type...", newType, x);
                setNewType(x);
            } else {
                console.log("Doing nothing", newType, x);
            }
        }, 250);
        return (1);
    }
    const toggleXfr = (x) => {
        setTimeout(() => {
            // console.log("Toggling the other value...");
            setNewXfr(!newXfr);
        }, 250);
        return (1);
    }

    function updateAmt(rawVal) {
        const newVal = toNumber(rawVal);
        // this.setState({newAmount: newVal});
        // could introduce a very fake toggle to force state change

        // console.log(newVal);
        // setNewAmount(0);
        setNewAmount(newVal);
        setNewAmtToggle(!newAmtToggle);
        // setState({ newAmount: newVal })
    }

    const attemptSave = (x) => {
        // IF INCOME
        // check for source
        // check for account
        // check for date -- default to today, but verify?

        // IF EXPENSE
        // check for payee
        // check for category
        // check for account
        // check for date -- default to today

        // IF XFR
        // check for From
        // check for To
        // check for date
        console.log("Attempting to save now");
        if (newType == 2) {
            api_update_transfer_from(() => {
                // api_update_transfer_to()
                console.log("Finished API POST #1");
            });
            api_update_transfer_to(() => {
                console.log("Finished API POST #2");
            });
            setTimeout(() => {
                console.log("Saving transfer...");
                setConfirmed(!confirmed);
            }, 1550);

        } else {
            if (splitId != null) {
                console.log("Seeing a splitId...");
                if (newCat < 0) {
                    api_update_pretax_transaction();
                    setTimeout(() => {
                        console.log("Saving pretax transaction...");
                        setConfirmed(!confirmed);
                    }, 1550);
                } else {
                    api_update_split_transaction();
                    setTimeout(() => {
                        console.log("Saving split transaction...");
                        setConfirmed(!confirmed);
                    }, 1550);
                }

            } else {
                console.log(splitId);
                console.log(location.state.newSplitId);
                api_update_transaction();
                setTimeout(() => {
                    console.log("Saving transaction...");
                    setConfirmed(!confirmed);
                }, 550);
            }
        }
        return (1);
    }


    const attemptDelete = (x) => {
        console.log("Attempting to delete now");
        if (newType == 2) {

            api_delete_transaction(transId);
            api_delete_transaction(xfrId);
            setTimeout(() => {
                console.log("Deleting Transfer...");
                setConfirmed(!confirmed);
            }, 1550);

        } else {
            if (splitId != null) {
                if (newCat < 0) {
                    api_delete_pretax_transaction();
                    setTimeout(() => {
                        console.log("Deleting split transaction...");
                        setConfirmed(!confirmed);
                    }, 1550);
                } else {
                    api_delete_split_transaction();
                    setTimeout(() => {
                        console.log("Deleting split transaction...");
                        setConfirmed(!confirmed);
                    }, 1550);
                }
            } else {
                api_delete_transaction(transId);
                setTimeout(() => {
                    console.log("Deleting transaction...");
                    setConfirmed(!confirmed);
                }, 550);
            }
        }
        return (1);
    }

    var amtClass = '';
    var payeeLabel = '';
    var payeeHint = '';
    var categoryLabel = '';
    var catOptions = [{}];
    var saveButtonClass = '';
    var confirmColor = '';
    if (newType == 2) {
        amtClass = "TransactionAmount TransferAmount";
        saveButtonClass = 'col saveButtonYellow saveOrCancelButton';
    } else if (newType == 0) {
        amtClass = "TransactionAmount IncomeAmount";
        payeeLabel = 'Source';
        payeeHint = 'Who paid you?';
        categoryLabel = 'Available';
        // catOptions = ['Next Month', 'This Month'];
        catOptions = [{ type: 'category', categoryid: -2, name: 'Next Month' }, { type: 'category', categoryid: -1, name: 'This Month' }];
        saveButtonClass = 'col saveButtonGreen saveOrCancelButton';
        confirmColor = 'greenCheck';
    } else {
        amtClass = "TransactionAmount ExpenseAmount";
        payeeLabel = 'Payee';
        payeeHint = 'Who did you pay?';
        categoryLabel = 'Category';
        saveButtonClass = 'col saveButtonRed saveOrCancelButton';
        confirmColor = 'redCheck';
    }


    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: greenCheck,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    // if number doesn't actually change it won't update!

    const api_get_params = (authToken) => {
        const data = { token: authToken, color2: 'red' };
        const requestOptions = {
            method: 'GET'
        };
        // Where we're fetching data from
        const baseURL = dbURL + 'newTransaction?';
        const reqURL = baseURL + 'token=' + data.token;
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response.json())
            .then((data) => {
                const newData = data.results.rows
                console.log(newData);
                setAccOptions(newData);
                setDaysLeft(newData[0]['days_left']);
            })
            .catch((error) => console.error(error));
    }

    const api_update_transaction = () => {
        console.log("API Updating transaction...");
        const data = {
            token: token, accountid: newAcc, categoryid: newCat,
            merchant: encodeURIComponent(newPayee), notes: '',
            amount: Math.round(newAmount), occurred_at: newDate, transactionid: transId
        };
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };

        console.log('Adding before api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'updatetransaction?';
        const reqURL = baseURL
            + 'token=' + data.token
            + '&transid=' + data.transactionid
            + '&accountid=' + data.accountid
            + '&categoryid=' + data.categoryid
            + '&merchant=' + encodeURIComponent(data.merchant)
            + '&notes=' + data.notes
            + '&amount=' + data.amount
            + '&occurred=' + data.occurred_at;
        console.log(reqURL);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response)
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }

    const api_update_transfer_from = () => {
        // Update "from" transaction
        console.log("Updating transfer...POST #1");
        const data = {
            token: token, accountid: newXfrAcc, categoryid: '-3',
            merchant: '', notes: '',
            amount: newAmount, occurred_at: newDate, transactionid: xfrId
        };
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };

        console.log('Adding before api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'updatetransaction?';
        const reqURL = baseURL
            + 'token=' + data.token
            + '&transid=' + data.transactionid
            + '&accountid=' + data.accountid
            + '&categoryid=' + data.categoryid
            + '&merchant=' + encodeURIComponent(data.merchant)
            + '&notes=' + data.notes
            + '&amount=' + data.amount
            + '&occurred=' + data.occurred_at;
        console.log(reqURL);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response)
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }

    const api_update_transfer_to = () => {
        // Update "from" transaction
        // this is the only half working?
        console.log("Updating transfer...POST #2");
        const data = {
            token: token, accountid: newAcc, categoryid: '-4',
            merchant: '', notes: '',
            amount: newAmount, occurred_at: newDate, transactionid: transId
        };
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };

        console.log('Adding before api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'updatetransaction?';
        const reqURL = baseURL
            + 'token=' + data.token
            + '&transid=' + data.transactionid
            + '&accountid=' + data.accountid
            + '&categoryid=' + data.categoryid
            + '&merchant=' + encodeURIComponent(data.merchant)
            + '&notes=' + data.notes
            + '&amount=' + data.amount
            + '&occurred=' + data.occurred_at;
        console.log(reqURL);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response)
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }

    const api_update_split_transaction = () => {
        let promiseList = [];
        splitList.forEach((splitItem) => {
            // call api
            const data = {
                token: token,
                transId: splitItem[2],
                accountid: newAcc,
                categoryid: splitItem[0],
                merchant: encodeURIComponent(newPayee),
                notes: '',
                amount: Math.round(toCurrencyNumber((splitItem[1]).toString())),
                occurred_at: newDate,
                effective_at: newDate,
                splitid: splitId
            };
            const requestOptions = {
                method: 'POST',
                // headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({})
            };

            console.log(splitItem[2]);
            let baseURL = '';
            if (splitItem[2] == undefined) {
                // when transid doesn't exist --> create it!
                baseURL = dbURL + 'addtransaction?';

            } else {
                // when transid exists --> update it!
                baseURL = dbURL + 'updatetransaction?';

            }

            console.log('Adding before api post...');
            // Where we're fetching data from
            const reqURL = baseURL
                + 'token=' + data.token
                + '&transid=' + data.transId
                + '&accountid=' + data.accountid
                + '&categoryid=' + data.categoryid
                + '&merchant=' + encodeURIComponent(data.merchant)
                + '&notes=' + data.notes
                + '&amount=' + data.amount
                + '&occurred=' + data.occurred_at
                + '&effective=' + data.effective_at
                + '&splitid=' + data.splitid;
            console.log(reqURL);
            const newPromise = fetch(reqURL, requestOptions);
            promiseList.push(newPromise);
        })
        // Need to delete any list items that have been removed
        removalList.forEach((removeItem) => {
            console.log(removalList);
            console.log(removeItem);
            console.log("Item to remove", removeItem[0]);
            console.log("Item to remove", removeItem[1]);
            console.log("Item to remove", removeItem[2]);
            // call api
            const data = {
                token: token, transactionid: removeItem[2]
            };
            const requestOptions = {
                method: 'DELETE'
                // headers: { 'Content-Type': 'application/json' },
                // body: JSON.stringify({})
            };

            console.log(removeItem[2]);
            let baseURL = dbURL + 'deletetransaction/';

            console.log('Adding before api post...');
            // Where we're fetching data from
            const reqURL = baseURL
                + '' + data.transactionid
                + '?token=' + data.token;
            console.log(reqURL);
            const newPromise = fetch(reqURL, requestOptions);
            promiseList.push(newPromise);
        })
        // return (Promise.all(promiseList));
        let myPromise = Promise.all(promiseList);

        return myPromise
            .then((response) => response)
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }

    const api_update_pretax_transaction = () => {
        let promiseList = [];
        // First update the base transaction
        console.log("Updating pretax transaction...");
        const data = {
            token: token,
            accountid: newAcc,
            categoryid: newCat,
            merchant: encodeURIComponent(newPayee),
            notes: '',
            amount: Math.round(newAmount),
            occurred_at: newDate,
            transactionid: transId,
            splitid: splitId
        };
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };

        console.log('Updating main transaction...');
        // Where we're fetching data from
        const baseURL = dbURL + 'updatetransaction?';
        const reqURL = baseURL
            + 'token=' + data.token
            + '&transid=' + data.transactionid
            + '&accountid=' + data.accountid
            + '&categoryid=' + data.categoryid
            + '&merchant=' + encodeURIComponent(data.merchant)
            + '&notes=' + data.notes
            + '&amount=' + data.amount
            + '&occurred=' + data.occurred_at
            + '&splitid=' + data.splitid;
        console.log(reqURL);

        const newPromise1 = fetch(reqURL, requestOptions);
        promiseList.push(newPromise1);

        // Then update the pretax list items
        let pretaxCatId = -6;
        if (newCat == -2) {
            pretaxCatId = -7
        }

        console.log('Updating pretax items...');
        pretaxList.forEach((pretaxItem) => {
            // call api
            const data = {
                token: token,
                transId: pretaxItem[2],
                accountid: newAcc,
                categoryid: pretaxCatId,
                merchant: encodeURIComponent(newPayee),
                notes: encodeURIComponent(pretaxItem[0]),
                amount: Math.round(toCurrencyNumber((pretaxItem[1]).toString())),
                occurred_at: newDate,
                effective_at: newDate,
                splitid: splitId
            };
            const requestOptions = {
                method: 'POST',
                // headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({})
            };

            console.log(pretaxItem[2]);
            let baseURL = '';
            if (pretaxItem[2] == undefined) {
                // when transid doesn't exist --> create it!
                baseURL = dbURL + 'addtransaction?';

            } else {
                // when transid exists --> update it!
                baseURL = dbURL + 'updatetransaction?';

            }

            console.log('Adding before api post...');
            // Where we're fetching data from
            const reqURL = baseURL
                + 'token=' + data.token
                + '&transid=' + data.transId
                + '&accountid=' + data.accountid
                + '&categoryid=' + data.categoryid
                + '&merchant=' + encodeURIComponent(data.merchant)
                + '&notes=' + encodeURIComponent(data.notes)
                + '&amount=' + data.amount
                + '&occurred=' + data.occurred_at
                + '&effective=' + data.effective_at
                + '&splitid=' + data.splitid;
            console.log(reqURL);
            const newPromise = fetch(reqURL, requestOptions);
            promiseList.push(newPromise);
        })

        // Finally, delete any list items that have been removed
        console.log('Removing pretax item...');
        removalList.forEach((removeItem) => {
            console.log(removalList);
            console.log(removeItem);
            console.log("Item to remove", removeItem[0]);
            console.log("Item to remove", removeItem[1]);
            console.log("Item to remove", removeItem[2]);
            // call api
            const data = {
                token: token, transactionid: removeItem[2]
            };
            const requestOptions = {
                method: 'DELETE'
                // headers: { 'Content-Type': 'application/json' },
                // body: JSON.stringify({})
            };

            console.log(removeItem[2]);
            let baseURL = dbURL + 'deletetransaction/';

            console.log('Adding before api post...');
            // Where we're fetching data from
            const reqURL = baseURL
                + '' + data.transactionid
                + '?token=' + data.token;
            console.log(reqURL);
            const newPromise = fetch(reqURL, requestOptions);
            promiseList.push(newPromise);
        })
        // return (Promise.all(promiseList));
        let myPromise = Promise.all(promiseList);

        return myPromise
            .then((response) => response)
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }

    const api_delete_transaction = (x) => {
        console.log("About to make the API reuqest...POST");
        const data = {
            token: token, transactionid: x
        };
        const requestOptions = {
            method: 'DELETE'
            // headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({})
        };

        console.log('Adding before api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'deletetransaction/';
        const reqURL = baseURL
            + '' + data.transactionid
            + '?token=' + data.token;
        // + '&transactionid=' + data.transactionid;
        console.log(reqURL);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response)
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }

    const api_delete_split_transaction = () => {
        let promiseList = [];
        splitList.forEach((splitItem) => {
            // call api
            const data = {
                token: token, transactionid: splitItem[2]
            };
            const requestOptions = {
                method: 'DELETE'
                // headers: { 'Content-Type': 'application/json' },
                // body: JSON.stringify({})
            };


            let transId = '';
            console.log(splitItem[2]);
            if (splitItem[2] == undefined) {
                // when transid doesn't exist --> create it!
                // baseURL = dbURL + 'addtransaction?';
                console.log("Nothing to delete");

            } else {
                // when transid exists --> update it!
                const baseURL = dbURL + 'deletetransaction/';
                const reqURL = baseURL
                    + '' + data.transactionid
                    + '?token=' + data.token;
                console.log(reqURL);
                const newPromise = fetch(reqURL, requestOptions);
                promiseList.push(newPromise);
            }
            // return (1);
            // We get the API response and receive data in JSON format
            // .then((response) => response.json())
            // .then((data) => console.log("Adding after api post.." + data))
            // .catch((error) => console.error(error));
        })
        let myPromise = Promise.all(promiseList);

        return myPromise
            .then((response) => response)
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }

    const api_delete_pretax_transaction = () => {
        let promiseList = [];

        // First delete the main
        const data = {
            token: token, transactionid: transId
        };
        const requestOptions = {
            method: 'DELETE'
            // headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({})
        };

        console.log('Adding before api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'deletetransaction/';
        const reqURL = baseURL
            + '' + data.transactionid
            + '?token=' + data.token;
        // + '&transactionid=' + data.transactionid;
        console.log(reqURL);
        const newPromise1 = fetch(reqURL, requestOptions);
        promiseList.push(newPromise1);


        // Then delete each item
        pretaxList.forEach((pretaxItem) => {
            // call api
            const data = {
                token: token, transactionid: pretaxItem[2]
            };
            const requestOptions = {
                method: 'DELETE'
                // headers: { 'Content-Type': 'application/json' },
                // body: JSON.stringify({})
            };


            let transId = '';
            console.log(pretaxItem[2]);
            if (pretaxItem[2] == undefined) {
                // when transid doesn't exist --> create it!
                // baseURL = dbURL + 'addtransaction?';
                console.log("Nothing to delete");

            } else {
                // when transid exists --> update it!
                const baseURL = dbURL + 'deletetransaction/';
                const reqURL = baseURL
                    + '' + data.transactionid
                    + '?token=' + data.token;
                console.log(reqURL);
                const newPromise = fetch(reqURL, requestOptions);
                promiseList.push(newPromise);
            }
        })
        let myPromise = Promise.all(promiseList);

        return myPromise
            .then((response) => response)
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }

    useEffect(() => {

        if (location.state.newCatId < 0) {
            // If cat is < 0, then splitList is pretax items
            // console.log("doing pretax income");
            // console.log(JSON.stringify(location.state.newSplitList));
            // console.log(JSON.stringify(location.state.newSplitList).replaceAll(`"`, ``).replaceAll(`'`, `"`));
            setPretax(location.state.newSplitId);
            setSplitId(location.state.newSplitId);
            setPretaxList(JSON.parse(JSON.stringify(location.state.newSplitList).replaceAll(`"`, ``).replaceAll(`'`, `"`)));
        } else {
            // If cat is > 0, then splitList is split expense items
            // console.log("doing a split expense");
            // console.log(JSON.stringify(location.state.newSplitList));
            // console.log(JSON.stringify(location.state.newSplitList).replaceAll(`"`, ``));
            setSplitId(location.state.newSplitId);
            setSplitList(JSON.parse(JSON.stringify(location.state.newSplitList).replaceAll(`"`, ``)));
        }

        if (newCat == -1 || newCat == -2) {
            setNewType(0);
            setTransId(location.state.transId);
            setNewAcc(location.state.newAcc);
        } else if (newCat == -3 || newCat == -4) {
            setNewType(2);
            if (newCat == -3) {
                // this is the "to" transaction
                // the primary account will go to newXfrAcc
                // the primary transaction id will be the "to" aka catid = -4
                setNewAcc(location.state.newXfrAcc);
                setNewXfrAcc(location.state.newAcc);
                setTransId(location.state.transId);
                setXfrId(location.state.newXfrIdAcc);
            } else {
                // this is either the "from" transaction (cat = -3)
                // or a normal income/expense
                setNewAcc(location.state.newAcc);
                setNewXfrAcc(location.state.newXfrAcc);
                setTransId(location.state.transId);
                setXfrId(location.state.newXfrIdAcc);
            }
        } else {
            // this is either the "from" transaction (cat = -3)
            // or a normal income/expense
            setNewAcc(location.state.newAcc);
            setNewXfrAcc(location.state.newXfrAcc);
            setTransId(location.state.transId);
            setXfrId(location.state.newXfrIdAcc);
        }
        firebaseAuth.onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                setUserID(firebaseAuth.currentUser.uid);
                setEmail(encodeURIComponent(firebaseAuth.currentUser.email));
                // const idToken2 = await 
                user.getIdToken()
                    .then(idToken => {  // <------ Check this line
                        api_get_params(idToken);
                        setToken(idToken);
                    });
                console.log('logged in!' + firebaseAuth.currentUser.uid);
                // console.log(api_getIdToken(user));
            } else {
                // No user is signed in.
                console.log('no one signed in');
                navigate('/login');
            }
        });
    }, []);


    function DeleteConfirmation(props) {
        const [show, setShow] = useState(false);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        return (
            <>
                {/* Desktop */}
                <div className='d-none d-lg-block row text-center'>
                    <Button variant="outline-danger" className='w-50 mt-5 fs-5 fw-bolder'
                        onClick={handleShow} disabled={props.is_disabled}>
                        Delete
                        {newType == 2 ? (<span> Transfer</span>) : (<span> Transaction</span>)}
                    </Button>
                </div>
                {/* Mobile */}
                {props.is_disabled ? (
                    <div >
                    </div>
                ) : (
                    <div className='text-center mt-5 fs-5 fw-bolder text-black-50 d-block d-lg-none'
                        onClick={handleShow} >
                        Delete
                        {newType == 2 ? (<span> Transfer</span>) : (<span> Transaction</span>)}
                    </div>
                )}

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this
                        {newType == 2 ? (<span> transfer?</span>) : (<span> transaction?</span>)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => attemptDelete()}>
                            Yes, Delete
                        </Button>
                        <Button variant="outline-danger" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    class MobileTransactionType extends Component {
        render() {
            return (
                <> {newType == 2 ? (
                    <ToggleButtonGroup type="radio" name="radio" id="transTypeGroup">
                        <ToggleButton
                            key={0}
                            id={`radio-`}
                            variant={(newType == 0) ? 'success' : 'outline-success disabled'}
                            value={newType == 0}
                            checked={newType == 0}
                        >
                            <strike>Income</strike>
                        </ToggleButton>
                        <ToggleButton
                            key={1}
                            id={`radio-`}
                            variant={(newType == 1) ? 'danger' : 'outline-danger disabled'}
                            value={newType == 1}
                            checked={newType == 1}
                        >
                            <strike>Expense</strike>
                        </ToggleButton>
                        <ToggleButton
                            key={2}
                            id={`radio-`}
                            variant={(newType == 2) ? 'warning' : 'outline-warning'}
                            value={newType == 2}
                            checked={newType == 2}
                            onClick={(e) => toggleType(2)}
                        >
                            Transfer
                        </ToggleButton>
                    </ToggleButtonGroup>
                ) : (
                    <ToggleButtonGroup type="radio" name="radio" id="transTypeGroup">
                        <ToggleButton
                            key={0}
                            id={`radio-`}
                            variant={(newType == 0) ? 'success' : 'outline-success'}
                            value={newType == 0}
                            checked={newType == 0}
                            onClick={(e) => toggleType(0)}
                        >
                            Income
                        </ToggleButton>
                        <ToggleButton
                            key={1}
                            id={`radio-`}
                            variant={(newType == 1) ? 'danger' : 'outline-danger'}
                            value={newType == 1}
                            checked={newType == 1}
                            onClick={(e) => toggleType(1)}
                        >
                            Expense
                        </ToggleButton>
                        <ToggleButton
                            key={2}
                            id={`radio-`}
                            variant={'outline-warning disabled'}
                        >
                            <strike>Transfer</strike>
                        </ToggleButton>
                    </ToggleButtonGroup>
                )}

                </>
            )
        }
    }

    function TransactionType() {
        return (
            <>
                <div className='d-none d-lg-block'>
                    <DesktopTransactionType />
                </div>
                <div className='d-block d-lg-none'>
                    <MobileTransactionType />
                </div>
            </>
        );
    }

    class DesktopTransactionType extends Component {
        render() {
            return (
                <>
                    <div className='ticketHoles'>
                        <hr className='ticketLine' />
                    </div>

                    {newType == 2 ? (
                        <ToggleButtonGroup type="radio"
                            name="radio" id="desktopTransTypeGroup">
                            <ToggleButton
                                key={0}
                                id={`radio-`}
                                variant={(newType == 0) ? 'success' : 'outline-success disabled'}
                                value={newType == 0}
                                checked={newType == 0}
                            >
                                <strike>Income</strike>
                            </ToggleButton>
                            <ToggleButton
                                key={1}
                                id={`radio-`}
                                variant={(newType == 1) ? 'danger' : 'outline-danger disabled'}
                                value={newType == 1}
                                checked={newType == 1}
                            >
                                <strike>Expense</strike>
                            </ToggleButton>
                            <ToggleButton
                                key={2}
                                id={`radio-`}
                                variant={(newType == 2) ? 'warning' : 'outline-warning'}
                                value={newType == 2}
                                checked={newType == 2}
                                onClick={(e) => toggleType(2)}
                            >
                                Transfer
                            </ToggleButton>
                        </ToggleButtonGroup>
                    ) : (
                        <ToggleButtonGroup type="radio"
                            name="radio" id="desktopTransTypeGroup">
                            <ToggleButton
                                key={0}
                                id={`radio-`}
                                variant={(newType == 0) ? 'success' : 'outline-success'}
                                value={newType == 0}
                                checked={newType == 0}
                                onClick={(e) => toggleType(0)}
                            >
                                Income
                            </ToggleButton>
                            <ToggleButton
                                key={1}
                                id={`radio-`}
                                variant={(newType == 1) ? 'danger' : 'outline-danger'}
                                value={newType == 1}
                                checked={newType == 1}
                                onClick={(e) => toggleType(1)}
                            >
                                Expense
                            </ToggleButton>
                            <ToggleButton
                                key={2}
                                id={`radio-`}
                                variant={'outline-warning disabled'}
                            >
                                <strike>Transfer</strike>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )}

                </>
            )
        }
    }

    class TransactionXfr extends Component {
        render() {
            return (
                <>
                    <div className="container transType">
                        <div className="row">
                            <div className="col-6">
                                <div className="transTypeLabel">Account Transfer</div>
                            </div>
                            <div className="col" id="transXFRToggleDiv">
                                {/* maybe because the state is re-rendering, which doesnt leave time for the transition? */}
                                <Toggle
                                    id='transactionXfr'
                                    defaultChecked={newXfr}
                                    icons={false}
                                    onChange={() => toggleXfr(!newXfr)} />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    class TransactionPayee extends Component {
        render() {
            return (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <div className="row mt-5 ms-5">
                            <div className="col-3">
                                <div className="transTypeLabel">{payeeLabel}</div>
                            </div>
                            <div className="col">
                                <input className='desktopTransInput w-75' placeholder={payeeHint}
                                    defaultValue={newPayee}
                                    onBlur={(e) => setNewPayee(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                    <div className="container transPayee d-block d-lg-none">
                        <div className="row">
                            <div className="col">
                                <div className="transTypeLabel">{payeeLabel}</div>
                            </div>
                            <div className="col">
                                <input className='transInput' placeholder={payeeHint}
                                    defaultValue={newPayee}
                                    onBlur={(e) => setNewPayee(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }


    class TransactionCategory extends Component {
        render() {
            return (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <div className="row mt-4 ms-5">
                            <div className="col-3">
                                <div className="transTypeLabel">{categoryLabel}</div>
                            </div>
                            <div className="col-7">
                                <select id="newTransCat" name="cars" className='desktopTransInput w-75'

                                    // If transaction is income and category is expense, or
                                    // if transaction is expense and category is income

                                    value={(newType === 0 && newCat < 0 || newType === 1 && newCat > 0) ? (newCat) : ('')}

                                    onChange={(e) => setNewCat(e.target.value)}>
                                    <option disabled value='' key="op">--select category--</option>
                                    {newType == 0 ? (
                                        catOptions.map((cat, index) => {
                                            return (<option value={cat.categoryid} key={"1" + cat.categoryid}>{cat.name}</option>)
                                        })
                                    ) : (
                                        accOptions.filter((item, index) => item.type == 'category').map((cat, index) => {
                                            return (
                                                <option value={cat.categoryid} key={"2" + cat.categoryid}>{cat.name}</option>
                                            )
                                        })
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                    <div className="container transPayee d-block d-lg-none">
                        <div className="row">
                            <div className="col-4">
                                <div className="transTypeLabel">{categoryLabel}</div>
                            </div>
                            <div className="col">
                                <select id="newTransCat" name="cars" className='transInput'
                                    value={newCat}
                                    onChange={(e) => setNewCat(e.target.value)}>
                                    <option disabled value='' key="op">--select category--</option>
                                    {newType == 0 ? (
                                        catOptions.map((cat, index) => {
                                            return (<option value={cat.categoryid} key={"1" + cat.categoryid}>{cat.name}</option>)
                                        })
                                    ) : (
                                        accOptions.filter((item, index) => item.type == 'category').map((cat, index) => {
                                            return (
                                                <option value={cat.categoryid} key={"2" + cat.categoryid}>{cat.name}</option>
                                            )
                                        })
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    class TransactionAccount extends Component {
        render() {
            return (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <div className="row mt-4 ms-5">
                            <div className="col-3">
                                <div className="transTypeLabel">Account</div>
                            </div>
                            <div className="col">
                                <select id="newTransAcc" name="cars" className='desktopTransInput w-75'
                                    value={newAcc}
                                    onChange={(e) => setNewAcc(e.target.value)}>
                                    <option disabled value=''> --select account-- </option>
                                    {accOptions.filter((item, index) => item.type == 'account').map((acc, index) => {
                                        return (
                                            <option value={acc.categoryid} key={acc.categoryid}>{acc.name}</option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                    <div className="container transPayee d-block d-lg-none">
                        <div className="row">
                            <div className="col-4">
                                <div className="transTypeLabel">Account</div>
                            </div>
                            <div className="col">
                                <select id="newTransAcc" name="cars" className='transInput'
                                    value={newAcc}
                                    onChange={(e) => setNewAcc(e.target.value)}>
                                    <option disabled value=''> --select account-- </option>
                                    {accOptions.filter((item, index) => item.type == 'account').map((acc, index) => {
                                        return (
                                            <option value={acc.categoryid} key={acc.categoryid}>{acc.name}</option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }


    function SplitListItem(props) {
        const categoryid = props['categoryid'];
        const amount = props['amount'];
        const index = props['index'];


        function removeSplitItem(x) {
            let toBeRemoved = splitList.filter((item, index) => index == x);
            let newRemovalList = removalList.filter((item, index) => 1 == 1);
            setRemovalList(newRemovalList.concat(toBeRemoved));
            let newList = splitList.filter((item, index) => index !== x);
            setSplitList(newList);

        }

        function updateSplitItem(x, cat, amt) {
            let newList = splitList.filter(item => 1 !== 2);
            newList[x][0] = cat;
            newList[x][1] = amt;
            setSplitList(newList);

        }

        return (
            <>
                {/* Deskop */}
                <div className='d-none d-lg-block'>
                    <div className="row mt-4 ms-4 mb-4">
                        <div className="col-6">
                            <select id="newTransCat" name="cars" className='desktopTransInput'
                                value={categoryid}
                                onChange={(e) => updateSplitItem(index, e.target.value, amount)}>
                                <option disabled value=''>--select category--</option>
                                {accOptions.filter(d => d.hidden_at == null)
                                    .filter(d => d.type == 'category')
                                    .map((cat, index) => {
                                        return (
                                            <option value={cat.categoryid} key={cat.categoryid}>
                                                {cat.name}
                                            </option>
                                        )
                                    }
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-4">
                            <input className='desktopTransInput w-100' type='number'
                                placeholder='$0.00'
                                defaultValue={(amount / 100.0).toFixed(2)}
                                onBlur={(e) => updateSplitItem(index, categoryid, toNumber(e.target.value))}
                            />
                        </div>
                        <div className="col-1">
                            <FontAwesomeIcon icon={faMinusCircle} color="red"
                                onClick={() => { removeSplitItem(index); }} />
                        </div>
                    </div>
                </div>
                {/* Mobile */}
                <div className='d-block d-lg-none'>
                    <div className='row mb-4'>
                        <div className="col-7">
                            <select id="newTransCat" name="cars" className='transInput'
                                value={categoryid}
                                onChange={(e) => updateSplitItem(index, e.target.value, amount)}>
                                <option disabled value=''>--select category--</option>
                                {accOptions.filter(d => d.hidden_at == null)
                                    .filter(d => d.type == 'category')
                                    .map((cat, index) => {
                                        return (
                                            <option value={cat.categoryid} key={cat.categoryid}>
                                                {cat.name}
                                            </option>
                                        )
                                    }
                                    )
                                }
                            </select>
                        </div>
                        <div className="col">
                            <input className='splitAmount' type='number'
                                placeholder='$0.00'
                                defaultValue={(amount / 100.0).toFixed(2)}
                                onBlur={(e) => updateSplitItem(index, categoryid, toNumber(e.target.value))}
                            />
                        </div>
                        <div className="col-1">
                            <FontAwesomeIcon icon={faMinusCircle} color="red"
                                onClick={() => { removeSplitItem(index); }} />
                        </div>
                    </div>
                </div>
            </>
        )

    }


    function moveDottedLineDown() {
        styleElem.innerHTML = "#ticketDiv:before {top: 195px;}";
        styleElem.innerHTML = "#ticketDiv:after {top: 195px;}";
        return (1);
    }

    function SplitList() {

        function addSplitItem() {
            console.log("Adding a new split item");
            let newList = splitList.filter(item => 1 !== 2);
            newList.push(['', 0]);
            setSplitList(newList);
        }
        moveDottedLineDown();

        // ..ticket:before, .ticket:after {
        // Need top to be 195px

        // console.log(splitList);
        return (
            <>



                <div className='ticketHoles d-none d-lg-block'>
                    <hr className='ticketLine' />
                </div>
                <div className="container transPayee">

                    {splitList.map((item, index) => {
                        return (
                            <SplitListItem
                                categoryid={item[0]}
                                amount={item[1]}
                                index={index}
                                key={index} />
                        )
                    }
                    )}
                    <div className='btn btn-primary ms-5'
                        onClick={() => { addSplitItem(); }}>
                        + Add category to split
                    </div>
                </div>
            </>
        )
    }

    function PretaxListItem(props) {
        const memo = props['memo'];
        const amount = props['amount'];
        const index = props['index'];


        function removePretaxItem(x) {
            console.log("Getting rid of item", x);
            let toBeRemoved = pretaxList.filter((item, index) => index == x);
            let newRemovalList = removalList.filter((item, index) => 1 == 1);
            setRemovalList(newRemovalList.concat(toBeRemoved));

            let newList = pretaxList.filter((item, index) => index !== x);
            setPretaxList(newList);
            console.log("Getting rid of item", toBeRemoved);

        }

        function updatePretaxItem(x, memo, amt) {
            let newList = pretaxList.filter(item => 1 !== 2);
            newList[x][0] = memo;
            newList[x][1] = amt;
            setPretaxList(newList);

        }

        return (
            <>
                {/* Deskop */}
                <div className='d-none d-lg-block'>
                    <div className="row mt-4 ms-4 mb-4">
                        <div className="col-6">
                            <input className='desktopTransInput w-100' type='text'
                                placeholder='E.g. 401k Contribution'
                                defaultValue={memo}
                                onBlur={(e) => updatePretaxItem(index, e.target.value, toNumber(((amount / 100.0).toFixed(2)).toString()))}
                            />
                        </div>

                        <div className="col-4">
                            <input className='desktopTransInput w-100' type='number'
                                placeholder='$0.00'
                                defaultValue={(amount / 100.0).toFixed(2)}
                                onBlur={(e) => updatePretaxItem(index, memo, toNumber(e.target.value))}
                            />
                        </div>

                        <div className="col-1">
                            <FontAwesomeIcon icon={faMinusCircle} color="red"
                                onClick={() => { removePretaxItem(index); }} />
                        </div>
                    </div>
                </div>
                {/* Mobile */}
                <div className='d-block d-lg-none'>
                    <div className='row mb-4'>
                        <div className="col-7">
                            <input className='splitAmount' type='text'
                                placeholder='E.g. 401k Contribution'
                                defaultValue={memo}
                                onBlur={(e) => updatePretaxItem(index, e.target.value, toNumber(((amount / 100.0).toFixed(2)).toString()))}
                            />
                        </div>
                        <div className="col-4">
                            <input className='splitAmount' type='number'
                                placeholder='$0.00'
                                defaultValue={(amount / 100.0).toFixed(2)}
                                onBlur={(e) => updatePretaxItem(index, memo, toNumber(e.target.value))}
                            />
                        </div>
                        <div className="col-1">
                            <FontAwesomeIcon icon={faMinusCircle} color="red"
                                onClick={() => { removePretaxItem(index); }} />
                        </div>
                    </div>
                </div>
            </>
        )

    }

    function PretaxList() {

        function addPretaxItem() {
            console.log("Adding a new pretax item");
            let newList = pretaxList.filter(item => 1 !== 2);
            newList.push(['', 0]);
            setPretaxList(newList);
        }

        // console.log(pretaxList);
        return (
            <>
                <div className="container transPayee">

                    {pretaxList.map((item, index) => {
                        return (
                            <PretaxListItem
                                memo={item[0]}
                                amount={item[1]}
                                index={index}
                                key={index} />
                        )
                    }
                    )}
                    <div className='btn btn-primary ms-5'
                        onClick={() => { addPretaxItem(); }}>
                        + Add category to split
                    </div>
                </div>
            </>
        )
    }

    class TransactionXFRAccount extends Component {
        render() {
            return (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <div className="row mt-4 ms-5">
                            <div className="col-3">
                                <div className="transTypeLabel">From</div>
                            </div>
                            <div className="col">
                                <select id="newTransAcc" name="cars"
                                    className='desktopTransInput w-75'
                                    value={newXfrAcc}
                                    onChange={(e) => setNewXfrAcc(e.target.value)}>
                                    {accOptions.map((acc, index) => {
                                        return (
                                            acc.type == 'account' ? (
                                                <>
                                                    <option value={acc.categoryid} key={acc.categoryid}>{acc.name}</option>
                                                </>) : (
                                                <div></div>
                                            ));
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row mt-4 ms-5">
                            <div className="col-3">
                                <div className="transTypeLabel">To</div>
                            </div>
                            <div className="col">
                                <select id="newTransAcc" name="cars"
                                    className='desktopTransInput w-75'
                                    value={newAcc}
                                    onChange={(e) => setNewAcc(e.target.value)}>
                                    {accOptions.map((acc, index) => {
                                        return (
                                            acc.type == 'account' ? (
                                                <>
                                                    <option value={acc.categoryid} key={acc.categoryid}>{acc.name}</option>
                                                </>) : (
                                                <div></div>
                                            ));
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                    <div className="container transPayee d-block d-lg-none">
                        <div className="row">
                            <div className="col">
                                <div className="transTypeLabel">From</div>
                            </div>
                            <div className="col">
                                <select id="newTransAcc" name="cars" className='transInput'
                                    value={newXfrAcc}
                                    onChange={(e) => setNewXfrAcc(e.target.value)}>
                                    {accOptions.map((acc, index) => {
                                        return (
                                            acc.type == 'account' ? (
                                                <>
                                                    <option value={acc.categoryid} key={acc.categoryid}>{acc.name}</option>
                                                </>) : (
                                                <div></div>
                                            ));
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container transPayee d-block d-lg-none">
                        <div className="row">
                            <div className="col">
                                <div className="transTypeLabel">To</div>
                            </div>
                            <div className="col">
                                <select id="newTransAcc" name="cars" className='transInput'
                                    value={newAcc}
                                    onChange={(e) => setNewAcc(e.target.value)}>
                                    {accOptions.map((acc, index) => {
                                        return (
                                            acc.type == 'account' ? (
                                                <>
                                                    <option value={acc.categoryid} key={acc.categoryid}>{acc.name}</option>
                                                </>) : (
                                                <div></div>
                                            ));
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>


                </>
            )
        }
    }

    class TransactionDate extends Component {
        render() {
            return (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <div className="row mt-4 ms-5 mb-4">
                            <div className="col-3">
                                <div className="transTypeLabel">Date</div>
                            </div>
                            <div className="col">
                                <input className='desktopTransInput w-75' type='date'
                                    value={newDate}
                                    onChange={(e) => setNewDate(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                    <div className="container transPayee d-block d-lg-none">
                        <div className="row">
                            <div className="col">
                                <div className="transTypeLabel">Date</div>
                            </div>
                            <div className="col">
                                <input className='transInput' type='date'
                                    value={newDate}
                                    onChange={(e) => setNewDate(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    class TransactionAmount extends Component {
        render() {
            return (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <input type="text"
                            className={amtClass + ' desktopAmount'}
                            defaultValue={toCurrency(newAmount)}
                            aria-label="Amount (to the nearest dollar)"
                            onBlur={e => {
                                updateAmt(e.target.value);
                                // const newId = itemId.slice(4,);
                                // const oldVal = items[newId]['val']['budget'];
                                // // console.log(oldVal);
                                // updateBudgetAmt(newId, e.target.value, oldVal);
                            }}
                        />
                    </div>
                    {/* Mobile */}
                    <div className='d-block d-lg-none'>
                        <input type="text"
                            className={amtClass}
                            defaultValue={toCurrency(newAmount)}
                            aria-label="Amount (to the nearest dollar)"
                            onBlur={e => {
                                updateAmt(e.target.value);
                                // const newId = itemId.slice(4,);
                                // const oldVal = items[newId]['val']['budget'];
                                // // console.log(oldVal);
                                // updateBudgetAmt(newId, e.target.value, oldVal);
                            }}
                        // onChange={e => {
                        //     const newVal = toNumber(e.target.value);
                        //     setNewAmount(newVal);
                        // }}
                        />
                    </div>
                </>
            )
        };
    }

    class SaveOrCancel extends Component {
        render() {
            // const history = useHistory();
            return (
                <>{/* Desktop */}
                    {/* Mobile */}
                    <div className="container d-block d-lg-none">
                        <div className="row fixed-bottom text-center">
                            {this.props.is_disabled ? (
                                // If subscription is over, saving is disabled
                                <div >
                                </div>
                            ) : (
                                <div className={saveButtonClass} onClick={() => attemptSave()}>
                                    SAVE
                                </div>
                            )}
                            <div className="col cancelButton saveOrCancelButton" onClick={() => navigate(-1)}>
                                CANCEL
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }


    class DesktopSaveOrCancel extends Component {
        render() {
            // const history = useHistory();
            return (
                <>{/* Desktop */}
                    <div className='d-none d-lg-block'>
                        <div className="row justify-content-center">
                            <div className='col-4'>
                                {this.props.is_disabled ? (
                                    // If subscription is over, saving is disabled
                                    <div className="desktopSaveButtonDisabled">
                                        Save Disabled
                                    </div>
                                ) : (
                                    <div className="desktopSaveButton"
                                        onClick={() => attemptSave()}>
                                        Save Transaction
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                </>
            )
        }
    }

    class XfrScreen extends Component {
        render() {
            return (
                <>
                    <TransactionAmount />
                    <TransactionType />
                    <TransactionXFRAccount />
                    <TransactionDate />
                    <DeleteConfirmation is_disabled={this.props.is_disabled} />
                    {/* <TransactionXfr /> */}
                    <SaveOrCancel is_disabled={this.props.is_disabled} />
                </>
            )
        };
    }

    class SplitExpenseScreen extends Component {
        render() {
            return (
                <>
                    <TransactionPayee />
                    <TransactionAccount />
                    <TransactionDate />
                    <SplitList />
                    <DeleteConfirmation is_disabled={this.props.is_disabled} />
                    {/* <TransactionXfr /> */}
                    <SaveOrCancel is_disabled={this.props.is_disabled} />
                </>

            )
        }
    }

    class PretaxIncomeScreen extends Component {
        render() {
            return (
                <>
                    <TransactionAmount />
                    <TransactionType />
                    <TransactionPayee />
                    <TransactionCategory />
                    <TransactionAccount />
                    <TransactionDate />
                    {/* <PretaxToggle /> */}

                    <PretaxList />
                    <DeleteConfirmation is_disabled={this.props.is_disabled} />

                    <SaveOrCancel is_disabled={this.props.is_disabled} />
                </>

            )
        }
    }

    class IncomeOrExpenseScreen extends Component {
        render() {
            return (
                <>
                    <TransactionAmount />
                    <TransactionType />
                    <TransactionPayee />
                    <TransactionCategory />
                    <TransactionAccount />
                    <TransactionDate />
                    {/* {newType == 0 ? (
                        <PretaxToggle />
                    ) : (
                        <SplitToggle />
                    )} */}
                    <DeleteConfirmation is_disabled={this.props.is_disabled} />
                    <SaveOrCancel is_disabled={this.props.is_disabled} />
                </>

            )
        }
    }

    return (
        <>

            <FreeTrialAlert days_left={days_left} email={email} />
            {/* <div style={days_left === '-1' || days_left < 500
                ? { marginTop: '80px' }
                : {}}> */}
                <div>
                <Navigation key="nav-bar-key" />
                {confirmed ? (
                    <ConfirmScreen
                        buttonList={[['Back to History', '/History']]}
                    />
                ) : (
                    <>
                        {/* Desktop */}
                        <div className='d-none d-lg-block greyBG'>
                            <div className='contentSpace ms-5'>
                                <div className='row ms-2'>
                                    <div className='col-10 mt-5 text-black-50 fw-bold'>
                                        <FontAwesomeIcon icon={faArrowLeft}
                                            className="me-3 hoverPointer" />
                                        <span className='hoverPointer'
                                            onClick={() => navigate(-1)}>
                                            Cancel
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row justify-content-center mt-5'>
                                <div id="ticketDiv" className='ticket col'>
                                    <div className="ticket-content-wrapper">
                                        {newType == 2 ? (
                                            // 0=income, 1=expense, 2=transfer
                                            <XfrScreen is_disabled={days_left === '-1'} />
                                        ) : (
                                            (splitId != null && newType == 1) ? (
                                                // Income or Expense w/ split
                                                <SplitExpenseScreen is_disabled={days_left === '-1'} />
                                            ) : (
                                                (pretax && newType == 0) ? (
                                                    <PretaxIncomeScreen is_disabled={days_left === '-1'} />
                                                ) : (
                                                    <IncomeOrExpenseScreen is_disabled={days_left === '-1'} />
                                                )
                                            )

                                        )}


                                        {/* <SplitList /> */}
                                    </div>
                                </div>
                            </div>
                            <DesktopSaveOrCancel is_disabled={days_left === '-1'} />
                        </div>

                        {/* Mobile */}
                        <div className='d-block d-lg-none '>
                            {newType == 2 ? (
                                // 0=income, 1=expense, 2=transfer
                                <XfrScreen is_disabled={days_left === '-1'} />
                            ) : (
                                (splitId != null && newType == 1) ? (
                                    // Income or Expense w/ split
                                    <SplitExpenseScreen />
                                ) : (
                                    (pretax && newType == 0) ? (
                                        <PretaxIncomeScreen is_disabled={days_left === '-1'} />
                                    ) : (
                                        <IncomeOrExpenseScreen is_disabled={days_left === '-1'} />
                                    )
                                )

                            )}

                            <div id="bottomSpacing" className='mb-5'></div>
                        </div>
                    </>
                )
                }
            </div>
        </>
    );
}

