import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
// import { budgetsteps } from '../toursteps/budgetsteps';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import './index.css';
import "shepherd.js/dist/css/shepherd.css";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';


const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: true
        }
    },
    useModalOverlay: true
};

function TourIcon() {
    const tour = useContext(ShepherdTourContext);
    return (
        <div className='tourButton' 
            onClick={tour.start}
        >
            <FontAwesomeIcon icon={faCircleQuestion}
                className="" />
        </div>
    );
}

export default function TourButton(props) {
    // console.log("TOUR", props);

    return (
        (props.steps !== undefined) ? (
            <ShepherdTour
                steps={props.steps}
                tourOptions={tourOptions}>
                <TourIcon />
            </ShepherdTour>
        ) : (
            <></>
        )

    );
}

