import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
// import parse, { attributesToProps } from 'html-react-parser';
import 'bootstrap/dist/css/bootstrap.min.css';
// import monthlyIncomeImg from './monthlyincome.PNG';
// import envelopesImg from './rent.png';
import { firebaseDb, firebaseAuth, firebaseGoogleAuth } from './firebase.js';
import 'font-awesome/css/font-awesome.min.css';
// import { BrowserRouter, Route, useHistory, withRouter, Redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";

// library.add(faCoffee);



function NewAccount(props) {
    let navigate = useNavigate();

    const [transactions, setTransactions] = useState([]);

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [userID, setUserID] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    console.log("Loading SignUp");


    const signIn = () => {
        firebaseAuth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in
                console.log('logged in!');
                props.history.push('/History');
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode);
                console.log(errorMessage);
            });
    };


    const createAccountWithEmail = () => {
        console.log("Attempting create account with ", email, password, confirmPassword);

        const emailRegExp = new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$', 'g');
        if (password !== confirmPassword) {
            alert("Password confirmation does not match.")
        } else if (password.length <= 5) {
            alert("Password must be at least 6 characters long.")
        } else if (emailRegExp.test(email)) {
            console.log("Initial checks passed");
            firebaseAuth.createUserWithEmailAndPassword(email, password).then(u => {
                console.log("New User Created!!!");
                console.log(u);
            }).catch(error => {
                switch (error.code) {
                    case 'auth/email-already-in-use':
                        alert(`Email address already in use.`);
                        break;
                    case 'auth/invalid-email':
                        alert(`Email address is invalid.`);
                        break;
                    case 'auth/operation-not-allowed':
                        alert(`Error during sign up.`);
                        break;
                    // case 'auth/weak-password':
                    // console.log('Password is not strong enough. Add additional characters including special characters and numbers.');
                    // break;
                    default:
                        alert(error.message);
                        // setEmailWarning(true);
                        break;
                }
            });
            //handleAnswerButtonClick(currentQuestion,answerIndex);
        } else {
            alert("Email address is invalid.");
            // display bad email
            // setEmailWarning(true);
        }
    };

    const createAccountWithGoogle = () => {
        console.log("Attempting create account with Google...", );
        firebaseAuth.signInWithPopup(firebaseGoogleAuth)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = firebaseGoogleAuth.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          // ...
          console.log("Created user with Google");
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = firebaseGoogleAuth.credentialFromError(error);
          // ...
        });

    };

    
    const createAccountWithFacebook = () => { 
    }

    const dummyAPICall = (goal) => {
        const db = firebaseDb.ref("/items");
        db.push(goal);
    };


    useEffect(() => {
        firebaseAuth.onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                console.log("signed in!");
                console.log(firebaseAuth.currentUser.uid);
                setUserID(firebaseAuth.currentUser.uid);
                // useNavigate('/History');
            } else {
                // No user is signed in.
                console.log('no one signed in');
            }
        });
    }, []);


    function updateEmail(rawVal) {
        const newVal = rawVal;
        // this.setState({newAmount: newVal});
        // could introduce a very fake toggle to force state change

        // console.log(newVal);
        // setNewAmount(0);
        setEmail(newVal);
        // setState({ newAmount: newVal })
    }

    return (

        <>
            {/* Desktop */}
            <div className='d-none d-lg-block greyBG'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-4 '>
                            <div className='mt-5 mb-5 d-inline-block'></div>
                            <div className='mt-5 mb-5 d-inline-block'></div>
                            <div className="mt-5 mb-5">
                                <input className="form-control"
                                    type="email"
                                    placeholder="Email"
                                    defaultValue={email}
                                    onBlur={e => updateEmail(e.target.value)} />
                                <input className="form-control"
                                    type="password"
                                    placeholder="Password"
                                    defaultValue={password}
                                    onBlur={e => setPassword(e.target.value)} />
                                <input className="form-control"
                                    type="password" placeholder="Confirm Password"
                                    defaultValue={confirmPassword}
                                    onBlur={e => setConfirmPassword(e.target.value)} />
                                <div class="d-grid gap-2">
                                    <button className='btn btn-success '
                                        onClick={() => createAccountWithEmail()}>Create Account
                                    </button>
                                </div>
                                <div class="d-grid gap-2 mt-4">
                                    <button className='btn btn-outline-success '
                                        onClick={() => createAccountWithGoogle()}>Sign In with Google
                                    </button>
                                    <button className='btn btn-outline-info '
                                        onClick={() => createAccountWithFacebook()}>Sign In with Meta
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile */}
            <div className='d-block d-lg-none '>
                <div className='app d-flex flex-column '>
                    <div>TIME TO LOG IN</div>
                    <p>Login </p>
                    <div className="d-grid gap-2 mt-auto">
                        <input className="form-control" type="email" placeholder="Email"
                            value={email} onInput={e => setEmail(e.target.value)} />
                        <label htmlFor="exampleInputEmail1" className="form-label"></label>
                        <input className="form-control" type="password" placeholder="Password"
                            value={password} onInput={e => setPassword(e.target.value)} />
                        <button className='btn-active' onClick={() => signIn()}>Sign In</button>
                    </div>
                </div>
            </div>

        </>
    );
}



export default NewAccount;

