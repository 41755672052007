import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faList, faUserFriends, faCog, faPlus, faAward, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

import sqarelogo from './img/squarelogo.png';

const tabs = [{
    route: "/History",
    icon: faList,
    label: "History",
    key: 1
}, {
    route: "/Budget",
    icon: faWallet,
    label: "Budget",
    key: 2
}, {
    route: "/AddTransaction",
    icon: faPlus,
    label: "AddTransaction",
    key: 3
}, {
    route: "/Analytics",
    icon: faChartLine,
    label: "Analytics",
    key: 4
}, {
    route: "/Settings",
    icon: faCog,
    label: "Settings",
    key: 5
}]

const desktopTabs = [{
    route: "/Budget",
    icon: faWallet,
    label: "Budget",
    key: 1
}, {
    route: "/History",
    icon: faList,
    label: "History",
    key: 2
}, {
    route: "/Analytics",
    icon: faChartLine,
    label: "Analytics",
    key: 3
}, {
    route: "/Settings",
    icon: faCog,
    label: "Settings",
    key: 4
}, {
    route: "/AddTransaction",
    icon: faPlus,
    label: "New Transaction",
    key: 4
}]

const Navigation = (props) => {
    const location = useLocation();
    // console.log(location.pathname);
    return (
        <>
            {/* Desktop Nav*/}
            <div className="d-none d-lg-block desktopNav" >
                <div className="d-flex flex-column flex-shrink-0 pt-5 bg-light desktopNav" >
                    <a href="/" className="d-flex align-items-center text-muted text-decoration-none">
                        <svg className="" width="40" height="32"></svg>
                        <img
                            src={sqarelogo}
                            className='navLogo ms-5'
                            alt="..."></img>
                    </a>
                    <div className='mb-4 mt-4'></div>
                    <ul className="nav nav-pills flex-column mb-auto">

                        {desktopTabs.map((tab, index) => (
                            <React.Fragment key={`key900-${index}`}>
                                {tab.label == 'New Transaction' ? (
                                    <NavItem key={`tab600-${tab.key}`} className="nav-item m-2" >
                                        <NavLink key={`tab800-${tab.key}`} to={tab.route}
                                            activeclassname="active"
                                            className="nav-link desktopNavAdd desktopNavItem" >
                                            <FontAwesomeIcon icon={tab.icon} className="me-4 ms-3" />
                                            {tab.label}
                                        </NavLink>
                                    </NavItem>
                                ) : (
                                    <NavItem key={`tab700-${tab.key}`} className="nav-item m-2" >
                                        <NavLink key={`tab900-${tab.key}`} to={tab.route}
                                            activeclassname="active"
                                            className="nav-link fs-5 desktopNavItem" >
                                            <FontAwesomeIcon icon={tab.icon} className="me-4 ms-3" />
                                            {tab.label}
                                        </NavLink>
                                    </NavItem>
                                )}

                            </React.Fragment>

                        ))
                        }

                    </ul>

                    <div to={"/"} key="key-blankThanks">
                        <div className='bottomNavAlert text-center p-4'>
                            <div className='fw-bold fs-5 text-dark mt-3 mb-2'>Thanks!</div>
                            <div className='text-dark mb-4'>We hope you're enjoying Vermillion ad much as we do.</div>
                            <a href='https://vermillion.app/help/' target="_blank" rel="noopener noreferrer">
                                <div className='btn btn-primary-purple mb-3'>
                                    Learn more
                                </div>
                            </a>
                        </div>
                    </div>

                    <hr />
                    <div className="dropdown">
                        <a href="/" className="d-flex align-items-center text-muted text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                            {/* <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
                            <strong>mdo</strong> */}
                        </a>
                    </div>
                </div>


            </div>

            {/* Mobile */}
            {(location.pathname === '/AddTransaction' || location.pathname === '/EditTransaction') ? (
                <></>
            ) : (
                <>
                    <nav className="navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav bg-light " role="navigation">
                        <Nav key='mobile-nav-key' className="w-100">
                            <div className=" d-flex flex-row justify-content-around w-100">
                                {
                                    tabs.map((tab, index) => (

                                        <React.Fragment key={`key500-${index}`}>
                                            {tab.label == 'AddTransaction' ? (
                                                <div key={`newkey-${index}`}>
                                                    <React.Fragment key={`key-${index}`}>
                                                        <NavItem key={`tab-${tab.label}`}>
                                                            <NavLink key={`link-${tab.label}`} to={tab.route} className="nav-link bottom-nav-link" activeclassname="active">
                                                                <div key={`key1-${index}`} className="row d-flex flex-column justify-content-center align-items-center addButton">
                                                                    <FontAwesomeIcon key={`key2-${index}`} size="lg" icon={tab.icon} />
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                    </React.Fragment>
                                                </div>
                                            ) : (
                                                <div key={`newkey-${index}`}>
                                                    <React.Fragment key={`key-${index}`}>
                                                        <NavItem key={`tab-${tab.label}`}>
                                                            <NavLink key={`link-${tab.label}`} to={tab.route} className="nav-link bottom-nav-link" activeclassname="active">
                                                                <div key={`key1-${index}`} className="row d-flex flex-column justify-content-center align-items-center">
                                                                    <FontAwesomeIcon key={`key2-${index}`} size="lg" icon={tab.icon} />
                                                                    <div key={`key3-${index}`} className="bottom-tab-label">{tab.label}</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                    </React.Fragment>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))
                                }
                            </div>
                        </Nav>
                    </nav>
                </>
            )}
        </>

    )
};

export default Navigation;