import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { withRouter, useNavigate } from "react-router-dom";
// import LinesEllipsis from 'react-lines-ellipsis';

// import SwipeToRevealActions from '../SwipeToRevealActions'


import CategoryBalanceButton from './CategoryBalanceButton';
import BudgetProgress from './BudgetProgress';
import { toCurrency, toNumber, textEllipsis } from '../util/Util';
// import { text } from 'stream/consumers';


export default function BudgetCategory(props) {
	let navigate = useNavigate();
    // console.log("PROPS FOR BUDGET CAT", props);
    // { onClick, itemId, name, budgeted, spent, bal, catid }
    return (
        <div id={"budgetCat-" + props.catid} className='budgetcatdiv'>
            {/* <SwipeToRevealActions
                actionButtons={[
                    {
                        content: (
                            <div className="your-className-here">
                                <CategoryBalanceButton
                                    bal={props.bal}
                                    bud={props.budgeted}
                                    budgetid={"budget-input-" + props.itemId}
                                    hotBalance={props.hotBalance}
                                />



                            </div>
                        ),
                        onClick: () => console.log('Pressed the balance button'),
                    },
                    {
                        content: (
                            <div className="your-className-here">
                                <FontAwesomeIcon icon={faArrowRight}
                                    className="ms-1"
                                    onClick={() => {
                                        console.log()
                                        props.history.push({
                                            pathname: '/Category',
                                            state: {
                                                // any values/objects/etc you want to also send to pushed route/path
                                                catid: props.catid,
                                                cats: props.budget
                                            },
                                        })
                                    }} />
                            </div>
                        ),
                        onClick: () => console.log('Pressed the details button'),
                    },
                ]}
                actionButtonMinWidth={70}
            > */}

                
                <div className='container bcatContainer'>
                    <div id={"budgetCat" + props.catid + "row1"} className='row mb-2'>
                        <div className='col'>
                            <div className='catName' >
                                {props.name}
                                {/* <LinesEllipsis
                                    text={props.name}
                                    maxLine='1'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters'
                                />*/}
                                {/* {textEllipsis(props.name,10)}  */}
                            </div>
                        </div>
                    </div>

                    <div id={"budgetCat" + props.catid + "row2"} className='row'>
                        <div className=' mb-2 text-end'>
                            <BudgetProgress
                                budget={props.budget}
                                spent={props.spent}
                                bal={props.bal}
                                name={props.name}
                                itemId={props.itemId} />
                        </div>
                    </div>

                    <div id={"budgetCat" + props.catid + "row2"} className='row'>
                        <div className='col-4'>
                            <input type="text"
                                id={"budget-input-" + props.itemId}
                                className="budgetAmt"
                                defaultValue={toCurrency(props.budgeted)}
                                aria-label="Amount (to the nearest dollar)"
                                disabled={props.is_disabled}
                                onBlur={e => {
                                    const newId = props.itemId;
                                    console.log(props.itemId);
                                    console.log(props.budget.filter(d => d.budgetid == props.itemId)[0]['budgeted']);
                                    const oldVal = props.budget.filter(d => d.budgetid == props.itemId)[0]['budgeted'];
                                    // console.log(oldVal);
                                    props.updateBudgetAmt(newId, e.target.value, oldVal, props.itemId);
                                }}
                            />
                        </div>
                        <div className='col-3 text-end'>
                            <span className='catSpent' >
                                {toCurrency(Math.abs(props.spent))}
                            </span>

                        </div>
                        <div className='col-4 text-end'>
                            {props.bal >= 0 ? (
                                <>
                                    {props.bal == 0 ? (
                                        <div className="budgetBal-neu">{toCurrency(Math.abs(props.bal))}</div>
                                    ) : (
                                        <div className="budgetBal-pos">{toCurrency(Math.abs(props.bal))}</div>
                                    )}

                                </>
                            ) : (
                                <>
                                    <div className="budgetBal-neg">{toCurrency(Math.abs(props.bal))}</div>

                                </>
                            )}
                        </div>
                        <div className='col-1 text-end'>
                            <span className='catSpent' >
                            <FontAwesomeIcon icon={faAngleRight}
                                    className="ms-1"
                                    onClick={() => {
                                        console.log()
                                        navigate('/Category',
                                            {state: {
                                                // any values/objects/etc you want to also send to pushed route/path
                                                catid: props.catid,
                                                cats: props.budget
                                            }},
                                        )
                                    }} />
                            </span>

                        </div>
                    </div>
                </div>
            {/* </SwipeToRevealActions > */}
        </div >

    );
}
