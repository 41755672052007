import React, { useEffect, useState } from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import '../index.css'
import { toCurrency } from '../util/Util';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';


function HistoryDateRangePicker(props) {

    var firstDay = props.startDate;
    var lastDay = props.endDate;

    const today = new Date();

    function handleEvent(event, picker) {
        // var picker = $('#HistoryDateRangePicker').data('daterangepicker');
        console.log("date changeiiiiing", picker);
        var newStartDate = new Date(picker.split(" ")[0]);
        var newEndDate = new Date(picker.split(" ")[2]);
        props.setStartDate(newStartDate);
        props.setEndDate(newEndDate);
        console.log("Setting start date to...", newStartDate);
        console.log("Setting end date to...", newEndDate);
    }

    var date = new Date();
    var date2 = new Date();
    date2.setDate(date2.getDate()-30);
    var firstDay2 = new Date(date2.getFullYear(), date2.getMonth(), 1);
    var lastDay2 = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return (
        <DateRangePicker
            id="HistoryDateRangePicker"
            onApply={(e) => { handleEvent(e, e.target.value) }}
            initialSettings={{
                startDate: firstDay2,
                endDate: lastDay2,
                ranges: {
                    Today: [moment().toDate(), moment().toDate()],
                    Yesterday: [
                        moment().subtract(1, 'days').toDate(),
                        moment().subtract(1, 'days').toDate(),
                    ],
                    'Last 7 Days': [
                        moment().subtract(6, 'days').toDate(),
                        moment().toDate(),
                    ],
                    'Last 30 Days': [
                        moment().subtract(29, 'days').toDate(),
                        moment().toDate(),
                    ],
                    'This Month': [
                        moment().startOf('month').toDate(),
                        moment().endOf('month').toDate(),
                    ],
                    'Last Month': [
                        moment().subtract(1, 'month').startOf('month').toDate(),
                        moment().subtract(1, 'month').endOf('month').toDate(),
                    ],
                },
            }}
        //   onCallback={handleCallback}
        >
            <input type="text" className="form-control HistoryDateRangePicker" />
            
        </DateRangePicker>
    );
}


export default HistoryDateRangePicker;