import React, { useEffect, useState } from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import '../index.css'
import { toCurrency } from '../util/Util';
import HistoryDateRangePicker from './HistoryDateRangePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faPencilAlt,faPencil } from '@fortawesome/free-solid-svg-icons';
import { withRouter, useHistory, useNavigate } from "react-router-dom";



const today = new Date();

function App(props) {
	let navigate = useNavigate();
    // const [months,setMonths] = useState(props.accountList);
    const [selected, setSelected] = useState([props.selectedAccount]);

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    // console.log("selected", selected);
    // console.log("selectedAccount", props.selectedAccount);
    // console.log("selectedAccount", props.accountList);

    // useEffect(() => {
    //     if (props.selectedAccount === undefined && props.accountList !== undefined && props.accountList !== []) {
    //         // console.log("current account has been changed",props.accountList[0]['accountid']);
    //         console.log("accountsss", props.accountList);
    //     }
    // }, []);

    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);
                var blah = [];
                props.updateAccount(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? blah = [id]
                        // if item is currently selected, do nothing
                        : currentSelected = [id]
                    // if it's not currently selected, add it!
                );
            };

    return (
        <>
            <div className='bg-white navMonthDate ps-4 pt-4'>
                <div id="currentDay">
                    {today.toLocaleString('en-EN', { weekday: 'long' })}
                </div>
                <div id="currentDate">
                    {(today.toLocaleString('en-EN', { month: 'long', day: 'numeric', year: 'numeric' })).replace(',', '')}
                </div>
            </div>
            <div className='bg-white'>
                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                    {(props.accountList).map(({ name, accountid, running_balance, closed_at }) => (
                        closed_at == null ? (
                        <div id={accountid} key={`keyacc-${accountid}`} className="accountContainer">
                            <Card
                                itemId={accountid} // NOTE: itemId is required for track items
                                name={name}
                                running_balance={running_balance}
                                onClick={handleClick(accountid)}
                                selected={accountid == props.selectedAccount}
                            />
                        </div>
                        ) : (
                            <></>
                        )
                    ))}
                    <SelectCard
                        itemId="add-account" // NOTE: itemId is required for track items
                        name="Edit/Add Account"
                        running_balance={0}
                        onClick={() => {navigate('/Accounts')}}
                        selected={false}
                    />
                </ScrollMenu>
            </div>
            <div className='bg-white scrollNavMonths css-box-shadow pb-3 mb-4'>
                <HistoryDateRangePicker
                    start={props.startDate}
                    end={props.endDate}
                    setStartDate={props.setStartDate}
                    setEndDate={props.setEndDate} />
            </div>
        </>

    );
}

function LeftArrow() { return (''); }
function RightArrow() { return (''); }

function Card({ onClick, selected, name, running_balance, itemId }) {
    const visibility = React.useContext(VisibilityContext);

    return (

        <>
            < div
                onClick={() => onClick(visibility)
                }
                style={{
                    width: '70px',
                }}
                tabIndex={0}
            >
                {selected ? (
                    <div id="activeAccount" className="active accountSingleContainer">
                        <div className="text-left accountSingleBalance">{toCurrency(running_balance)}</div>
                        <div className="text-left accountSingleName">{name}</div>
                        <div className="text-left accountSingleStars">**** **** ****</div>
                    </div>
                ) : (
                    <div className="accountSingleContainer">
                        <div className="text-left accountSingleBalance">{toCurrency(running_balance)}</div>
                        <div className="text-left accountSingleName">{name}</div>
                        <div className="text-left accountSingleStars">**** **** ****</div>
                    </div>
                )}

            </div >
        </>

    );
}


function SelectCard({ onClick, selected, name, running_balance, itemId }) {
    const visibility = React.useContext(VisibilityContext);

    return (

        <>
            < div
                onClick={() => onClick(visibility)
                }
                style={{
                    width: '70px',
                }}
                tabIndex={0}
            >
                {selected ? (
                    <div id="activeAccount" className="active accountSingleContainerSelect">
                        <div className="text-left accountSingleBalance"><FontAwesomeIcon icon={faPlus} className="ms-3" /></div>
                        <div className="text-left accountSingleName">{name}</div>
                        {/* <div className="text-left accountSingleStars">**** **** ****</div> */}
                    </div>
                ) : (
                    <div className="accountSingleContainerSelect">
                        <div className="text-left accountSingleBalance"><FontAwesomeIcon icon={faPlus} className="ms-3" /></div>
                        <div className="text-left accountSingleName">{name}</div>
                        {/* <div className="text-left accountSingleStars">**** **** ****</div> */}
                    </div>
                )}

            </div >
        </>

    );
}

export default App;