import React, { Component, useState } from 'react';
import ScrollMonthMenu from './ScrollMonthMenu';
import DesktopScrollMonthMenu from './components/DesktopScrollMonthMenu';
import LoadingSpinner from "./LoadingSpinner";
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import TourButton from './components/TourButton';
import { budgetsteps } from './toursteps/budgetsteps';
// Note: Only Solid and Brands are avail for free. "Regular" etc are Pro.

// import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used


// import { SwipeToRevealActions } from './react-swipe-to-reveal-actions';
// import SwipeToRevealActions from './SwipeToRevealActions';
// import { SwipeListView } from 'react-native-swipe-list-view';
// import { SwipeToRevealOptions } from 'react-swipe-to-reveal-options2';

import { firebaseAuth } from './firebase.js';
import { withRouter, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './index.css'
import Lottie from 'lottie-react';
import greenCheck from './lotties/greencheck.json';
import { Button, Modal } from 'react-bootstrap';

import BudgetSummary from './components/BudgetSummary';
import BudgetCategory from './components/budgetCategory';
import DesktopBudgetCategory from './components/DesktopBudgetCategory';
import FreeTrialAlert from './components/FreeTrialAlert';
import Navigation from './Navigation';
import ConfirmScreen from './components/ConfirmScreen.js';
import comingsoon from './img/comingsoon.png';

import { reformatDate } from "./util/Util";


// library.add(faCoffee);
export default function Analytics(props) {
    let navigate = useNavigate();

    return (
        <>
            <Navigation key="nav-bar-key" />
            <>
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block greyBG fullBG'>
                        <div className='contentSpace'>
                            <div className='row ms-5 d-flex justify-content-center'>
                                <div id='bottomSpacing'></div>
                                <div id='bottomSpacing'></div>
                                <div className='w-50 mt-5 '>
                                    <img
                                        src={comingsoon}
                                        className='img-fluid '
                                        alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Mobile */}
                    <div className='greyBG d-block d-lg-none '>

                        <div id='bottomSpacing'></div>
                        <div className='w-100 mt-5 '>
                            <img
                                src={comingsoon}
                                className='img-fluid '
                                alt="..." />
                        </div>
                    </div>

                </>
            </>

        </>
    );
}

