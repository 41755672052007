import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

function BudgetProgress({ budget, spent, bal, name, itemId }) {
    return (
        bal > 0 ? (
            spent / bal < 0.05 ? (
                <>
                    {/* <ProgressBar variant="success" now={100*spent/budget} key={1} className='progress-sm' /> */}
                    <ProgressBar className='progress-container' key={`progress-container-1-${itemId}`}>
                        <ProgressBar variant="success" now={5} key={`keyprog-11-${itemId}`} className='progress-sm' />
                        <ProgressBar variant="warning" now={3} key={`keyprog-12-${itemId}`} />
                        <ProgressBar variant="success" now={100 - (100 * spent / bal)} key={`keyprog-13-${itemId}`} className='progress-sm' />
                    </ProgressBar>
                </>

            ) : (
                <>
                    {/* <ProgressBar variant="success" now={100*spent/budget} key={1} className='progress-sm' /> */}
                    <ProgressBar className='progress-container' key={`progress-container-22-${itemId}`}>
                        <ProgressBar variant="info" now={100 * spent / bal} key={`keyprog-21-${itemId}`} className='progress-sm' />
                        <ProgressBar variant="warning" now={3} key={`keyprog-22-${itemId}`} />
                        <ProgressBar variant="success" now={100 - (100 * spent / bal)} key={`keyprog-3-${itemId}`} className='progress-sm' />
                    </ProgressBar>
                </>

            )

        ) : (
            bal == 0 ? (

                spent > 0 ? (
                    <>
                        <ProgressBar className='progress-container' key={`progress-container-3-${itemId}`}>
                            <ProgressBar variant="info" now={94} key={`keyprog-31-${itemId}`} className='progress-sm' />
                            <ProgressBar variant="warning" now={3} key={`keyprog-32-${itemId}`} />
                            <ProgressBar variant="info" now={3} key={`keyprog-33-${itemId}`} className='progress-sm' />
                        </ProgressBar>
                    </>

                ) : (
                    <>
                        <ProgressBar className='progress-container' key={`progress-container-3-${itemId}`}>
                            <ProgressBar variant="info" now={3} key={`keyprog-31-${itemId}`} className='progress-sm' />
                            <ProgressBar variant="warning" now={3} key={`keyprog-32-${itemId}`} />
                            <ProgressBar variant="info" now={94} key={`keyprog-33-${itemId}`} className='progress-sm' />
                        </ProgressBar>
                    </>
                )

            ) : (
                <>
                    <ProgressBar className='progress-container' key={`progress-container-3-${itemId}`}>
                        <ProgressBar variant="danger" now={94} key={`keyprog-41-${itemId}`} className='progress-sm' />
                        <ProgressBar variant="warning" now={3} key={`keyprog-42-${itemId}`} />
                        <ProgressBar variant="danger" now={3} key={`keyprog-43-${itemId}`} className='progress-sm' />
                    </ProgressBar>
                </>
            )
        )

    )
}

export default BudgetProgress