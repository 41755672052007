import React from 'react';
import { Button } from 'react-bootstrap';
import Lottie from 'lottie-react';
import greenCheck from '../lotties/GreenSpinner.json';
import { useNavigate } from "react-router-dom";
import error from '../img/error.png';



function ErrorScreen({ buttonList, history }) {
    let navigate = useNavigate();

    function ConfirmScreenButton(text, link, index) {
        return (
            <>
                <Button
                    variant={index === 1 ? ("success") : ("outline-success")}
                    size="lg"
                    onClick={() => {
                        if (link === '') {
                            window.location.reload(false);
                        } else {
                            navigate(link)
                        }
                    }}>
                    {text}
                </Button>
            </>
        )
    };
    // [[buttonText1, buttonLink1], [buttonText2, buttonLink2]]
    return (
        <div>
            <div className='d-none d-lg-block '>
                <div className='row greyBG justify-content-center m-0'>
                    
                <div className=''>
                        <div className='w-50 m-auto p-5 h1 text-center mt-5 pb-3'>
                            A problem occurred with your request.
                        </div>
                    <div className='w-50 m-auto'>
                        <img
                            src={error}
                            className='img-fluid '
                            alt="..." />
                    </div>
                    <div id="" className='mt-3'>
                        <div className="text-center h5">
                            Contact us at <br/>
                            <a href="mailto:hello@vermillion.app?subject=Issue with website&body=I'm having trouble with..."
                                style={{}}> hello@vermillion.app</a>.
                        </div>
                    </div>
                </div>
                </div>
            </div>

            {/* Mobile */}
            <div className='d-block d-lg-none greyBG fullBG'>
                <div className=''>
                        <div className='p-5 h1 text-center mt-5 pb-3'>
                            A problem occurred with your request.
                        </div>
                    <div className='w-100 m-auto'>
                        <img
                            src={error}
                            className='img-fluid '
                            alt="..." />
                    </div>
                    <div id="" className='mt-3'>
                        <div className="text-center h5">
                            Contact us at <br/>
                            <a href="mailto:hello@vermillion.app?subject=Issue with website&body=I'm having trouble with..."
                                style={{}}> hello@vermillion.app</a>.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorScreen