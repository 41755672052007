import React from "react";
// import { Data } from "../util/Data.js";
// import {Charts} from "./PieChart";
// import "./styles.css";
import { useLocation } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
      usePointStyle: true,
      pointStyle: 'circle'
    },
    title: {
      display: false,
    },
  },
  // Modify the axis by adding scales
  scales: {
    // to remove the labels
    x: {
      // ticks: {
      //   display: false,
      // },

      // to remove the x-axis grid
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    // to remove the y-axis labels
    y: {
      ticks: {
        display: false,
        beginAtZero: true,
      },
      // to remove the y-axis grid
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
};



function MonthOverMonthSpend(props) {
  const location = useLocation();
  let thisYear = {};
  let lastYear = {};
  let thisYearData = [];
  let lastYearData = [];
  let labels = [];


  // console.log("mom spend3", props.data);

  if (props.data !== undefined) {
    thisYear = props.data.filter(m => m.label === 'This Year');
    lastYear = props.data.filter(m => m.label === 'Last Year');

    thisYearData = thisYear.map(m => m.sum);
    lastYearData = lastYear.map(m => m.sum);
    labels = lastYear.map(m => m.monthname);
    // console.log("mom spend4", thisYearData);
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Last Year',
        data: lastYearData,
        backgroundColor: 'rgba(58, 129, 149, 1)',
        borderRadius: 5,
        pointStyle: 'circle'
      },
      {
        label: 'This Year',
        data: thisYearData,
        backgroundColor: 'rgba(126, 201, 196, 1)',
        borderRadius: 5,
        pointStyle: 'line'
      },
    ],
  };


  return (
    <div className="momSpendContainer m-3">
      <div className="h5 text-center fw-bold mb-4">Month Over Month Spending</div>
      <div className="momSpendWrapper">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}

export default MonthOverMonthSpend;