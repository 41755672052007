import React, { useEffect, useState } from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import '../index.css'
import { toCurrency } from '../util/Util';
import HistoryDateRangePicker from './HistoryDateRangePicker';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faPencil } from '@fortawesome/free-solid-svg-icons';
import { withRouter, useHistory, useNavigate } from "react-router-dom";




const today = new Date();

function App(props) {

    let navigate = useNavigate();
    // const [months,setMonths] = useState(props.accountList);
    const [selected, setSelected] = useState([props.selectedAccount]);

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    // console.log("selected", selected);
    // console.log("selectedAccount", props.selectedAccount);
    // console.log("selectedAccount", props.accountList);

    // useEffect(() => {
    //     if (props.selectedAccount === undefined && props.accountList !== undefined && props.accountList !== []) {
    //         // console.log("current account has been changed",props.accountList[0]['accountid']);
    //         console.log("accountsss", props.accountList);
    //     }
    // }, []);

    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);
                var blah = [];
                props.updateAccount(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? blah = [id]
                        // if item is currently selected, do nothing
                        : currentSelected = [id]
                    // if it's not currently selected, add it!
                );
            };

    return (
        <>
            <div className='desktopAccountNav'>
                <ScrollMenu
                    LeftArrow={LeftArrow}
                    RightArrow={RightArrow}>
                    {(props.accountList).map(({ name, accountid, running_balance, closed_at }) => (
                        closed_at == null ? (
                            <div id={accountid} key={`keyacc-${accountid}`} className="desktopAccountContainer hoverPointer">
                                <Card
                                    itemId={accountid} // NOTE: itemId is required for track items
                                    name={name}
                                    running_balance={running_balance}
                                    onClick={handleClick(accountid)}
                                    selected={accountid == props.selectedAccount}
                                />
                            </div>
                        ) : (
                            <></>
                        )

                    ))}
                    <div id={'add-account'}
                        key={`keyacc-add-account`}
                        className="desktopAccountContainerSelect hoverPointer">
                        <SelectCard
                            itemId={1} // NOTE: itemId is required for track items
                            name="Edit/Add Accounts"
                            running_balance={0}
                            onClick={() => { navigate('/Accounts') }}
                            selected={false}
                        />
                    </div>
                </ScrollMenu>
            </div>
        </>

    );
}

function LeftArrow() { return (''); }
function RightArrow() { return (''); }

function Card({ onClick, selected, name, running_balance, itemId }) {
    const visibility = React.useContext(VisibilityContext);

    return (

        <>
            < div
                onClick={() => onClick(visibility)
                }
                style={{
                    width: '70px',
                }}
                tabIndex={0}
            >
                {selected ? (
                    <div id="activeAccount" className="active desktopAccountSingleContainer">
                        <div className="text-left accountSingleBalance">{toCurrency(running_balance)}</div>
                        <div className="text-left accountSingleName">{name}</div>
                        <div className="text-left accountSingleStars">**** **** ****</div>
                    </div>
                ) : (
                    <div className="desktopAccountSingleContainer">
                        <div className="text-left accountSingleBalance">{toCurrency(running_balance)}</div>
                        <div className="text-left accountSingleName">{name}</div>
                        <div className="text-left accountSingleStars">**** **** ****</div>
                    </div>
                )}

            </div >
        </>

    );
}


function SelectCard({ onClick, selected, name, running_balance, itemId }) {
    const visibility = React.useContext(VisibilityContext);

    return (

        <>
            < div
                onClick={() => onClick(visibility)
                }
                style={{
                    width: '70px',
                }}
                tabIndex={0}
            >
                {selected ? (
                    <div id="activeAccount" className="active desktopAccountSingleContainerSelect">
                        <div className="text-left ">{toCurrency(running_balance)}</div>
                        <div className="text-left accountSingleName">{name}</div>
                        <div className="text-left accountSingleStars"></div>
                    </div>
                ) : (
                    <div className="desktopAccountSingleContainerSelect">
                        <div className="text-left"><FontAwesomeIcon icon={faPlus} className="ms-2" /></div>
                        <div className="text-left accountSingleName">{name}</div>
                        <div className="text-left accountSingleStars"></div>
                    </div>
                )}

            </div >
        </>

    );
}

export default App;