export const budgetsteps = [
  {
    id: 'budgetTour1',
    attachTo: { element: '.hegitget', on: 'bottom' },
    
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel'
      },
      
      {
        classes: 'shepherd-button-danger',
        text: 'Show me around',
        type: 'next'
      }
    ],
    classes: 'shepherd-element-bottom',
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Your Budget',
    text: ["This is where you'll proactively budget every dollar to make sure your money is working as hard as you do!"],
  },
  {
    id: 'budgetTour2',
    attachTo: { element: '#desktopIncomeSummary', on: 'bottom' },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Next: Savings',
        type: 'next'
      }
    ],
    classes: 'shepherd-element-bottom',
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Income Summary',
    text: ["Whenever money comes into your life (a paycheck, a gift from Grandma, etc.) be sure to record it as an income transaction and it will appear here. When you first start budgeting, this will be $0.00 until you earn some additional cash."],
  },
  {
    id: 'budgetTour3',
    attachTo: { element: '#desktopSavingsSummary', on: 'left' },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [  
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },    
      {
        classes: 'shepherd-button-primary',
        text: 'Next: Expenses',
        type: 'next'
      }
    ],
    classes: 'shepherd-element-left',
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Saving Summary',
    text: ["Every dollar you assign to a savings category will be recorded here, as post-tax savings. You can also record pre-tax savings on income transactions (e.g. 401(k) contributions) to more accurately track what you're really saving over time."],
  },
  {
    id: 'budgetTour4',
    attachTo: { element: '#desktopExpenseSummary', on: 'left' },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      
      {
        classes: 'shepherd-button-primary',
        text: 'Next: Over/Underbudget',
        type: 'next'
      }
    ],
    classes: 'shepherd-element-left',
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Expense Summary',
    text: ["Every dollar that isn't assigned to a savings category is considered spent. If you're not saving it, you will spend it!"],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    id: 'budgetTour5',
    attachTo: { element: '.budgetAlert', on: 'bottom' },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      
      {
        classes: 'shepherd-button-primary',
        text: 'Next: Budget Category',
        type: 'next'
      }
    ],
    classes: 'shepherd-element-bottom',
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Over or Underbudget',
    text: ["You should budget every dollar you have you by dividing it up among your different categories. If the total that you've budgeted is more or less than the money you actually have, we'll let you know here."],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    id: 'budgetTour6',
    attachTo: { element: '.budgetcatdiv', on: 'bottom' },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      
      {
        classes: 'shepherd-button-primary',
        text: 'Next: Budgeted',
        type: 'next'
      }
    ],
    classes: 'shepherd-element-bottom',
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Budget Category',
    text: ["Your categories will be unique to you! Set money aside for the electric bill, pizza, or a new car. Just remember to keep it simple and sustainable!"],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    id: 'budgetTour7',
    attachTo: { element: '.budgetAmt', on: 'bottom' },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      
      {
        classes: 'shepherd-button-primary',
        text: 'Next: Spent',
        type: 'next'
      }
    ],
    classes: 'shepherd-element-bottom',
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Budgeted Amount',
    text: ["Each category acts like a digital envelope, and this is where you add your dollars. Try to be realistic about where your dollars need to go before your next paycheck."],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    id: 'budgetTour8',
    attachTo: { element: '.catSpent', on: 'bottom' },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      
      {
        classes: 'shepherd-button-primary',
        text: 'Next: Balance',
        type: 'next'
      }
    ],
    classes: 'shepherd-element-bottom',
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Spent in Category',
    text: ["As you track your spending by adding transactions, you can see how much you've spent in each category."],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    id: 'budgetTour9',
    attachTo: { element: '.budgetBalDiv ', on: 'bottom' },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      
      {
        classes: 'shepherd-button-primary',
        text: 'Next: Details',
        type: 'next'
      }
    ],
    classes: 'shepherd-element-bottom',
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Category Balance',
    text: ["Your category balance is the amount of money you have left to spend in each category. You might choose to empty it out at the end of the month, or build it up for a future expense."],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    id: 'budgetTour10',
    attachTo: { element: '.desktopBudgetArrow', on: 'bottom' },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      
      {
        classes: 'shepherd-button-primary',
        text: 'Next: Finish Up',
        type: 'next'
      }
    ],
    classes: 'shepherd-element-bottom',
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Category Details',
    text: ["If you want to see an overview of your spending by merchant or over time, check out the Category Overview."],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    id: 'final',
    attachTo: { element: '.tourButton', on: 'top' },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Got it!',
        type: 'next'
      }
    ],
    classes: 'shepherd-element-top',
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Want a replay?',
    text: ['Click this button any time, on any page to get a step-by-step tour.'],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
];