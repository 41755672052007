import React, { Component, useState } from 'react';
import RDropDown from 'react-bootstrap/Dropdown';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { firebaseDb, firebaseAuth } from './firebase.js';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { withRouter, useHistory, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { toNumber } from './util/Util.js';


import './index.css'


import ScrollAccountMenu from './components/ScrollAccountMenu';
import DesktopScrollAccountMenu from './components/DesktopScrollAccountMenu';
import DesktopHistoryDateRangePicker from './components/DesktopHistoryDateRangePicker';

import Navigation from './Navigation';

const dbURL = 'https://vermillionapp.fly.dev/';
// const dbURL = 'https://shielded-bastion-58122.herokuapp.com/';

export default function Settings(props) {

    ChartJS.register(ArcElement, Tooltip, Legend);
    let navigate = useNavigate();

    const [userID, setUserID] = useState('');
    var newDate = new Date();
    const [startDate, setStartDate] = useState(newDate);
    const [endDate, setEndDate] = useState(newDate);
    const [currAccount, setCurrAccount] = useState('');
    const [idToken, setIdToken] = useState('');
    const [email, setEmail] = useState('');
    const [saveRateGoal, setSaveRateGoal] = useState('');
    let [days_left, setDaysLeft] = useState(600);

    // console.log("base curr account",currAccount);

    const signOut = () => {
        firebaseAuth.signOut().then(() => {
            // Sign-out successful.
            props.history.push('/Login');
        }).catch((error) => {
            // An error happened.
            console.log("Cannot log out.");
            navigate('/Login');
        });
    };

    const reformatDate = (oldDate) => {
        var date = new Date(oldDate + 'T00:00:00');
        return (date.toLocaleString("en-us", {
            month: "short",
            day: 'numeric',
            year: 'numeric'
        }).toUpperCase().replace(",", ""));
    }

    const reformatDateForFilter = (oldDate) => {
        var date = new Date(oldDate).toISOString().split('T')[0];
        return (date);
    }


    const toCurrency = (x) => {
        // *** Convert to format $0.00
        return (x / 100).toLocaleString('US', {
            style: "currency",
            currency: "USD"
        });
    }


    async function api_getIdToken(user) {
        if (user) {
            const token = await user.getIdToken()

        } else {
            console.log("No user is logged in");
            navigate('/Login');
        }
    }

    const api_get_user = (authToken, selectedMonth) => {
        // setIsLoading(true);
        const data = {
            token: authToken,
            month: selectedMonth
        };
        const requestOptions = {
            method: 'GET'
        };
        // Where we're fetching data from
        const baseURL = dbURL + 'getUser?';
        const reqURL = baseURL
            + 'token=' + data.token;
        console.log(reqURL);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response.json())
            .then((data) => {
                const newData = data.results.rows
                // setIsLoading(false);
                return (newData);
            })
            .catch((error) => console.error(error));
    }

    const api_update_user = (saveRateGoal) => {
		console.log("About to make the API reuqest...POST");
		const data = {
			token: idToken, 
            save_rate_goal: saveRateGoal
		};
		const requestOptions = {
			method: 'POST',
			// headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({})
		};

		console.log('Adding before api post...');
		// Where we're fetching data from
		const baseURL = dbURL + 'updateuser?';
		const reqURL = baseURL
			+ 'token=' + data.token
			+ '&save_rate_goal=' + data.save_rate_goal;
		console.log("POST", reqURL);
		return fetch(reqURL, requestOptions)
			// We get the API response and receive data in JSON format
			.then((response) => {
				console.log("Update user response", JSON.parse(JSON.stringify(response)));
				return (JSON.parse(JSON.stringify(response)));
			})
			.then((data) => console.log("Adding after api post.." + data))
			.catch((error) => console.error(error));
	}

    useEffect(() => {
        firebaseAuth.onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                setUserID(firebaseAuth.currentUser.uid);
                setEmail(encodeURIComponent(firebaseAuth.currentUser.email));
                // const idToken2 = await 
                user.getIdToken()
                    .then(idToken => {
                        setIdToken(idToken);

                    });
                console.log('logged in!' + firebaseAuth.currentUser.uid);

            } else {
                // No user is signed in.
                console.log('no one signed in');
                navigate('/Login');
            }
        });
    }, []);

    useEffect(() => {
        if (idToken != '') {
            api_get_user(idToken).then((data) => {
                const newData = data;
                console.log("User items...", newData);
                setSaveRateGoal(newData[0]['save_rate_goal']);
                setDaysLeft(newData[0]['days_left']);
                console.log("Setting days left to ", newData[0]['days_left']);
            });
        }
    }, [idToken]);

    const data = {
        datasets: [
            {
                data: [.2, .8],
                backgroundColor: [
                    '#FFBB37',
                    '#E1D7D7'
                ],
                borderWidth: 1,
                cutout: '80%',
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                position: 'top',
                usePointStyle: true,
                pointStyle: 'circle'
            },
            title: {
                display: false,
            },
        },
    };

    function attemptUpdateSaveRateGoal(newSaveRateGoal) {
        let newGoal = Math.round(toNumber(newSaveRateGoal)/100.0);
        console.log("new goal ",newSaveRateGoal,newGoal);
        setSaveRateGoal(newGoal);
        // For some reason this won't change unless the actual result changes (probably React trying to be smart)
        api_update_user(newGoal);
    }


    class DesktopSaveRateGoal extends Component {
        render() {
            return (
                <>
                    <div className='bg-white ms-5 p-5 pt-4 mt-5'
                        style={{
                            width: "500px",
                            height: '170px',
                            borderRadius: '8px'
                        }}>
                        <div className='row'>
                            <div className='col-6'>
                                <div
                                    className='text-center'
                                    style={{
                                        marginTop: '-10px',
                                        position: 'relative',
                                        zIndex: '10'
                                    }}>

                                    <div className='fw-bold'>Save Goal</div>
                                    <div><input
                                        type="text"
                                        defaultValue={saveRateGoal + '%'}
                                        className='w-50 text-center'
                                        style={{
                                            backgroundColor: '#F2F2F2',
                                            border: 'none',
                                            color: '#4A5568',
                                            fontWeight: 'bold',
                                            paddingLeft: '10px',
                                            fontSize: '24px',
                                            borderRadius: '8px'
                                        }} 
                                        onBlur={e => {
                                            attemptUpdateSaveRateGoal(e.target.value);
                                        }}
                                        />
                                        </div>
                                </div>

                                <div style={{ marginTop: '-125px', position: 'relative' }}>
                                    <Doughnut data={data} options={options} />
                                </div>
                                <div style={{
                                    backgroundColor: "white",
                                    width: '115%',
                                    height: '220px',
                                    borderRadius: '200px',
                                    marginTop: '-195px',
                                    marginLeft: '-15px'
                                }}>
                                </div>
                            </div>
                            <div className='col ms-3 ps-4'>
                                <div className="col clearfix mt-3">
                                    <FontAwesomeIcon icon={faCircle}
                                        className="ms-1 colorDot colorDotPretax" />
                                    <span className='ms-2 summaryLabel float-left'>Pre-Tax Saved</span>
                                </div>
                                <div className="col clearfix mt-2">
                                    <FontAwesomeIcon icon={faCircle}
                                        className="ms-1 colorDot colorDotPretax" />
                                    <span className='ms-2 summaryLabel float-left'>Post-Tax Saved</span>
                                </div>
                                <div className="col clearfix mt-2">
                                    <FontAwesomeIcon icon={faCircle}
                                        className="ms-1 colorDot colorDotPosttax"
                                        style={{ color: '#E1D7D7' }} />
                                    <span className='ms-2 summaryLabel float-left'>Everyting Else</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        };
    }

    class SaveRateGoal extends Component {
        render() {
            return (
                <>
                    <div className='bg-white m-3 p-4 pt-4 mt-5'
                        style={{
                            width: "350px",
                            height: '160px',
                            borderRadius: '8px'
                        }}>
                        <div className='row'>
                            <div className='col-7'>
                                <div
                                    className='text-center'
                                    style={{
                                        marginTop: '-10px',
                                        position: 'relative',
                                        zIndex: '10'
                                    }}>

                                    <div className='fw-bold'>Save Goal</div>
                                    <div><input type="text" 
                                        defaultValue={saveRateGoal + '%'}
                                        className='w-50 text-center'
                                        style={{
                                            backgroundColor: '#F2F2F2',
                                            border: 'none',
                                            color: '#4A5568',
                                            fontWeight: 'bold',
                                            paddingLeft: '10px',
                                            fontSize: '24px',
                                            borderRadius: '8px'
                                        }} 
                                        onBlur={e => {
                                            attemptUpdateSaveRateGoal(e.target.value);
                                        }}
                                        /></div>
                                </div>

                                <div style={{ marginTop: '-125px', position: 'relative' }}>
                                    <Doughnut data={data} options={options} />
                                </div>
                                <div style={{
                                    backgroundColor: "white",
                                    width: '110%',
                                    height: '180px',
                                    borderRadius: '200px',
                                    marginTop: '-165px',
                                    marginLeft: '-8px'
                                }}>
                                </div>
                            </div>
                            <div className='col m-0 p-0 pb-0'>
                                <div className="col clearfix mt-3">
                                    <FontAwesomeIcon icon={faCircle}
                                        className="ms-1 colorDot colorDotPretax" />
                                    <span className='summaryLabel float-left small'>Pre-Tax Saved</span>
                                </div>
                                <div className="col clearfix mt-2">
                                    <FontAwesomeIcon icon={faCircle}
                                        className="ms-1 colorDot colorDotPretax" />
                                    <span className='summaryLabel float-left small'>Post-Tax Saved</span>
                                </div>
                                <div className="col clearfix mt-2">
                                    <FontAwesomeIcon icon={faCircle}
                                        className="ms-1 colorDot colorDotPosttax"
                                        style={{ color: '#E1D7D7' }} />
                                    <span className='summaryLabel float-left small'>Everyting Else</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        };
    }

    return (

        <>

            <Navigation key="nav-bar-key" />
            {/* Desktop */}
            <div className='d-none d-lg-block greyBG'>
                <div className='contentSpace'>

                    <div id='bottomSpacing'></div>
                    <div className='row ms-2 mt-5'>
                        <DesktopSaveRateGoal />
                    </div>
                    <div className='row ms-5'>
                        <div className='col-10 mt-5 text-black-50 '>

                            <a target="_blank" href={"https://billing.stripe.com/p/login/fZe4k87s753y0Fy288?prefilled_email=" + email}>
                                <div className="button button-darkGreen">
                                    Manage Subscription
                                </div></a>
                        </div>
                    </div>

                    <div className='row ms-5'>
                        <div className='col-10 mt-5 text-black-50 '>
                            <div onClick={() => signOut()}
                                className="button button-lightRed">
                                Sign Out
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile */}
            <div className='greyBG d-block d-lg-none '>
                <div className='greyBG'>

                    <div id='bottomSpacing'></div>
                    <div className='row ms-2 mt-5'>
                        <SaveRateGoal />
                    </div>
                    <div className='row ms-2'>
                        <div className='col-10 mt-5 text-black-50 '>
                            <a target="_blank" href={"https://billing.stripe.com/p/login/fZe4k87s753y0Fy288?prefilled_email=" + email}>
                                <div className="button button-darkGreen">
                                    Manage Subscription
                                </div></a>
                        </div>
                    </div>

                    <div className='row ms-2'>
                        <div className='col-10 mt-5 text-black-50 '>
                            <div onClick={() => signOut()}
                                className="button button-lightRed">
                                Sign Out
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

