import React, { Component, useState } from 'react';
import { ToggleButton, Button, ToggleButtonGroup, Modal } from 'react-bootstrap';
import Toggle from 'react-toggle';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faArrowRight, faArrowLeft, faPencil, faEyeSlash, faEye, faXmarkCircle, faPen } from '@fortawesome/free-solid-svg-icons';
import { withRouter, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { firebaseAuth } from './firebase.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './index.css';
import Lottie from 'lottie-react';
import greenCheck from './lotties/greencheck.json';
import "react-toggle/style.css"; // for ES6 modules
import walletcoins from './img/walletcoins.PNG';
import dollarbills from './img/dollarbills.png';


import Navigation from './Navigation';
import MonthOverMonthSpend from './components/MonthOverMonthSpend';
import CategorySpendScatter from './components/CategorySpendScatter';
import TourButton from './components/TourButton';
import { budgetsteps } from './toursteps/budgetsteps';
import ConfirmScreen from './components/ConfirmScreen.js';



const dbURL = 'https://vermillionapp.fly.dev/';
// const dbURL = 'https://shielded-bastion-58122.herokuapp.com/';

export default function Category(props) {
    //   const history = useHistory();
    const location = useLocation();
    let navigate = useNavigate();

    const [userID, setUserID] = useState('');
    const [newAmtToggle, setNewAmtToggle] = useState(false);
    // 0 = income, 1 = expense, 2 = transfer
    const [newType, setNewType] = useState(1);
    const [newXfr, setNewXfr] = useState(0);
    var newCat = '';
    const [confirmed, setConfirmed] = useState(false);
    const [token, setToken] = useState('');
    const [catId, setCatId] = useState(location.state.catid);
    const [catName, setCatName] = useState('');
    const [newCatName, setNewCatName] = useState('');
    const [catSection, setCatSection] = useState('');
    const [catSectionId, setCatSectionId] = useState('');
    const [newCatSectionId, setNewCatSectionId] = useState('');
    const [hidden, setHidden] = useState(false);
    const [numTransactions, setNumTransactions] = useState(-1);
    const [sumTransactions, setSumTransactions] = useState(-1);
    const [numBudget, setNumBudget] = useState(-1);
    const [sumBudget, setSumBudget] = useState(-1);
    const [otherCats, setOtherCats] = useState(location.state.cats);
    const [catActivity, setCatActivity] = useState([]);
    const [momSpend, setMomSpend] = useState([]);

    const [showSettings, setShowSettings] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showHide, setShowHide] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    let [days_left, setDaysLeft] = useState(600);

    // let catSectionId = '';
    // if (location.state.catid === 'Everyday Expenses') {
    //     catSectionId = 1
    // } else if (location.state.catid === 'Savings') {
    //     catSectionId = 2
    // } else if (location.state.catid === 'Debt') {
    //     catSectionId = 3
    // } else if (location.state.catid === 'Monthly Bills') {
    //     catSectionId = 4
    // }

    console.log("PROPS FOR CAT!", location.state);
    // console.log("more props",location.state.cats.filter(m => m.categoryid === location.state.catid))

    var summary = location.state.cats.filter(m => m.categoryid === location.state.catid)[0];
    var budgeted = summary.budgeted;
    var spent = summary.spent;
    var balance = summary.balance;
    // console.log("HE PROP WE WANTED", location.state.cats)

    // var curr = new Date();
    var curr = new Date(location.state.newDate);
    // curr.setDate(curr.getDate() - 1);

    const toCurrency = (x) => {
        // *** Convert to format $0.00
        return (x / 100).toLocaleString('US', {
            style: "currency",
            currency: "USD"
        });
    }


    const toNumber = (x) => {
        const newX = 100 * Number(x.replace(/[^0-9.-]/g, '')
            .replace(/(\..*)\./g, '$1')
            .replace(/(?!^)-/g, '')
            .replace(/^0+(\d)/gm, '$1'));
        // console.log('Turning into Number...' + newX);
        return (newX);

    }


    const toggleType = (x) => {
        setTimeout(() => {
            console.log("Toggling the value...", newType, x);
            if (x != newType) {
                console.log("Toggling the value...", newType, x);
                setNewType(x);
            } else {
                console.log("Doing nothing", newType, x);
            }
        }, 250);
        return (1);
    }
    const toggleXfr = (x) => {
        setTimeout(() => {
            console.log("Toggling the other value...");
            setNewXfr(!newXfr);
        }, 250);
        return (1);
    }



    const attemptSave = (x) => {
        // IF INCOME
        // check for source
        // check for account
        // check for date -- default to today, but verify?

        // IF EXPENSE
        // check for payee
        // check for category
        // check for account
        // check for date -- default to today

        // IF XFR
        // check for From
        // check for To
        // check for date
        console.log("Attempting to save now");
        if (newType == 2) {
            setTimeout(() => {
                console.log("Toggling the other value...");
                setConfirmed(!confirmed);
            }, 1550);

        } else {
            setTimeout(() => {
                console.log("Toggling the other value...");
                setConfirmed(!confirmed);
            }, 550);
        }
        return (1);
    }

    const attemptHide = (x) => {
        console.log("Attempting to un/hide now");
        // if (newType == 2) {
        api_hide_category(x, hidden);
        setTimeout(() => {
            console.log("Toggling the other value...");
            setConfirmed(!confirmed);
        }, 1550);

        // } else {
        //     setTimeout(() => {
        //         console.log("Toggling the other value...");
        //         setConfirmed(!confirmed);
        //     }, 550);
        // }
        return (1);
    }

    const attemptDelete = (x, y) => {
        console.log("Attempting to delete now");
        // if (newType == 2) {
        api_delete_category(x, y);
        setTimeout(() => {
            console.log("Toggling the other value...");
            setConfirmed(!confirmed);
        }, 1550);

        // } else {
        //     setTimeout(() => {
        //         console.log("Toggling the other value...");
        //         setConfirmed(!confirmed);
        //     }, 550);
        // }
        return (1);
    }

    var amtClass = '';
    var payeeLabel = '';
    var payeeHint = '';
    var categoryLabel = '';
    var catOptions = [{}];
    var saveButtonClass = '';
    var confirmColor = '';
    if (newType == 2) {
        amtClass = "TransactionAmount TransferAmount";
        saveButtonClass = 'col saveButtonYellow saveOrCancelButton';
    } else if (newType == 0) {
        amtClass = "TransactionAmount IncomeAmount";
        payeeLabel = 'Source';
        payeeHint = 'Who paid you?';
        categoryLabel = 'Available';
        // catOptions = ['Next Month', 'This Month'];
        catOptions = [{ type: 'category', categoryid: -2, name: 'Next Month' }, { type: 'category', categoryid: -1, name: 'This Month' }];
        saveButtonClass = 'col saveButtonGreen saveOrCancelButton';
        confirmColor = 'greenCheck';
    } else {
        amtClass = "TransactionAmount ExpenseAmount";
        payeeLabel = 'Payee';
        payeeHint = 'Who did you pay?';
        categoryLabel = 'Category';
        saveButtonClass = 'col saveButtonRed saveOrCancelButton';
        confirmColor = 'redCheck';
    }


    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: greenCheck,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    // if number doesn't actually change it won't update!

    const api_get_params = (authToken) => {
        const data = { token: authToken, categoryid: catId };
        const requestOptions = {
            method: 'GET'
        };
        // Where we're fetching data from
        const baseURL = dbURL + 'getCategory?';
        const reqURL = baseURL
            + 'token=' + data.token + '&'
            + 'categoryid=' + data.categoryid;
        console.log("get cat", reqURL);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response.json())
            .then((data) => {
                const newData = data.results.rows
                console.log("cat data", newData);
                setCatActivity(newData);
                setCatName(newData[0]['name']);
                setNewCatName(newData[0]['name']);
                setCatSection(newData[0]['section']);
                setCatSectionId(newData[0]['sectionid']);
                setNewCatSectionId(newData[0]['sectionid']);
                setDaysLeft(newData[0]['days_left']);
                if (newData[0]['hidden_at'] != null) {
                    setHidden(true);
                }

                setNumTransactions(newData[0]['trans_count']);
                setSumTransactions(newData[0]['trans_total']);
                setNumBudget(newData[0]['budget_count']);
                setSumBudget(newData[0]['budget_total']);

            })
            .catch((error) => console.error(error));
    }



    const api_hide_category = (x, hidden) => {
        // const newName = document.getElementById('category-name').value;
        console.log("About to make the API reuqest...POST");
        // toHide = 1 to hide the category
        // toHide = 0 to unhide the category
        // if the cat is currently hidden, pass 0 to unhide. Otherwise pass 1.
        var toHide = 1;
        if (hidden) {
            toHide = 0;
        }
        const data = {
            token: token, categoryid: x,
            toHide: toHide
        };
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };

        console.log('Adding before api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'hidecategory?';
        const reqURL = baseURL
            + 'token=' + data.token
            + '&categoryid=' + data.categoryid
            + '&tohide=' + data.toHide;
        console.log(reqURL);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response.json())
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }

    const api_delete_category = (x, y) => {
        console.log("About to make the API reuqest...POST");
        const data = {
            token: token,
            categoryid: x,
            newcatid: y
        };
        const requestOptions = {
            method: 'DELETE'
            // headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({})
        };

        console.log('Adding before api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'deletecategory/';
        const reqURL = baseURL
            + '' + data.categoryid
            + '?token=' + data.token
            + '&newcatid=' + data.newcatid;
        // + '&transactionid=' + data.transactionid;
        console.log(reqURL);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response.json())
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }

    const api_get_MoMSpend = (authToken) => {
        const data = { token: authToken, categoryid: catId };
        const requestOptions = {
            method: 'GET'
        };
        // Where we're fetching data from
        const baseURL = dbURL + 'getCatMoMSpend?';
        const reqURL = baseURL
            + 'token=' + data.token + '&'
            + 'categoryid=' + data.categoryid;
            console.log(reqURL);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response.json())
            .then((data) => {
                const newData = data.results.rows
                console.log("Mom spend", newData);
                setMomSpend(newData);
            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        firebaseAuth.onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                setUserID(firebaseAuth.currentUser.uid);
                // const idToken2 = await 
                user.getIdToken()
                    .then(idToken => {  // <------ Check this line
                        api_get_params(idToken);
                        api_get_MoMSpend(idToken);
                        setToken(idToken);
                    });
                console.log('logged in!' + firebaseAuth.currentUser.uid);
                // console.log(api_getIdToken(user));
            } else {
                // No user is signed in.
                console.log('no one signed in');
                location.state.history.push('/login');
            }
        });
    }, []);


    // useEffect(() => {
    //     console.log('Cat was updated but lets not close the modal');
    // }, [newCat]);

    const api_update_category = () => {
        // const newName = document.getElementById('category-name').value;
        // const newSection = document.getElementById('category-section').value;
        console.log("About to make the API reuqest...POST");
        const data = {
            token: token, categoryid: catId,
            name: encodeURIComponent(newCatName),
            section: encodeURIComponent(newCatSectionId)
        };
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };

        console.log('Adding before api post...');
        // Where we're fetching data from
        const baseURL = dbURL + 'updatecategory?';
        const reqURL = baseURL
            + 'token=' + data.token
            + '&categoryid=' + data.categoryid
            + '&name=' + encodeURIComponent(data.name)
            + '&section=' + data.section;
        console.log(reqURL);
        return fetch(reqURL, requestOptions)
            // We get the API response and receive data in JSON format
            .then((response) => response)
            .then((data) => console.log("Adding after api post.." + data))
            .catch((error) => console.error(error));
    }

    const updateCatName = () => {
        // const newName = document.getElementById('category-name').value;
        // const newSection = document.getElementById('category-section').value;
        console.log("NEW NAME", newCatName, newCatSectionId);
        // setNewCatName(newName);
        // setNewCatSectionId(newName);
        api_update_category();
        setTimeout(() => {
            console.log("Toggling the other value...");
            setConfirmed(!confirmed);
        }, 550);

    }

    function EditCatName() {
        // const [show, setShow] = useState(false);

        const handleClose = () => setShowEdit(false);
        const handleShow = () => setShowEdit(true);

        return (
            <>
                {showHide || showDelete ? (
                    <></>
                ) : (
                    <>
                        {showEdit ? (
                            <Modal show={showEdit} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Update Category</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="mb-3 mt-3">
                                        <input type="text" className="form-control"
                                            id="category-name"
                                            placeholder='Category Name'
                                            defaultValue={newCatName}
                                            onBlur={e => {
                                                setNewCatName(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <div className="mb-3">
                                            <select name="category-section"
                                                className="form-control"
                                                id="category-section"
                                                defaultValue={catSectionId}
                                                onBlur={e => {
                                                    setNewCatSectionId(e.target.value);
                                                }}
                                            >
                                                <option value=''>--select a section--</option>
                                                <option value="1">Everyday Expenses</option>
                                                <option value="2">Monthly Bills</option>
                                                <option value="3">Savings</option>
                                                <option value="4">Debt</option>
                                            </select>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className='button button-darkGreen' onClick={() => updateCatName()}>
                                        Save
                                    </div>
                                    <div className="button button-lightGreen" onClick={handleClose}>
                                        Cancel
                                    </div>
                                </Modal.Footer>
                            </Modal>

                        ) : (
                            <div className='text-center mt-5 fs-5 fw-bolder text-black-75 row hoverPointer'
                                onClick={handleShow}>
                                <div className='col-1'></div>
                                <div className='col-1'><FontAwesomeIcon icon={faPencil} className="me-3" /></div>
                                <div className='col-8'>Edit Name/Description</div>
                                <div className='col-1'></div>
                            </div>

                        )}


                    </>
                )}

            </>
        );
    }


    function SettingsModal() {
        // const [showMenu, setShowMenu] = useState(true);

        const handleCloseMenu = () => {
            console.log("closing the setings menu")
            // maybe sleep here for 0.1s for effect
            setShowSettings(false)
        };
        const handleShowMenu = () => setShowSettings(true);


        return (
            <>

                {days_left === '-1' ? (
                    <></>
                ) : (
                    <>
                        <div className='gearIcon hoverPointer'>
                            <FontAwesomeIcon icon={faCog}
                                onClick={handleShowMenu} />
                        </div>
                    </>
                )}

                {(!showSettings && (showEdit || showHide || showDelete)) ? (
                    <div>
                        <EditCatName />
                        <HideConfirmation />
                        <DeleteConfirmation />
                    </div>
                ) : (
                    <></>
                )}

                <Modal show={showSettings} onHide={handleCloseMenu}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogClassName="bottom-modal-dialog">
                    {/* <Modal.Header closeButton>
                        <Modal.Title>Confirm {hideWord}</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>
                        <div className='bottomModal'>
                            <div onClick={() => {
                                setShowSettings(false);
                            }}>
                                <EditCatName />
                                <hr className='m-5' />
                            </div>
                            <div onClick={() => {
                                setShowSettings(false);
                            }}>
                                <HideConfirmation />
                                <hr className='m-5' />
                            </div>
                            <div onClick={() => {
                                setShowSettings(false);
                            }}>
                                <DeleteConfirmation />
                            </div>
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="danger" onClick={() => attemptHide(catId)}>
                            Yes, {hideWord}
                        </Button>
                        <Button variant="outline-danger" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer> */}
                </Modal>
            </>
        );
    }


    function HideConfirmation() {
        // const [show, setShow] = useState(false);
        var hideWord = 'Hide';
        if (hidden == true) {
            hideWord = 'Unhide';
        }

        const handleClose = () => setShowHide(false);
        const handleShow = () => setShowHide(true);


        return (
            <>
                {showEdit || showDelete ? (
                    <></>
                ) : (
                    <>
                        {showHide ? (
                            <Modal show={showHide} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirm {hideWord}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Are you sure you want to {hideWord.toLowerCase()} this category?
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className='button button-darkRed' onClick={() => attemptHide(catId)}>
                                        Yes, {hideWord}
                                    </div>
                                    <div className='button button-lightRed' onClick={handleClose}>
                                        Cancel
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        ) : (
                            <div className='text-center mt-5 fs-5 fw-bolder text-black-75 row hoverPointer'
                                onClick={handleShow}>
                                <div className='col-1'></div>
                                <div className='col-1'>
                                    {hidden ? (
                                        <FontAwesomeIcon icon={faEye} className="me-3" />
                                    ) : (
                                        <FontAwesomeIcon icon={faEyeSlash} className="me-3" />
                                    )}
                                </div>
                                <div className='col-8'>{hideWord} Category</div>
                                <div className='col-1'></div>
                            </div>
                        )}
                    </>
                )
                }



            </>
        );
    }

    function DeleteConfirmation() {
        const [show, setShow] = useState(false);
        const [replaceCat, setReplaceCat] = useState(0);
        const [replaceCatName, setReplaceCatName] = useState('');
        var allowDelete = false;

        if (replaceCat != '' || (numTransactions == 0 && numBudget == 0)) {
            // setDisabledBtn(false);
            allowDelete = true
        }

        const handleClose = () => {
            setShowDelete(false);
        }
        const handleShow = () => setShowDelete(true);

        return (
            <> {otherCats == undefined || otherCats == null || otherCats == [] || showHide || showEdit ? (
                <></>
            ) : (
                <>
                    {showDelete ? (
                        <Modal show={showDelete} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Delete</Modal.Title>
                            </Modal.Header>
                            {numTransactions == 0 && numBudget == 0 ? (
                                <Modal.Body>
                                    Are you sure you wish to delete?
                                </Modal.Body>
                            ) : (
                                <Modal.Body>Before we can delete this category, you'll need to choose where to reassign the following:
                                    <ul>
                                        <li>{numTransactions}
                                            {numTransactions == 1 ? (' transaction') : (' transactions')} totalling {toCurrency(sumTransactions)}</li>
                                        <li>{toCurrency(sumBudget)} budgeted over {numBudget}
                                            {numBudget == 1 ? (' month') : (' months')}</li>
                                    </ul>

                                    <select id="newTransCat"
                                        name="cars"
                                        className='form-control mt-3'
                                        defaultValue={newCat}
                                        onChange={e => {
                                            var index = e.nativeEvent.target.selectedIndex;
                                            setReplaceCat(e.target.value);
                                            setReplaceCatName(e.nativeEvent.target[index].text);
                                        }}
                                    >
                                        <option disabled value=''>--select category--</option>
                                        {otherCats.map((cat, index) => {
                                            return (<option value={cat.categoryid} key={cat.categoryid}>{cat.name}</option>)
                                        })
                                        }
                                    </select>

                                    <div className={allowDelete ? ('mt-3') : ('d-none')}>
                                        Got it. We'll reclassify those dollars under
                                        {/* {otherCats.filter(d => d.categoryid == replaceCat)[0]['name']} */}
                                        {' ' + replaceCatName}.
                                    </div>
                                </Modal.Body>
                            )}
                            <Modal.Footer>
                                <div id='btn-delete-cat' className='button button-darkRed'
                                    onClick={() => { attemptDelete(catId, replaceCat) }}
                                    disabled={!allowDelete} >
                                    Delete
                                </div>
                                <div className='button button-lightRed' onClick={handleClose}>
                                    Cancel
                                </div>
                            </Modal.Footer>
                        </Modal>
                    ) : (

                        <div className='text-center mt-5 fs-5 fw-bolder text-black-75 row hoverPointer'
                            onClick={handleShow}>
                            <div className='col-1'></div>
                            <div className='col-1'><FontAwesomeIcon icon={faXmarkCircle} className="me-3" /></div>
                            <div className='col-8'>Delete Category</div>
                            <div className='col-1'></div>
                        </div>
                    )}


                </>
            )}

            </>
        );
    }

    class SaveOrCancel extends Component {
        render() {
            // const history = useHistory();
            return (
                <>
                    <div className="container">
                        <div className="row fixed-bottom text-center">
                            <div className={saveButtonClass} onClick={() => updateCatName()}>
                                SAVE
                            </div>
                            <div className="col cancelButton saveOrCancelButton" onClick={() => location.state.history.goBack()}>
                                CANCEL
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    // class CategoryActivity extends Component {
    //     render() {
    //         return (
    //             <>
    //                 <div className='mt-4'>
    //                     Where the money goes:
    //                 </div>
    //                 <div>

    //                     <div className='row'>
    //                         <div className='col'>Merchant</div>
    //                         <div className='col'>Avg Order</div>
    //                         <div className='col'>Total Spent</div>
    //                         <div className='col'>Transactions</div>

    //                     </div>
    //                     {catActivity != undefined && catActivity != [] ? (
    //                         catActivity.map((cat_item, index) => (
    //                             <div id={cat_item}
    //                                 key={`keycat-${index}`}
    //                                 className='row'>
    //                                 <div className='col'>{cat_item.merchant}</div>
    //                                 <div className='col'>{toCurrency(cat_item.merchant_avg)}</div>
    //                                 <div className='col'>{toCurrency(cat_item.merchant_total)}</div>
    //                                 <div className='col'>{cat_item.merchant_count}</div>

    //                             </div>
    //                         ))) : (
    //                         <div></div>
    //                     )}
    //                 </div>
    //             </>
    //         )
    //     }
    // }

    function MonthSummary() {
        return (
            <>
                <div className='catSummaryContainer ms-3 me-3'>
                    <div className='row'>
                        <div className='col-4'>
                            <img
                                src={walletcoins}
                                className='img-fluid mt-2'
                                alt="..."></img>
                        </div>
                        <div className='col-8'>
                            <div className='catSummaryTitle'>
                                This Month Summary
                            </div>
                            <div className='d-flex justify-content-evenly'>
                                <div className='budgetBal-neg ms-2'>-{toCurrency(spent)}</div>
                                <div className='budgetBal-pos'>+{toCurrency(budgeted)}</div>
                            </div>
                            <div className='d-flex justify-content-evenly'>
                                <div className='budgetBal-warn'>{toCurrency(balance)} remaining</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function SeeMoreButton() {
        return (
            <>
                <div className='budgetAlert mt-4 mb-4'>
                    <div className="alert alert-danger" role="alert">
                        <div className="alert-subheading text-center">
                            See all {catName} transactions <FontAwesomeIcon
                                icon={faArrowRight}
                                className="ms-2" />
                        </div>
                    </div>
                </div>
            </>
        )
    }



    function AllTimeSpent() {
        return (
            <>
                <div className='budgetAlert mt-4 mb-4'>
                    <div className="alert alert-success" role="alert">
                        <div className='row mb-3'>
                            <div className='col'>
                                <div className='alertSubheader'>All time spent</div>
                                <div className='alertDisplay'>{toCurrency(sumTransactions)}</div>
                            </div>
                            <div className='col-2'>

                                <img
                                    src={dollarbills}
                                    className='dollarbillimg' />
                            </div>
                            <div className='col'>
                                <div className='alertDisplay'>{numTransactions}</div>
                                <div className='alertSubheader'>Transactions</div>
                            </div>
                        </div>
                        {/* <div className="alert-subheading text-center">
                            See all {catName} transactions <FontAwesomeIcon
                                icon={faArrowRight}
                                className="ms-2" />
                        </div> */}
                    </div>
                </div>
            </>
        )
    }


    function DesktopAllTimeSpent() {
        return (
            <>
                <div className='budgetAlert h-100 w-100'>
                    <div className="desktopAlertRounded alert-success h-100">
                        <div className='row mb-2'>
                            <div className='col'>
                                <div className='alertSubheader mt-2 mb-2'>All time spent</div>
                                <div className='alertDisplay fs-4'>{toCurrency(sumTransactions)}</div>
                            </div>
                            <div className='col-2'>

                                <img
                                    src={dollarbills}
                                    className='dollarbillimg' />
                            </div>
                            <div className='col'>
                                <div className='alertDisplay mt-3 fs-4 mb-1'>{numTransactions}</div>
                                <div className='alertSubheader'>Transactions</div>
                            </div>
                        </div>
                        {/* <div className="alert-subheading text-center">
                            See all {catName} transactions <FontAwesomeIcon
                                icon={faArrowRight}
                                className="ms-2" />
                        </div> */}
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Navigation key="nav-bar-key" />
            {confirmed ? (
                <ConfirmScreen
                    buttonList={[['Back to Budget', '/Budget']]}
                />
            ) : (
                <>
                    {/* Desktop */}
                    <div className='d-none d-lg-block greyBG'>
                        <div className='contentSpace ms-5'>
                            <div className='row ms-2'>
                                <div className='col-10 mt-5 text-black-50 fw-bold'>
                                    <FontAwesomeIcon icon={faArrowLeft}
                                        className="me-3 hoverPointer" />
                                    <span className='hoverPointer'
                                        onClick={() => navigate('/Budget')}>
                                        Back to Budget
                                    </span>
                                </div>
                                <div className='col-1 mt-5'>
                                    <div>
                                        <SettingsModal />
                                    </div>
                                </div>

                                <div className='col'>
                                    <TourButton steps={budgetsteps} />
                                </div>
                            </div>
                            <div className='row ms-2'>
                                <div className='col mt-5 text-dark fw-bold fs-2'>
                                    {catName}
                                    <div className='mt-2 fs-6'>
                                        {catSection}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5 ms-2'>
                                <div className='col-2 desktopPill solidGreen me-4'>
                                    <div className='row text-white fs-4 fw-bold'>
                                        <div className='text-center'>
                                            {toCurrency(budgeted)}
                                        </div>
                                    </div>
                                    <div className='row text-white'>
                                        <div className='text-center'>
                                            Budgeted
                                        </div>
                                    </div>
                                </div>
                                <div className='col-2 desktopPill solidPink me-4'>
                                    <div className='row text-white fs-4 fw-bold'>
                                        <div className='text-center'>
                                            {toCurrency(spent)}
                                        </div>
                                    </div>
                                    <div className='row text-white'>
                                        <div className='text-center'>
                                            Spent
                                        </div>
                                    </div>
                                </div>
                                <div className='col-2 desktopPill solidYellow'>
                                    <div className='row text-white fs-4 fw-bold'>
                                        <div className='text-center'>
                                            {toCurrency(balance)}
                                        </div>
                                    </div>
                                    <div className='row text-white'>
                                        <div className='text-center'>
                                            Remaining
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 '>
                                    <DesktopAllTimeSpent />
                                </div>
                            </div>
                            <div className='row mt-3 ms-0 ps-0'>
                                <div className='col-6 ms-0 ps-0'>
                                    <MonthOverMonthSpend
                                        data={momSpend} />
                                </div>
                                <div className='col-5 ms-0 ps-0'>
                                    <CategorySpendScatter
                                        data={catActivity} />
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* Mobile */}
                    <div className='greyBG d-block d-lg-none '>
                        <div className='greyBG'>
                            <div className='bg-white navMonthDate scrollNavMonths css-box-shadow ps-4 pt-4 pt-5 pb-5 mb-4'>
                                <span id="currentDay">
                                    {catName}
                                </span>
                                <div className='pull-right mt-1 me-4'>
                                    <SettingsModal />
                                </div><br />
                                <span id="currentDate">
                                    {catSection}
                                </span>
                            </div>
                            <MonthSummary />
                            {/* <SeeMoreButton /> */}
                            <MonthOverMonthSpend
                                data={momSpend} />
                            <AllTimeSpent />
                            <CategorySpendScatter
                                data={catActivity} />

                            <div id='bottomSpacing'></div>
                            <div id='bottomSpacing'></div>
                        </div>
                    </div>
                </>
            )}

        </>
    );
}


